import styles from './Calendar.module.scss';
import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useEffectOnChange } from 'utils/hooks';
import {
  calendarTypePropType, tileContentPropType, valuePropType,
  viewValidator, edgeDateValueValidator,
} from './propTypes';
import { СalendarViews, AllValueTypes } from './constants';
import { PeriodTypes } from './enums';
import { getRangeStart, normalizeDate, getRangeValue } from './helpers';
import Navigation from './Navigation';
import Body from './Body';

const periodViewTileSelector = `.${styles.tile}:not(.${styles.weekNumber})`;
const selectableTileSelector = `:not(.${styles.neighboringMonth}):not([aria-disabled="true"])`;
const focusableTileSelector = periodViewTileSelector + selectableTileSelector;
const activeTileSelector = `.${styles.hasActive}` + selectableTileSelector;
const focusActiveTileAttr = 'data-focus-active';

const Calendar = React.forwardRef(({
  activeStartDate,
  allowPartialRange,
  calendarType,
  className = '',
  defaultActiveStartDate,
  defaultValue,
  defaultView,
  firstDayOfWeek,
  locale,
  maxDate,
  maxDetail = PeriodTypes.MONTH,
  minDate,
  minDetail = PeriodTypes.CENTURY,
  navigationAriaLabel,
  nextUpperRangeAriaLabel,
  nextUpperRangeLabel,
  nextAriaLabel,
  nextLabel,
  onChange,
  onClickWeekNumber,
  prevUpperRangeAriaLabel,
  prevUpperRangeLabel,
  prevAriaLabel,
  prevLabel,
  selectRange,
  setNavigationLabel,
  showDoubleView,
  showFixedNumberOfWeeks,
  showNavigation = true,
  showNeighboringMonth,
  showWeekNumbers,
  shouldDisableTile,
  tileContent,
  value,
  view,
}, ref) => {
  const [localActiveStartDate, setLocalActiveStartDate] = useState(defaultActiveStartDate);
  const [localValue, setLocalValue] = useState(value || defaultValue);
  const [localView, setLocalView] = useState(defaultView);
  const [hoveredDate, setHoveredDate] = useState(null);
  const onChangeEventRef = useRef(null);
  const viewRef = useRef();

  const currentActiveStartDate = activeStartDate || localActiveStartDate || getCurrentActiveStartDate(
    defaultActiveStartDate,
    defaultValue,
    defaultView,
    minDate,
    minDetail,
    maxDate,
    maxDetail,
    value,
    view,
  );
  const valueType = getValueType(maxDetail);
  const currentValue = useCurrentValue(value, localValue, selectRange);
  const currentView = getView(view || localView, minDetail, maxDetail);
  const currentViews = useMemo(() => getCurrentViews(minDetail, maxDetail), [minDetail, maxDetail]);
  const isDrillDownAvailable = currentViews.indexOf(currentView) < currentViews.length - 1;
  const isDrillUpAvailable = currentViews.indexOf(currentView) > 0;

  useEffect(() => {
    setHoveredDate(null);
  }, [selectRange]);

  // defaultView prop used for localView state initialization should not be changed after initial component render
  // to avoid unexpected behavior in the below useEffect.
  useEffect(() => {
    if (localView === defaultView)
      return;

    const wrapper = viewRef.current.parentElement;
    if (wrapper.contains(document.activeElement))
      return;

    const selectedTile = wrapper.querySelector(activeTileSelector) || wrapper.querySelector(focusableTileSelector);
    selectedTile && selectedTile.focus();
  }, [localView, defaultView]);

  useEffectOnChange(() => {
    const onChangeEvent = onChangeEventRef.current;
    if (!onChangeEvent)
      return;

    if (selectRange) {
      const isSingleValue = getIsSingleValue(localValue);
      if (!isSingleValue) {
        onChange(localValue, onChangeEvent);
      } else if (allowPartialRange) {
        onChange([localValue], onChangeEvent);
      }
    } else {
      onChange(localValue, onChangeEvent);
    }
    onChangeEventRef.current = null;
  }, [localValue]);

  useEffectOnChange(() => {
    if (areDatesEqual(localValue, defaultValue) || areDatesEqual(localValue, value))
      return;

    const newLocalActiveStartDate = getCurrentActiveStartDate(
      defaultActiveStartDate,
      defaultValue,
      defaultView,
      minDate,
      minDetail,
      maxDate,
      maxDetail,
      value,
      view,
    );

    setLocalValue(value);
    setLocalActiveStartDate(newLocalActiveStartDate);
  }, [value]);

  const setActiveStartDate = useCallback(newValue => setLocalActiveStartDate(getDateValueStateUpdater(newValue)), []);

  const handleDrillDown = useCallback(nextActiveStartDate => {
    if (!isDrillDownAvailable)
      return;

    const nextView = currentViews[currentViews.indexOf(currentView) + 1];
    setLocalActiveStartDate(getDateValueStateUpdater(nextActiveStartDate));
    setLocalView(nextView);
  }, [isDrillDownAvailable, currentViews, currentView]);

  const handleDrillUp = useCallback(() => {
    if (!isDrillUpAvailable)
      return;

    const nextView = currentViews[currentViews.indexOf(currentView) - 1];
    const nextActiveStartDate = getRangeStart(nextView, currentActiveStartDate);
    setLocalActiveStartDate(getDateValueStateUpdater(nextActiveStartDate));
    setLocalView(nextView);
  }, [currentActiveStartDate, isDrillUpAvailable, currentViews, currentView]);

  const handleChange = useCallback((value, event) => {
    let nextValue;
    if (selectRange) {
      if (!getIsSingleValue(currentValue)) {
        nextValue = getRangeStart(valueType, value);
      } else {
        nextValue = getRangeValue(valueType, currentValue, value);
      }
    } else {
      nextValue = getDetailValue(value, minDate, maxDate, maxDetail);
    }

    const nextActiveStartDate = getActiveStartDate(minDate, minDetail, maxDate, maxDetail, nextValue, view);

    event.persist();

    setLocalActiveStartDate(getDateValueStateUpdater(nextActiveStartDate));
    setLocalValue(getDateValueStateUpdater(nextValue));

    onChange && (onChangeEventRef.current = event);
  }, [currentValue, valueType, selectRange, minDate, maxDate, maxDetail, view]);

  const handleSetHoveredDate = useCallback(value => setHoveredDate(getDateValueStateUpdater(value)), []);

  const handleResetHoveredDate = useCallback(() => setHoveredDate(null), []);

  const handleBlur = useCallback(event => {
    const relatedTarget = event.relatedTarget || document.activeElement;
    const shouldSetFocusOnActiveTile = !event.currentTarget.contains(relatedTarget)
      || relatedTarget.classList.contains(styles.weekNumber)
      || event.target.classList.contains(styles.weekNumber) && !relatedTarget.classList.contains(styles.weekNumber);
    event.currentTarget.setAttribute(focusActiveTileAttr, shouldSetFocusOnActiveTile);

    selectRange && handleResetHoveredDate();
  }, [selectRange, handleResetHoveredDate]);

  const calendarBody = (
    // onHover is handled by onMouseOver handler in child components.
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <Body
      currentActiveStartDate={currentActiveStartDate}
      calendarType={calendarType}
      firstDayOfWeek={firstDayOfWeek}
      hoveredDate={hoveredDate}
      locale={locale}
      maxDate={maxDate}
      minDate={minDate}
      onClick={isDrillDownAvailable ? handleDrillDown : handleChange}
      onClickWeekNumber={onClickWeekNumber}
      onMouseLeave={selectRange ? handleResetHoveredDate : null}
      onMouseOver={selectRange ? handleSetHoveredDate : null}
      showFixedNumberOfWeeks={showFixedNumberOfWeeks || showDoubleView}
      showNeighboringMonth={showNeighboringMonth}
      showWeekNumbers={showWeekNumbers}
      tileContent={tileContent}
      shouldDisableTile={shouldDisableTile}
      valueType={valueType}
      value={currentValue}
      view={currentView}
    />
  );

  const wrapperClassNames = styles.wrapper +
    ` ${selectRange && getIsSingleValue(currentValue) ? styles.selectRange : ''}` +
    ` ${showDoubleView ? styles.doubleView : ''}` +
    ` ${className}`;

  return (
    <div className={wrapperClassNames} ref={ref}>
      {showNavigation &&
        <Navigation
          activeStartDate={currentActiveStartDate}
          isDrillUpAvailable={isDrillUpAvailable}
          locale={locale}
          maxDate={maxDate}
          minDate={minDate}
          navigationAriaLabel={navigationAriaLabel}
          nextUpperRangeAriaLabel={nextUpperRangeAriaLabel}
          nextUpperRangeLabel={nextUpperRangeLabel}
          nextAriaLabel={nextAriaLabel}
          nextLabel={nextLabel}
          onDrillUp={handleDrillUp}
          prevUpperRangeAriaLabel={prevUpperRangeAriaLabel}
          prevUpperRangeLabel={prevUpperRangeLabel}
          prevAriaLabel={prevAriaLabel}
          prevLabel={prevLabel}
          setActiveStartDate={setActiveStartDate}
          setNavigationLabel={setNavigationLabel}
          showDoubleView={showDoubleView}
          view={currentView}
        />
      }
      {/* onKeyDown and onFocus handle appropriate events on child interactive elements. */}
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <section
        className={styles.viewContainer}
        data-focus-active
        data-view={currentView}
        onBlur={handleBlur}
        onMouseLeave={selectRange ? handleResetHoveredDate : null}
        onKeyDown={handleKeyDown}
        onFocus={handleFocus}
        ref={viewRef}
      >
        {calendarBody}
        {showDoubleView && React.cloneElement(calendarBody, { isSecondView: true })}
      </section>
    </div>
  );
});

const calendarValuePropType = PropTypes.oneOfType([
  PropTypes.string,
  valuePropType,
]);

Calendar.propTypes = {
  activeStartDate: PropTypes.instanceOf(Date),
  allowPartialRange: PropTypes.bool,
  calendarType: calendarTypePropType,
  className: PropTypes.string,
  defaultActiveStartDate: PropTypes.instanceOf(Date),
  defaultValue: calendarValuePropType,
  defaultView: viewValidator,
  firstDayOfWeek: PropTypes.number,
  locale: PropTypes.string,
  maxDate: edgeDateValueValidator,
  maxDetail: PropTypes.oneOf(СalendarViews),
  minDate: edgeDateValueValidator,
  minDetail: PropTypes.oneOf(СalendarViews),
  navigationAriaLabel: PropTypes.string,
  nextUpperRangeAriaLabel: PropTypes.string,
  nextUpperRangeLabel: PropTypes.node,
  nextAriaLabel: PropTypes.string,
  nextLabel: PropTypes.node,
  onChange: PropTypes.func,
  onClickWeekNumber: PropTypes.func,
  prevUpperRangeAriaLabel: PropTypes.string,
  prevUpperRangeLabel: PropTypes.node,
  prevAriaLabel: PropTypes.string,
  prevLabel: PropTypes.node,
  selectRange: PropTypes.bool,
  setNavigationLabel: PropTypes.func,
  showDoubleView: PropTypes.bool,
  showFixedNumberOfWeeks: PropTypes.bool,
  showNavigation: PropTypes.bool,
  showNeighboringMonth: PropTypes.bool,
  showWeekNumbers: PropTypes.bool,
  shouldDisableTile: PropTypes.func,
  tileContent: tileContentPropType,
  value: calendarValuePropType,
  view: viewValidator,
};

Calendar.displayName = 'Calendar';

export default React.memo(Calendar);

function useCurrentValue(value, localValue, selectRange) {
  const prevValue = useRef();

  const currentValue = getCurrentValue(value, localValue, selectRange);
  if (areDatesEqual(prevValue.current, currentValue))
    return prevValue.current;

  return prevValue.current = currentValue;
}

function areDatesEqual(date1, date2) {
  if (!(date1 instanceof Date) || !(date2 instanceof Date))
    return false;

  return +date1 === +date2;
}

function getDateValueStateUpdater(newValue) {
  return prevValue => prevValue && areDatesEqual(prevValue, newValue) ? prevValue : newValue;
}

function getCurrentActiveStartDate(
  defaultActiveStartDate,
  defaultValue,
  defaultView,
  minDate,
  minDetail,
  maxDate,
  maxDetail,
  value,
  view,
) {
  let currentActiveStartDate = getActiveStartDateFromDefault(defaultActiveStartDate, view, minDetail, maxDetail);

  if (!currentActiveStartDate)
    currentActiveStartDate = getActiveStartDate(
      minDate,
      minDetail,
      maxDate,
      maxDetail,
      value || defaultValue,
      view || defaultView,
    );

  return currentActiveStartDate;
}

function getActiveStartDateFromDefault(defaultActiveStartDate, view, minDetail, maxDetail) {
  if (defaultActiveStartDate == null)
    return defaultActiveStartDate;

  const rangeType = getView(view, minDetail, maxDetail);
  return getRangeStart(rangeType, defaultActiveStartDate);
}

function getActiveStartDate(minDate, minDetail, maxDate, maxDetail, value, view) {
  const rangeType = getView(view, minDetail, maxDetail);
  const detailValueFrom = getDetailValue(value, minDate, maxDate, maxDetail);

  return getRangeStart(rangeType, detailValueFrom || new Date());
}

function getView(view, minDetail, maxDetail) {
  if (isViewAllowed(view, minDetail, maxDetail))
    return view;

  return maxDetail;
}

function isViewAllowed(view, minDetail, maxDetail) {
  const views = getCurrentViews(minDetail, maxDetail);
  return views.indexOf(view) !== -1;
}

function getCurrentViews(minDetail, maxDetail) {
  return СalendarViews.slice(СalendarViews.indexOf(minDetail), СalendarViews.indexOf(maxDetail) + 1);
}

function getCurrentValue(value, localValue, selectRange) {
  // In the middle of range selection, use value from state.
  if (selectRange && getIsSingleValue(localValue))
    return localValue;

  return value !== undefined ? value : localValue;
}

function getIsSingleValue(value) {
  return value && [].concat(value).length === 1;
}

function getValueType(maxDetail) {
  return AllValueTypes[СalendarViews.indexOf(maxDetail)];
}

function getDetailValue(value, minDate, maxDate, maxDetail) {
  if (!value)
    return null;

  const date = new Date(value);
  if (isNaN(+date))
    throw new Error(`Invalid date: ${value}`);

  const valueType = getValueType(maxDetail);
  const detailValue = getRangeStart(valueType, date);

  return normalizeDate(detailValue, minDate, maxDate);
}

function handleFocus(event) {
  if (document.documentElement.classList.contains('mouse-click'))
    return;

  if (event.target.classList.contains(styles.weekNumber))
    return;

  if (event.currentTarget.getAttribute(focusActiveTileAttr) !== 'true')
    return;

  const activeTile = event.currentTarget.querySelector(activeTileSelector);
  activeTile && activeTile.focus();
}

function handleKeyDown(event) {
  const { key } = event;
  if (
    key !== 'ArrowUp' && key !== 'Up'
    && key !== 'ArrowDown' && key !== 'Down'
    && key !== 'ArrowLeft' && key !== 'Left'
    && key !== 'ArrowRight' && key !== 'Right'
  )
    return;

  event.preventDefault();

  if (event.target.classList.contains(styles.weekNumber)) {
    handleWeekNumbersKeyDown(event);
  } else {
    handlePeriodViewKeyDown(event);
  }
}

function handleWeekNumbersKeyDown(event) {
  switch (event.key) {
    case 'ArrowUp':
    case 'Up':
      event.target.previousElementSibling && event.target.previousElementSibling.focus();
      return;
    case 'ArrowDown':
    case 'Down':
      event.target.nextElementSibling && event.target.nextElementSibling.focus();
      return;
    default:
      return;
  }
}

function handlePeriodViewKeyDown(event) {
  const isDoubleView = event.currentTarget.children.length === 2;
  const currTileIdx = [...event.target.parentElement.children].findIndex(el => el === event.target);

  switch (event.key) {
    case 'ArrowUp':
    case 'Up':
      handleArrowUpPress(event, isDoubleView, currTileIdx);
      return;
    case 'ArrowDown':
    case 'Down':
      handleArrowDownPress(event, isDoubleView, currTileIdx);
      return;
    case 'ArrowLeft':
    case 'Left':
      handleArrowLeftPress(event, isDoubleView, currTileIdx);
      return;
    case 'ArrowRight':
    case 'Right':
      handleArrowRightPress(event, isDoubleView, currTileIdx);
      return;
    default:
      return;
  }
}

function isTileFocusable(tile) {
  return !(tile.classList.contains(styles.neighboringMonth) || tile.getAttribute('aria-disabled') === 'true');
}

function getCurrentViewUpDownStep(event) {
  return event.currentTarget.dataset.view === PeriodTypes.MONTH ? 7 : 3;
}

function isTargetInFirstView(event) {
  return event.currentTarget.firstElementChild.contains(event.target);
}

function handleArrowUpPress(event, isDoubleView, currTileIdx) {
  const step = getCurrentViewUpDownStep(event);

  let nextUpperTile;
  for (let nextIdx = currTileIdx - step; nextIdx >= 0; nextIdx -= step) {
    const tile = event.target.parentElement.children[nextIdx];
    if (isTileFocusable(tile)) {
      nextUpperTile = tile;
      break;
    }
  }

  if (!nextUpperTile && isDoubleView && !isTargetInFirstView(event)) {
    const prevViewContainer = event.currentTarget.firstElementChild.querySelector(periodViewTileSelector).parentElement;
    const prevViewTiles = prevViewContainer.children;
    const tileIdx = currTileIdx % step + 1;
    const tilesInIncompleteRow = prevViewTiles.length % step;
    const lastIndexInCompleteRow = prevViewTiles.length - 1 - tilesInIncompleteRow;
    const startIdx = tileIdx < tilesInIncompleteRow ? lastIndexInCompleteRow + tileIdx : lastIndexInCompleteRow - (step - tileIdx);

    for (let nextIdx = startIdx; nextIdx >= 0; nextIdx -= step) {
      const tile = prevViewTiles[nextIdx];
      if (isTileFocusable(tile)) {
        nextUpperTile = tile;
        break;
      }
    }
  }

  nextUpperTile && nextUpperTile.focus();
}

function handleArrowDownPress(event, isDoubleView, currTileIdx) {
  const step = getCurrentViewUpDownStep(event);
  let tiles = event.target.parentElement.children;

  let nextLowerTile;
  for (let nextIdx = currTileIdx + step; nextIdx < tiles.length; nextIdx += step) {
    const tile = tiles[nextIdx];
    if (isTileFocusable(tile)) {
      nextLowerTile = tile;
      break;
    }
  }

  if (!nextLowerTile && isDoubleView && isTargetInFirstView(event)) {
    const nextTilesContainer = event.currentTarget.lastElementChild.querySelector(periodViewTileSelector).parentElement;
    tiles = nextTilesContainer.children;
    for (let nextIdx = currTileIdx % step; nextIdx < tiles.length; nextIdx += step) {
      const tile = tiles[nextIdx];
      if (isTileFocusable(tile)) {
        nextLowerTile = tile;
        break;
      }
    }
  }

  nextLowerTile && nextLowerTile.focus();
}

function handleArrowLeftPress(event, isDoubleView, currTileIdx) {
  let prevTile;
  for (let prevIdx = currTileIdx - 1; prevIdx >= 0; prevIdx--) {
    const tile = event.target.parentElement.children[prevIdx];
    if (isTileFocusable(tile)) {
      prevTile = tile;
      break;
    }
  }

  if (!prevTile && isDoubleView && !isTargetInFirstView(event)) {
    const prevViewContainer = event.currentTarget.firstElementChild.querySelector(periodViewTileSelector).parentElement;
    for (let prevIdx = prevViewContainer.children.length - 1; prevIdx >= 0; prevIdx--) {
      const tile = prevViewContainer.children[prevIdx];
      if (isTileFocusable(tile)) {
        prevTile = tile;
        break;
      }
    }
  }

  prevTile && prevTile.focus();
}

function handleArrowRightPress(event, isDoubleView, currTileIdx) {
  let nextTile;
  for (let nextIdx = currTileIdx + 1; nextIdx < event.target.parentElement.children.length; nextIdx++) {
    const tile = event.target.parentElement.children[nextIdx];
    if (isTileFocusable(tile)) {
      nextTile = tile;
      break;
    }
  }

  if (!nextTile && isDoubleView && isTargetInFirstView(event))
    nextTile = event.currentTarget.lastElementChild.querySelector(focusableTileSelector);

  nextTile && nextTile.focus();
}
