import { createLocation as createHistoryLocation } from 'history';

export function areLocationsEqual(l1, l2, ignoreHash = false) {
  return l1 === l2 || (
    l1 && l2 ? (
      (l1.pathname || '') === (l2.pathname || '') &&
      (l1.search || '') === (l2.search || '') &&
      (ignoreHash || (l1.hash || '') === (l2.hash || ''))
    ) : false
  );
}

export function createLocation(url) {
  const { pathname, search, hash } = createHistoryLocation(url);
  return { pathname, search, hash };
}

export function createUrl(location) {
  const { pathname, search, hash } = location;
  return pathname + search + hash;
}
