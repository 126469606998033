import styles from './Header.module.scss';
import React, { useRef, useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactResizeDetector from 'react-resize-detector';
import { Container, Row, Col } from 'components/primitives/grid';
import { LoginInfo } from 'components/objects/loginInfo';
import { LanguageSelector } from 'components/objects/languageSelector';
import { Logo } from 'components/primitives/logo';
import { Search } from 'components/objects/search';
import { ModalNav, useFocusResetOnLocationChange } from 'components/objects/navigation';
import OpenerContainer from './OpenerContainer';
import { useLayoutShifter, ShiftTypes } from 'utils/layout';
import { isModifierKeyPressed } from 'utils/helpers';
import { orientationChange$, useEventObservable } from 'utils/rxjs';
import FocusLock from 'react-focus-lock';
import { BasketSummary } from 'components/objects/basket';
import ShoppingCartSuggestions from './ShoppingCartSuggestions';
import { useSetFocusWithoutScrolling } from 'utils/hooks';
import { HEADER_SEARCH_BOX_ID } from './constants';

const StickyHeaderTemplate = ({ isDesktop, isLoading, noGutters }) => {
  const [isModalMode, setModalMode] = useState(false);
  const [searchIsFocused, setSearchIsFocused] = useState(false);
  const isFirstRender = useRef(true);
  const activeElementRef = useRef();
  const headerWrapperRef = useRef();
  const headerRef = useRef();
  const navOpenerRef = useRef();
  const prevHeightRef = useRef(0);
  const setFocusWithoutScrolling = useSetFocusWithoutScrolling();
  const { topShiftBlockHeight, bottomShiftBlockHeight, updateElementsAffectingShiftData } = useLayoutShifter();

  const toggleHeaderMode = useCallback(state => setModalMode(state ?? (prev => !prev)), []);
  const unsetModalMode = useCallback(() => setModalMode(false), []);

  const disableModalMode = useCallback(e => {
    if (e.type === 'click') {
      const { target, currentTarget: { lastElementChild } } = e;

      if (target !== lastElementChild)
        return;
    } else {
      const { key, which } = e;
      const isTargetKey = key === 'Escape' || which === 27;

      if (!isTargetKey || isModifierKeyPressed(e))
        return;
    }

    unsetModalMode();
  }, []);

  const handleResize = useCallback(() => {
    const { offsetHeight } = headerRef.current;
    if (offsetHeight === prevHeightRef.current)
      return;

    prevHeightRef.current = offsetHeight;
    updateElementsAffectingShiftData(ShiftTypes.TOP, 'stickyHeader', offsetHeight, !isDesktop);
  }, [isDesktop]);

  useEventObservable(orientationChange$, unsetModalMode);

  useEffect(() => {
    const height = headerRef.current.offsetHeight;
    updateElementsAffectingShiftData(ShiftTypes.TOP, 'stickyHeader', height, !isDesktop);
    prevHeightRef.current = height;

    if (!isDesktop) {
      return () => updateElementsAffectingShiftData(ShiftTypes.TOP, 'stickyHeader');
    }

    // Animation should be applied after small delay to work properly in IE11 and Legacy MS Edge.
    const timeoutId = setTimeout(() => headerRef.current.classList.add(styles.animate));

    return () => {
      clearTimeout(timeoutId);
      updateElementsAffectingShiftData(ShiftTypes.TOP, 'stickyHeader');
    };
  }, [isDesktop]);

  useEffect(() => {
    const { style: headerStyle, offsetHeight } = headerRef.current;
    const { style: headerWrapperStyle } = headerWrapperRef.current;

    headerStyle.top = `${topShiftBlockHeight}px`;
    headerWrapperStyle.paddingTop = `${topShiftBlockHeight + offsetHeight}px`;
    headerWrapperStyle.bottom = `${bottomShiftBlockHeight}px`;
  }, [topShiftBlockHeight, bottomShiftBlockHeight]);

  const handleFocus = useMemo(() => {
    if (isDesktop)
      return null;

    return e => setSearchIsFocused(e && e.type === 'focus');
  }, [isDesktop]);

  useEffect(() => {
    // Additional focus reset to override react-focus-lock default behaviour of setting focus
    // on first focusable element inside lock on deactivation. Focus will remain on active element
    // if it is present and is not a descendant of sticky header or will be set on layout element otherwise
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    if (isModalMode && isLoading)
      activeElementRef.current = document.activeElement;

    if (isModalMode || isLoading || activeElementRef.current === undefined)
      return;

    const [, menuElement] = headerWrapperRef.current.children;
    let targetElement = activeElementRef.current;
    // If targetElement is not present, it is located inside of top navigation menu or is not visible on the screen
    // set targetElement value to layout element.
    if (!targetElement || menuElement.contains(targetElement) || targetElement.offsetWidth === 0)
      targetElement = document.getElementById('layout');

    setFocusWithoutScrolling(targetElement);
    activeElementRef.current = undefined;
  }, [isLoading, isModalMode]);

  useFocusResetOnLocationChange(!isModalMode, unsetModalMode);

  return (
    <FocusLock disabled={!isModalMode}>
      {/*eslint-disable-next-line jsx-a11y/no-static-element-interactions*//*There is a role here actually.*/}
      <div
        role={isModalMode ? 'dialog' : 'presentation'}
        aria-modal={isModalMode ? 'true' : null}
        aria-keyshortcuts={isModalMode ? 'Esc' : null}
        className={styles.stickyHeaderWrapper}
        ref={headerWrapperRef}
        onKeyDown={disableModalMode}
        onClick={disableModalMode}
      >
        {/* data-scroll-lock-fill-gap - fills gap on element on right side with padding (default) when body scroll disabled */}
        <div className={`${styles.stickyHeader}${isDesktop ? ` ${styles.desktop}` : ''}`} ref={headerRef} data-scroll-lock-fill-gap>
          <ReactResizeDetector handleHeight onResize={handleResize} />
          <Container fluid={!isDesktop} className={`${styles.stickyHeaderContainer}${noGutters ? ` ${styles.noGutters}` : ''}`}>
            <Row crossAxisAlign="center" style={{ minHeight: styles.stickyHeight }} noGutters>
              <OpenerContainer ref={navOpenerRef} />
              <Col className={`${styles.middleSection} ${searchIsFocused ? styles.isFocused : ''}`}>
                <Logo className={styles.logo} small />
                <Search className={styles.searchBox} onFocus={handleFocus} onBlur={handleFocus} id={HEADER_SEARCH_BOX_ID} />
              </Col>
              <Col xs="auto" className={styles.column}>
                <ShoppingCartSuggestions hideLabel className={styles.suggestions} />
                <LanguageSelector />
                <LoginInfo />
                <BasketSummary hideLabel />
              </Col>
            </Row>
          </Container>
        </div>
        <ModalNav
          isDesktop={isDesktop}
          noGutters={noGutters}
          openerContainer={navOpenerRef}
          isVisible={isModalMode}
          visibilityChangeHandler={toggleHeaderMode}
        />
      </div>
    </FocusLock>
  );
};

StickyHeaderTemplate.propTypes = {
  isDesktop: PropTypes.bool,
  noGutters: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default connect(({ isLoading }) => ({ isLoading }))(StickyHeaderTemplate);
