import {
  USER_AUTHENTICATED,
  USER_ABILITIES_LOADED,
  USER_PROFILE_UPDATED,
  LOGIN_FAILED,
  LOGIN_FAILED_RESET,
  IMPERSONATION_FAILED,
  IMPERSONATION_FAILED_RESET,
  SET_PREVIEW_USER,
  UNSET_PREVIEW_USER,
} from './actions';
import { OFFLINE_MODE_CHANGED } from 'behavior/app';
import { createReducer } from 'utils/redux';

const initialState = {
  id: null,
  name: null,
  email: null,
  type: null,
  isAuthenticated: false,
  shopAccountType: null,
  isImpersonating: false,
  pricesInclTax: null,
  currencyId: null,
  customer: null,
  abilities: {},
  expiredAbilities: [],
  initialized: false,
  loginFailed: false,
  impersonationFailed: false,
};

export default createReducer(initialState, {
  [USER_AUTHENTICATED]: onUserAuthenticated,
  [OFFLINE_MODE_CHANGED]: onOfflineModeChanged,
  [USER_ABILITIES_LOADED]: onAbilitiesLoaded,
  [USER_PROFILE_UPDATED]: onUserProfileUpdated,
  [LOGIN_FAILED]: onLoginFailed,
  [LOGIN_FAILED_RESET]: onLoginFailedReset,
  [IMPERSONATION_FAILED]: onImpersonationFailed,
  [IMPERSONATION_FAILED_RESET]: onImpersonationFailedReset,
  [SET_PREVIEW_USER]: onPreviewUserSet,
  [UNSET_PREVIEW_USER]: onPreviewUserUnset,
});

function onUserAuthenticated(state, action) {
  const userData = {
    ...action.payload,
    initialized: true,
    abilities: action.payload.abilities || state.abilities,
    expiredAbilities: action.payload.abilities ? [] : Object.keys(state.abilities),
  };

  if (state.previewData)
    return { ...state, previewData: { ...state.previewData, originalUser: userData } };

  return userData;
}

function onOfflineModeChanged(state, _action) {
  return {
    ...state,
    expiredAbilities: Object.keys(state.abilities),
  };
}

function onAbilitiesLoaded(state, action) {
  const loadedKeys = Object.keys(action.payload);
  const newExpiredAbilities = state.expiredAbilities.filter(k => !loadedKeys.includes(k));
  return {
    ...state,
    abilities: {
      ...state.abilities,
      ...action.payload,
    },
    expiredAbilities: newExpiredAbilities.length === state.expiredAbilities.length ? state.expiredAbilities : newExpiredAbilities,
  };
}

function onUserProfileUpdated(state = null, action) {
  return {
    ...state, ...action.payload,
  };
}

function onLoginFailed(state, _action) {
  return {
    ...state, loginFailed: true,
  };
}

function onLoginFailedReset(state, _action) {
  return {
    ...state, loginFailed: false,
  };
}

function onImpersonationFailed(state, _action) {
  return {
    ...state, impersonationFailed: true,
  };
}

function onImpersonationFailedReset(state, _action) {
  return {
    ...state, impersonationFailed: false,
  };
}

function onPreviewUserSet(state, action) {
  return {
    ...action.payload,
    initialized: true,
    abilities: state.abilities,
    expiredAbilities: Object.keys(state.abilities),
    previewData: { originalUser: state },
  };
}

function onPreviewUserUnset(state) {
  return {
    ...state.previewData.originalUser,
    abilities: state.abilities,
    expiredAbilities: Object.keys(state.abilities),
  };
}