import styles from '../../Checkout.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LoadingIndicator from '../StepLoadingIndicator';
import { WarningAlert } from 'components/primitives/alerts';
import ShippingMethodsList from './ShippingMethodsList';
import { useSanaTexts, RichText } from 'components/sanaText';
import { makeRichText } from 'utils/render';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { useCultureName } from 'utils/hooks';
import { getFormatNumber } from 'utils/format';

const ShippingMethodStepBody = ({
  isQuote,
  totalGrossWeight,
  weightUnits,
  shippingMethods,
  selectedMethodId,
  selectedLocationId,
  locations,
  onSelect,
  onSubmit,
  validationMsg,
}) => {
  const anyMethods = shippingMethods.length > 0;
  const [methodsText] = useSanaTexts([
    anyMethods ? 'ChooseOneOfTheDeliveryMethodsListedBelow' : 'ShippingMethods_NoMethodsAvailable',
  ]).texts;

  const culture = useCultureName();

  if (!anyMethods)
    return (
      <>
        <WarningAlert>{makeRichText(methodsText)}</WarningAlert>
        <LoadingIndicator />
      </>
    );

  const numberFormat = getFormatNumber(culture);

  return (
    <>
      {methodsText &&
        <div className={styles.description}>
          {makeRichText(methodsText)}
        </div>
      }
      {validationMsg &&
        <div className={`${formStyles.validation} ${styles.validation}`} role="alert">
          <FontAwesomeIcon icon={faTimesCircle} />
          {validationMsg}
        </div>
      }
      <ShippingMethodsList
        shippingMethods={shippingMethods}
        locations={locations}
        onSelect={onSelect}
        onSubmit={onSubmit}
        selectedMethodId={selectedMethodId}
        selectedLocationId={selectedLocationId}
      />
      {totalGrossWeight && shippingMethods.some(method => method.dependsOnWeight) &&
        <div className={styles.totalGrossWeight}>
          <RichText textKey={`ShippingMethods_TotalShippingWeight${isQuote ? '_Quote' : ''}`} />
          {' '}
          <span>
            {numberFormat(totalGrossWeight)}{' '}{weightUnits}
          </span>
        </div>
      }
      <LoadingIndicator />
    </>
  );
};

ShippingMethodStepBody.propTypes = {
  isQuote: PropTypes.bool,
  totalGrossWeight: PropTypes.number,
  weightUnits: PropTypes.oneOf(['KG', 'G', 'OZ', 'LB']),
  shippingMethods: PropTypes.arrayOf(PropTypes.shape({
    weightRequired: PropTypes.bool,
  }).isRequired),
  selectedMethodId: PropTypes.string,
  selectedLocationId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  validationMsg: PropTypes.node,
};

const mapStateToProps = ({ settings: { product: { weightUnits } } }) => ({
  weightUnits,
});

export default connect(mapStateToProps)(ShippingMethodStepBody);
