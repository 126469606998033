import styles from './Details.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React, { useMemo } from 'react';
import { UseSanaTexts } from 'components/sanaText';
import { connectToContext } from 'utils/react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { joinClasses } from 'utils/helpers';
import ProductContext from './ProductContext';
import { Link } from 'components/primitives/links';
import { Placeholder } from 'components/primitives/placeholders';
import { makeSimpleText } from 'utils/render';
import { showMatrix } from './showMatrix';
import { useCanViewUom } from 'components/primitives/product';

const PrintPreviewLink = ({
  isPrintMode,
  pathname,
  routeData,
  variantId,
  uomId,
  className,
  product,
  matrixPreset,
}) => {
  const canViewUom = useCanViewUom();

  const { to, url } = useMemo(() => {
    const showUomInUrl = canViewUom && !!product.uoms
      && product.uoms.length > 1 && !!uomId;
    const showVariantIdInUrl = (!matrixPreset || !showMatrix(product)) && !!variantId;

    const variantIdString = showVariantIdInUrl ? '&variantId=' + variantId : '';
    const uomIdString = showUomInUrl ? '&uomId=' + uomId : '';
    return {
      to: routeData,
      url: `${pathname}?print=1${variantIdString}${uomIdString}`,
    };
  }, [
    pathname,
    routeData,
    variantId,
    uomId,
    product,
    matrixPreset,
    canViewUom,
  ]);

  const printPreviewPlaceholder = (
    <Placeholder
      className={joinClasses(
        styles.printPreviewPlaceholder,
        styles.link,
        isPrintMode && styles.hidden,
      )}
    />
  );

  return (
    <UseSanaTexts options={['ButtonText_PrintPreview']} placeholder={printPreviewPlaceholder}>
      {([ButtonText_PrintPreview]) => (
        <Link to={to} url={url} rel="nofollow" omitScroll replaceHistory
          className={joinClasses(
            className,
            styles.link,
            styles.hypPrintPreview,
            linkStyles.link,
            linkStyles.arrowed,
            isPrintMode && styles.hidden,
          )}
          // tabIndex allows link to be focusable programmatically in Safari browser
          tabIndex="0"
        >
          {makeSimpleText(ButtonText_PrintPreview)}
        </Link>
      )
      }
    </UseSanaTexts>

  );
};

PrintPreviewLink.propTypes = {
  isPrintMode: PropTypes.bool,
  pathname: PropTypes.any,
  routeData: PropTypes.any,
  variantId: PropTypes.any,
  uomId: PropTypes.any,
  className: PropTypes.string,
  product: PropTypes.shape({
    uoms: PropTypes.array,
  }),
  matrixPreset: PropTypes.bool,
};
const mapStateToProps = ({
  routing: { location: { pathname }, routeData },
}) => ({
  pathname,
  routeData,
});

const ConnectedPrintPreviewLink = connect(mapStateToProps)(PrintPreviewLink);

function mapContextToProps({ variantId, uomId, product }) {
  return ({
    variantId,
    uomId,
    product,
  });
}

export default connectToContext([ProductContext], mapContextToProps)(ConnectedPrintPreviewLink);
