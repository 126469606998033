import { getDiscountAmount, getDiscountPercentage } from './discountUtils';

export const ActionPricesPresentationTypes = Object.freeze({
  SalesPrice: 'SALES_PRICE',
  BaseAndSalesPrice: 'BASE_AND_SALES_PRICE',
  BaseAndSalesPriceDiscountPercentage: 'BASE_AND_SALES_PRICE_DISCOUNT_PERCENTAGE',
  BaseAndSalesPriceDiscountAmount: 'BASE_AND_SALES_PRICE_DISCOUNT_AMOUNT',
  SalesPriceDiscountPercentage: 'SALES_PRICE_DISCOUNT_PERCENTAGE',
  SalesPriceDiscountAmount: 'SALES_PRICE_DISCOUNT_AMOUNT',
});

export const createPriceModel = (salesPrice, basePrice, actionPricesMode) => {
  switch (actionPricesMode) {
    case ActionPricesPresentationTypes.SalesPrice:
      return {
        price: salesPrice,
      };

    case ActionPricesPresentationTypes.BaseAndSalesPrice:
      return {
        price: salesPrice,
        actionPrice: basePrice,
      };

    case ActionPricesPresentationTypes.BaseAndSalesPriceDiscountPercentage:
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    case ActionPricesPresentationTypes.BaseAndSalesPriceDiscountAmount:
      return {
        price: salesPrice,
        actionPrice: basePrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case ActionPricesPresentationTypes.SalesPriceDiscountAmount:
      return {
        price: salesPrice,
        discountAmount: getDiscountAmount(salesPrice, basePrice),
      };

    case ActionPricesPresentationTypes.SalesPriceDiscountPercentage:
      return {
        price: salesPrice,
        discountPercentage: getDiscountPercentage(salesPrice, basePrice),
      };

    default:
      throw new Error(`Action prices presentation mode ${actionPricesMode} is not supported.`);
  }
};