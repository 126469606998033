type DateInput = string | number | Date;

function getDate(date: DateInput): Date {
  if (date instanceof Date)
    return date;

  return new Date(date);
}

export function getToLocaleDate(culture: string): (date: DateInput | null) => string | null {
  const format = getCachedFormatFunc('', culture);

  return date => date !== null ? format(getDate(date)) : null;
}

export function toLocaleDate(date: DateInput | null, culture: string): string | null {
  return getToLocaleDate(culture)(date);
}

const longDateOptions: Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

export function toLongLocaleDate(date: DateInput | null, culture: string) {
  if (date === null)
    return null;

  const format = getCachedFormatFunc('long', culture, longDateOptions);
  return format(getDate(date));
}

export function toLocaleTime(dateString: string, culture: string, hideSeconds = false): string {
  const format = getCachedFormatFunc('time' + hideSeconds, culture, {
    hour: 'numeric',
    minute: 'numeric',
    second: hideSeconds ? undefined : 'numeric',
  });

  return format(getDate(dateString));
}

type Formatter = {
  culture: string;
  format: (date?: Date | number) => string;
};

const getCachedFormatFunc = function () {
  const caches = new Map<string, Formatter>();

  return (key: string, culture: string, options?: Intl.DateTimeFormatOptions) => {
    culture = culture || 'en-US';
    let formatter = caches.get(key);

    if (!formatter || formatter.culture !== culture) {
      formatter = {
        culture,
        format: new Intl.DateTimeFormat(culture, options).format,
      };

      caches.set(key, formatter);
    }

    return formatter.format;
  };
}();
