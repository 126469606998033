import { ofType } from 'redux-observable';
import { from, merge, EMPTY, of, concat, defer, asyncScheduler } from 'rxjs';
import { switchMap, pluck, filter, exhaustMap, takeUntil, share } from 'rxjs/operators';
import { NAVIGATING, statusCodeResolved, completeNavigation } from 'behavior/routing';
import { handle } from './handle';
import { previewHeader } from './preview';
import { createOfflinePage } from './helpers';
import { hasErrorCode } from 'utils/api/errorCodes';
import { toasts } from 'behavior/toasts';
import { routeNames, routesBuilder } from 'routes';
import { rewriteTo } from 'behavior/routing';
import { viewerChanged } from 'behavior/events';
import { unsetPreviewUser } from 'behavior/user/actions';

function pagesEpic(action$, state$, dependencies) {
  const serviceUnavailableError$ = dependencies.api.errors$.pipe(
    filter(e => e.status === 503),
    share(),
  );

  const serviceUnavailable$ = serviceUnavailableError$.pipe(
    exhaustMap(e => {
      const routing = state$.value.routing;
      const errors = e.response.errors;

      if (errors && hasErrorCode(errors, 'BASKET_UNAVAILABLE')) {
        toasts.warning('', { textKey: 'BasketUnavailable_TryLater' });

        if (routing.routeData.routeName === routeNames.Checkout || routing.routeData.routeName === routeNames.BasketPage)
          return of(rewriteTo(routesBuilder.forBasket()));

        return EMPTY;
      }

      const location = routing.navigatingTo ? routing.navigatingTo.location : routing.location;
      const routeData = routing.navigatingTo ? routing.navigatingTo.routeData : routing.routeData;

      return from([
        statusCodeResolved(503),
        completeNavigation(location, routeData, createOfflinePage()),
      ]);
    }),
  );

  const handleNavigating = (location, routeData) => handle(state$, dependencies, routeData).pipe(
    switchMap(({ page, statusCode, routeData, action$ }) => {
      const result$ = from([
        statusCode ? statusCodeResolved(statusCode) : null,
        page && completeNavigation(location, routeData, page),
      ].filter(Boolean));
      
      if (!action$)
        return result$;

      return merge(result$, action$);
    }),
    takeUntil(serviceUnavailableError$),
  );

  const navigating$ = action$.pipe(
    ofType(NAVIGATING),
    pluck('payload'),
    switchMap(({ location, routeData }) => {
      const navigatingFromPreview = state$.value.routing.routeData?.params?.previewToken;

      if (navigatingFromPreview && !routeData.params?.previewToken) {
        dependencies.api.headers.delete(previewHeader);

        if (state$.value.user.previewData)
          return concat(
            of(unsetPreviewUser(), viewerChanged()),
            defer(() => handleNavigating(location, routeData)),
            asyncScheduler,
          );
      }

      return handleNavigating(location, routeData);
    }),
  );

  return merge(navigating$, serviceUnavailable$);
}

export default pagesEpic;
