import PropTypes from 'prop-types';

const LineTemplatePropTypes = {
  productInfo: PropTypes.node.isRequired,
  productActionBlock: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  actionLinks: PropTypes.node,
  quantityBox: PropTypes.node.isRequired,
  uomLabel: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  priceNode: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.bool,
  ]),
  productDiscount: PropTypes.node,
  subTotal: PropTypes.node,
  className: PropTypes.string,
  isGroupedLine: PropTypes.bool,
  id: PropTypes.string,
  product: PropTypes.object,
  handleSerialNoChange: PropTypes.func,
  line: PropTypes.object,
};

export default LineTemplatePropTypes;