import React, { useCallback, useState, useEffect } from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import QuantityContext from './QuantityContext';
import { useProductContext } from './hooks';
import { addProducts } from 'behavior/basket';
import { generateKey } from 'utils/helpers';
import { createQuantityModel } from 'components/primitives/product';

const OrderBoxForm = ({ children, modifiedDate, updatedById, addProducts }) => {
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const { product, uomId, variantId } = useProductContext();

  const updateQuantity = useCallback(quantity => {
    updateContext(prev => {
      if (quantity === prev.quantity)
        return prev;

      return { ...prev, quantity };
    });
  }, []);
  const [context, updateContext] = useState(() => {
    const quantityModel = generateQuantityModel(product, uomId);
    return {
      uomId,
      productId: product.id,
      quantity: { value: quantityModel.initial, isValid: true },
      quantityModel,
      updateQuantity,
    };
  });

  const addToBasket = useCallback(e => {
    e.preventDefault();

    if (disabled)
      return;

    const { quantity } = context;
    if (quantity && quantity.isValid) {
      setDisabled(true);

      const line = {
        productId: product.id,
        variantId,
        uomId,
        quantity: quantity.value,
        serialNo: product.serialNo,
      };

      addProducts([line], componentId);
    }
  }, [product, uomId, variantId, context, disabled]);

  useEffect(() => {
    if (updatedById === componentId)
      setDisabled(false);
  }, [modifiedDate, updatedById]);

  useEffect(() => {
    updateContext(prev => {
      if (prev.productId === product.id && prev.uomId === uomId)
        return prev;

      const quantityModel = generateQuantityModel(product, uomId);
      return {
        ...prev,
        uomId,
        productId: product.id,
        quantity: { value: quantityModel.initial, isValid: true },
        quantityModel,
      };
    });
  }, [product.id, uomId]);

  return (
    <form disabled={disabled} onSubmit={addToBasket}>
      <QuantityContext.Provider value={context}>
        {children}
      </QuantityContext.Provider>
    </form>
  );
};

OrderBoxForm.propTypes = {
  children: PropTypes.node.isRequired,
  addProducts: PropTypes.func.isRequired,
  modifiedDate: PropTypes.number,
  updatedById: PropTypes.string,
};

export default connect(({ basket }) => ({
  modifiedDate: basket.modifiedDate,
  updatedById: basket.updated.updaterId,
}), { addProducts })(OrderBoxForm);

function generateQuantityModel(product, uomId) {
  const uom = product.uoms && product.uoms.find(u => u.id === uomId);
  return createQuantityModel(uom, false);
}
