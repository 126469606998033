import styles from './BasketB2B.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Row, Col } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import EmptyBasket from '../Empty';
import NonOrderable from '../nonOrderable';
import RecalculationSuggestion from '../RecalculationSuggestion';
import BasketPaging from '../BasketPaging';
import BasketContent from '../BasketContent';
import { QuoteButton, CheckoutButton } from '../actionButtons';
import { basketModelPropTypes } from '../PropTypes';
import { useIsMobileViewport } from 'utils/hooks';
import { useIsQuickOrderMode } from '../hooks';
import CreditLimitMessage from '../CreditLimitMessage';

const BasketB2B = ({
  model,
  canCreateQuote,
  canCreateOrder,
  lastModifiedLineId,
  quickOrderMaxLines,
}) => {
  if (model == null)
    return null;

  const isMobile = useIsMobileViewport();
  const isQuickOrderMode = useIsQuickOrderMode();

  const {
    productLines: { totalCount },
    nonOrderableLines,
    page,
    totalCount: linesTotalCount,
    isBlocked,
  } = model;

  const nonOrderable = <NonOrderable nonOrderableLines={nonOrderableLines} />;

  const header = !isMobile
    ? (
      <Col xs="auto" className={styles.limitedWidth}>
        <h2 className={styles.heading}>
          <SimpleText
            textKey="MyShoppingBasket"
            placeholder={<Placeholder className={styles.headingPlaceholder} />}
          />
        </h2>
      </Col>
    )
    : null;
  const actionButtonsSection = (orderDisabled, quoteDisabled) => (isMobile && (
    <>
      {canCreateQuote && (
        <Col xs={12} className={styles.btnContainer}>
          <QuoteButton disabled={quoteDisabled || null} canCreateOrder={canCreateOrder} />
        </Col>
      )}
      {canCreateOrder && (
        <Col xs={12} className={styles.btnContainer}>
          <CheckoutButton info={model} disabled={orderDisabled || null} />
        </Col>
      )}
    </>
  ));

  if (!totalCount) {
    return (
      <>
        <Row noGutters className={styles.panelHeader}>
          {header}
          {actionButtonsSection(true, true)}
        </Row>
        {nonOrderable}
        <EmptyBasket />
      </>
    );
  }

  const showPaging = page && page.size < totalCount && !isQuickOrderMode;

  return (
    <div className={styles.basketWrapper}>
      <Row noGutters className={styles.panelHeader}>
        {header}
        {showPaging && (
          <Col xs={12} md className={styles.pagingContainer}>
            <BasketPaging page={page} totalCount={totalCount} />
          </Col>
        )}
        {actionButtonsSection(isBlocked, false)}
      </Row>
      {nonOrderable}
      <CreditLimitMessage creditLimit={model.creditLimit} />
      <RecalculationSuggestion isQuickOrderMode={isQuickOrderMode} />
      <BasketContent
        productLines={model.productLines}
        serviceLines={model.serviceLines}
        lastModifiedLineId={lastModifiedLineId}
      />
      {isQuickOrderMode && totalCount > quickOrderMaxLines && (
        <RecalculationSuggestion
          isQuickOrderMode
          messageId="quickOrderRecalcMsg"
          recalcMessageTextKey="PleaseRecalculateShoppingCartToSeAllLines"
        />
      )}
      {showPaging && (
        <Row className={styles.panelFooter}>
          <Col xs={12} md="auto" className={styles.limitedWidth}>
            <span className={styles.unitTotal}>
              <SimpleText textKey="Basket_UnitTotal" formatWith={[linesTotalCount, totalCount]} />
            </span>
          </Col>
          <Col xs={12} md className={styles.pagingContainer}>
            <BasketPaging page={page} totalCount={totalCount} />
          </Col>
        </Row>
      )}
    </div>
  );
};

BasketB2B.propTypes = {
  ...basketModelPropTypes,
  canCreateQuote: PropTypes.bool,
  canCreateOrder: PropTypes.bool,
  lastModifiedLineId: PropTypes.string,
  quickOrderMaxLines: PropTypes.number.isRequired,
};

export default connect(
  ({
    basket: { model, lastModifiedLineId },
    settings: { basket: { quickOrderMaxLines } },
  }) => ({ model, lastModifiedLineId, quickOrderMaxLines }),
)(BasketB2B);
