import styles from '../OrderBox.module.scss';
import matrixStyles from 'components/objects/product/variantsMatrix/VariantsMatrix.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { useDispatch } from 'react-redux';
import { requestCalculatedFields, requestVariantsGeneralInfo } from 'behavior/pages/productList';
import { UseSanaButton } from 'components/primitives/buttons';
import { Modal, useModal } from 'components/objects/modals';
import VariantsMatrixForm from './VariantsMatrixForm';
import { SimpleText } from 'components/sanaText';
import ListContext from './ListContext';
import Spinner from 'components/primitives/spinner/Spinner';
import { UseSanaTexts } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { makeSimpleText } from 'utils/render';
import ViewProductButton from '../b2c/ViewProductButton';

const preloadTexts = ['Loading'];

const VariantsButton = ({ abilities, quantities, updateVariantQuantities, product }) => {
  const dispatch = useDispatch();
  const { opened, show, hide } = useModal();
  const [uomId, setUomId] = useState(product.uom && product.uom.id);
  const hasQuantities = quantities && quantities.size > 0;

  const selectVariantsBtnRef = useRef();
  const editVariantsBtnRef = useRef();

  const variantsAmount = useMemo(() => getVariantAmount(quantities), [quantities, opened]);

  const updateUom = uomId => {
    setUomId(uomId);
    const options = getProductInfoOptions(product.id, uomId);
    dispatch(requestCalculatedFields(options, true));
  };

  const hideModal = () => {
    hide();
    requestAnimationFrame(() => {

      if (hasQuantities)
        selectVariantsBtnRef.current.focus();
      else
        editVariantsBtnRef.current.focus();
    });
  };

  const openMatrix = () => {
    if (!product.variants) {
      const options = getProductInfoOptions(product.id, uomId);
      dispatch(requestVariantsGeneralInfo(options, true));
    }

    show();
  };

  const updateProductQuantities = quantities => {
    updateVariantQuantities(product.id, uomId, new Map(quantities));
    hideModal();
  };

  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;

  if (product.variantComponentGroups.length > 2)
    return (
      <ViewProductButton
        textKey="SelectVariants"
        className={`${btnStyles.btnSmall} btn-action`}
        product={product}
        placeholder={btnPlaceholder}
      />
    );

  const textPlaceholder = <Placeholder className={styles.textPlaceholder} />;

  let popupContent = null;
  const loaded = product.variantComponentGroups.length === 0 || product.variantComponentGroups[0].components;

  if (loaded) {
    popupContent = (
      <VariantsMatrixForm
        quantities={quantities}
        abilities={abilities}
        product={product}
        uomId={uomId}
        updateUomId={updateUom}
        onQuantitiesSubmit={updateProductQuantities}
      />
    );
  } else {
    popupContent = (
      <div className={styles.loader}>
        <div className={styles.spinner}><Spinner /></div>
        <SimpleText textKey="Loading" placeholder={textPlaceholder} />
      </div>
    );
  }

  const selectedVariantsCount = <span className={styles.variantsAmount}>{variantsAmount}</span>;

  return (
    <>
      <span className={hasQuantities ? null : styles.hide}>
        <SimpleText
          textKey="VariantsSelected_Label"
          formatWith={[selectedVariantsCount]}
          placeholder={textPlaceholder}
          formatAsHtml
        />
        <UseSanaButton textKey="Edit" placeholder={textPlaceholder}>
          {(text, title) => (
            <span className={styles.variantsEditBtn}>
              <button title={title} className={linkStyles.link} onClick={show} ref={editVariantsBtnRef}>
                {makeSimpleText(text)}
              </button>
            </span>
          )}
        </UseSanaButton>
      </span>
      <span className={hasQuantities ? styles.hide : null}>
        <UseSanaButton textKey="SelectVariants" placeholder={btnPlaceholder}>
          {(text, title) => (
            <button
              className={`${btnStyles.btn} ${btnStyles.btnSmall} btn-action`}
              title={title}
              onClick={openMatrix}
              ref={selectVariantsBtnRef}
            >
              <span className="btn-cnt">
                {makeSimpleText(text)}
              </span>
            </button>
          )}
        </UseSanaButton>
      </span>
      <Modal
        opened={opened}
        hide={hide}
        resetContentOnClose
        size="large"
        className={matrixStyles.variantPopup}
      >
        <div className={styles.variants}>
          {popupContent}
        </div>
      </Modal>
      <UseSanaTexts options={preloadTexts} />
    </>
  );
};

VariantsButton.propTypes = {
  abilities: PropTypes.object.isRequired,
  quantities: PropTypes.instanceOf(Map),
  updateVariantQuantities: PropTypes.func.isRequired,
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    uomId: PropTypes.string,
  }).isRequired,
};

const memoizedVariantsButton = React.memo(VariantsButton);

const mapContextToProps = ({ quantities, updateVariantQuantities }, { product }) => {
  const productQuantities = quantities.get(product.id);

  return {
    quantities: productQuantities,
    updateVariantQuantities,
  };
};

export default connectToContext([ListContext], mapContextToProps)(memoizedVariantsButton);

function getProductInfoOptions(productId, uomId) {
  return { ids: [productId], uomId };
}

function getVariantAmount(quantities) {
  if (!quantities)
    return 0;

  let variantsAmount = 0;

  for (const uoms of quantities.values())
    variantsAmount += uoms.size;

  return variantsAmount;
}