import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let VdRowWrapper;

const VdRowContainer = ({ children, row, visualDesignerInitialized, index }) => {
  if (!visualDesignerInitialized)
    return children;

  const { id, nested, isPlaceholder } = row;
  const hasContent = nested.some(item => item.nested && item.nested.length);

  if (!VdRowWrapper)
    VdRowWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./row'));

  return (
    <VdRowWrapper
      index={index}
      id={id}
      columnsLength={nested.length}
      hasContent={hasContent}
      isPlaceholder={isPlaceholder}
    >
      {children}
    </VdRowWrapper>
  );
};

VdRowContainer.propTypes = {
  children: PropTypes.node,
  visualDesignerInitialized: PropTypes.bool.isRequired,
  index: PropTypes.number,
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    nested: PropTypes.arrayOf(PropTypes.shape({
      nested: PropTypes.array,
    })),
  }),
};

export default connect(({ visualDesigner }) => ({
  visualDesignerInitialized: !!visualDesigner.initialized,
}))(VdRowContainer);