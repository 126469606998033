import { useSelector } from 'react-redux';
import { parseQuery } from 'utils/url';

export default (initialOnly = false) => {
  const search = useSelector(state => state.routing.location.search, initialOnly ? _ => true : undefined);

  let cached, searchUsedForCached;
  return ({
    get values() {
      if (!cached || (!initialOnly && search !== searchUsedForCached)) {
        cached = parseQuery(search);
        if (!initialOnly)
          searchUsedForCached = search;
      }

      return cached;
    },
  });
};