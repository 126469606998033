exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Specifications_field-name{color:var(--darkestText_FontColor,#000);font-weight:700;font-size:var(--normalText_BiggerFontSize,14px);padding-top:.3em;padding-bottom:.3em}.Specifications_title{margin-bottom:0}.Specifications_field-value{word-wrap:break-word;padding:.31em .75em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/page/specifications/Specifications.module.scss","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/page/_comparisonPageMixins.scss"],"names":[],"mappings":"AAKA,2BACI,wCACA,gBACA,gDACA,iBACA,mBAJJ,CAOA,sBCZI,eDSJ,CAOA,4BCZI,qBACA,mBDSJ,CAAA","file":"Specifications.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n/// <reference path=\"../_comparisonPageMixins.scss\" />\n@import 'css/_theme.scss';\n@import '../_comparisonPageMixins.scss';\n\n.field-name {\n    color: $darkestText_FontColor;\n    font-weight: bold;\n    font-size: $normalText_BiggerFontSize;\n    padding-top: 0.3em;\n    padding-bottom: 0.3em;\n}\n\n.title {\n    @include row-header;\n}\n\n.field-value {\n    @include row-cell;\n}","﻿@mixin row-header {\n    margin-bottom: 0;\n}\n\n@mixin row-cell {\n    word-wrap: break-word;\n    padding: 0.31em 0.75em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"field-name": "Specifications_field-name",
	"fieldName": "Specifications_field-name",
	"title": "Specifications_title",
	"field-value": "Specifications_field-value",
	"fieldValue": "Specifications_field-value"
};