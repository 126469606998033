exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".DisabledSearchBox_fake-form{display:flex;align-items:center}.DisabledSearchBox_fake-input{color:var(--inputs_FontColor,#333);line-height:1.2;padding-left:1.2em;margin-right:4.2em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/stdin","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/search/DisabledSearchBox.module.scss"],"names":[],"mappings":"AAMA,6BACI,aACA,kBCUJ,CDPA,8BACI,mCACA,gBACA,mBACA,kBCUJ,CAAA","file":"DisabledSearchBox.module.scss","sourcesContent":["/// <reference path=\"../../../css/_variables.scss\" />\n/// <reference path=\"../../../css/_theme.scss\" />\n/// <reference path=\"../../../css/_mixins.scss\" />\n@import 'css/_variables.scss';\n@import 'css/_mixins.scss';\n\n.fake-form {\n    display: flex;\n    align-items: center;\n}\n\n.fake-input {\n    color: $inputs_FontColor;\n    line-height: 1.2;\n    padding-left: 1.2em;\n    margin-right: 4.2em;\n}\n","/* GLOBAL Z-ORDER POSITIONING */\n:export {\n  isIE: (-ms-high-contrast: active), (-ms-high-contrast: none);\n  isFirefox: (min--moz-device-pixel-ratio: 0);\n  isSafari: not all and (min-resolution:.001dpcm);\n  isTouchDevice: (pointer: coarse);\n  isTouchScreen: (pointer: fine) and (any-pointer: coarse) and (any-pointer: fine);\n  hasMultiplePointers: (any-pointer: coarse) and (any-pointer: fine);\n}\n\n/* DEVICE TYPE DETECTIONS */\n/* BROWSERS DETECTIONS */\n/* GENERAL MIXINS */\n/* PRINT-MODE RELATED MIXINS */\n/* RESPONSIVE */\n.fake-form {\n  display: flex;\n  align-items: center;\n}\n\n.fake-input {\n  color: var(--inputs_FontColor, #333);\n  line-height: 1.2;\n  padding-left: 1.2em;\n  margin-right: 4.2em;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"isIE": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isIe": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isFirefox": "(min--moz-device-pixel-ratio:0)",
	"isSafari": "not all and (min-resolution:.001dpcm)",
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)",
	"hasMultiplePointers": "(any-pointer:coarse) and (any-pointer:fine)",
	"fake-form": "DisabledSearchBox_fake-form",
	"fakeForm": "DisabledSearchBox_fake-form",
	"fake-input": "DisabledSearchBox_fake-input",
	"fakeInput": "DisabledSearchBox_fake-input"
};