import PropTypes from 'prop-types';
import { AbilityState } from './constants';

export const abilityPropType = PropTypes.oneOf([
  AbilityState.Unauthorized,
  AbilityState.Available,
  AbilityState.NotAvailable,
  AbilityState.TemporaryNotAvailable,
]);

export const abilitiesPropType = PropTypes.arrayOf(abilityPropType);

export {
  login,
  register,
  logout,
  representCustomer,
  stopRepresentingCustomer,
  loadUserAbilities,
  USER_LOGOUT,
  createProspect,
  USER_PROFILE_UPDATED,
  userProfileUpdated,
  resetLoginFailed,
  resetImpersonationFailed,
  setPreviewUser,
  unsetPreviewUser,
} from './actions';

export { USER_ANON_EXPIRED } from './broadcastActions';

export { CustomerTypes, UserTypes, ShopAccountTypes } from './constants';