exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Error_title{text-transform:uppercase}.Error_action-list,.Error_body p{margin:0}.Error_body .Error_action-btn{color:var(--toasts_ErrorFontColor,#600502);text-decoration:underline;font-weight:700}.Error_body .Error_action-btn:hover{color:var(--toasts_ErrorFontColor,#600502)}.Error_block ul{padding-left:1.5em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/error/Error.module.scss"],"names":[],"mappings":"AAGA,aACI,wBAFJ,CAUI,iCACI,QAHR,CAMI,8BACI,2CACA,0BACA,eAJR,CAMQ,oCACI,0CAJZ,CASA,gBACI,kBANJ,CAAA","file":"Error.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.title {\n    text-transform: uppercase;\n}\n\n.action-list {\n    margin: 0;\n}\n\n.body {\n    p {\n        margin: 0;\n    }\n\n    .action-btn {\n        color: $toast_ErrorFontColor;\n        text-decoration: underline;\n        font-weight: bold;\n\n        &:hover {\n            color: $toast_ErrorFontColor;\n        }\n    }\n}\n\n.block ul {\n    padding-left: 1.5em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"title": "Error_title",
	"action-list": "Error_action-list",
	"actionList": "Error_action-list",
	"body": "Error_body",
	"action-btn": "Error_action-btn",
	"actionBtn": "Error_action-btn",
	"block": "Error_block"
};