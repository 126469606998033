import styles from './MainNav.module.scss';
import React, { useRef, useEffect, useContext, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ImagePosition } from 'behavior/navigation';
import MultiColumnView from './MultiColumnView';
import SimpleView from './simpleView/SimpleView';
import { NavDimensionsContext } from './NavDimensionsProvider';
import SublistPositionProvider, { DIRECTIONS } from './SublistPositionProvider';
import { MainNavItemPropTypes, MainNavImagePropTypes } from './PropTypes';
import { shouldRenderNavigation } from '../helpers';
import { triggerSubItemsPositionCalculation } from './simpleView/SimpleViewSubItems';
import { useMultiColumnDropdownSelector } from './hooks';
import { ResponsiveLazyImage } from 'components/primitives/responsiveImages';
import { Link } from 'components/primitives/links';
import { removeListHoverState, setFocus } from './eventHandlers';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useIsTouchScreen } from 'utils/detections';
import { useIsMouse } from 'components/detection';

const textsToLoad = ['Aria_MainNavigation_Submenu'];
const subListWidth = +styles.subListWidth;
const positionParams = { direction: DIRECTIONS.FORWARDS };

const NavItemSubItems = ({ subItems, image, id, title, isAccordion = false, isModal = false, isDesktop = false, expanded }) => {
  const [sublistParams, setSublistParams] = useState(positionParams);
  const { startOffset, direction } = sublistParams;
  const navDimensions = useContext(NavDimensionsContext);
  const multiColumnDropdown = useMultiColumnDropdownSelector();
  const isTouchScreen = useIsTouchScreen();
  const isMouse = useIsMouse();
  const navWrapperRef = useRef(null);
  const subItemsRef = useRef(null);
  let showImage = false;
  let imgItemStyles = '';

  const shouldCalculatePosition = useMemo(() => {
    if (!isDesktop)
      return false;

    return subItems.some(({ children }) => shouldRenderNavigation(children));
  }, [subItems]);

  if (isDesktop) {
    showImage = subItems.length < 5 && !!image && image.path != null;

    if (showImage) {
      const { position, keepOriginalSize } = image;
      imgItemStyles = styles.imgItem;

      if (position === ImagePosition.RightBottom)
        imgItemStyles += ` ${styles.rightBottom}`;

      if (keepOriginalSize)
        imgItemStyles += ` ${styles.originalSize}`;
    }
  }

  let setGeneralNavSublistParams = null;

  if (isDesktop) {
    setGeneralNavSublistParams = () => {
      if (!navDimensions)
        return;

      const { navWidth, navContainerWidth } = navDimensions;
      let { width, left, right } = subItemsRef.current.parentElement.getBoundingClientRect();
      // The above values should be rounded to perform correct calculations in Legacy MS Edge and IE11.
      width = Math.round(width);
      left = Math.round(left);
      right = Math.round(right);
      const isNavItemSmallerThanSublist = width < subListWidth;
      const navLeftOffset = (navContainerWidth - navWidth) / 2;
      const isPositionToRight = isNavItemSmallerThanSublist
        ? left + subListWidth >= navWidth + navLeftOffset
        : right + subListWidth > navWidth;

      subItemsRef.current.classList.remove(styles.rightPos);

      if (isPositionToRight)
        subItemsRef.current.classList.add(styles.rightPos);

      if (!shouldCalculatePosition)
        return;

      const startOffset = isPositionToRight
        ? isNavItemSmallerThanSublist
          ? navContainerWidth - (right - subListWidth) - navLeftOffset
          : navContainerWidth - left - navLeftOffset
        : isNavItemSmallerThanSublist
          ? left - navLeftOffset + subListWidth
          : right - navLeftOffset;
      const direction = isPositionToRight ? DIRECTIONS.BACKWARDS : DIRECTIONS.FORWARDS;

      if (sublistParams.direction !== direction || sublistParams.startOffset !== startOffset)
        setSublistParams({ startOffset, direction });
    };
  }

  useEffect(() => {
    if (!isDesktop || startOffset == null)
      return;

    triggerSubItemsPositionCalculation(subItems);
  }, [startOffset]);

  useEffect(() => {
    if (navDimensions == null)
      return;

    if (isDesktop) {
      !multiColumnDropdown && setGeneralNavSublistParams();
    }

    triggerSubItemsPositionCalculation(subItems);

    if (!multiColumnDropdown || !navWrapperRef.current)
      return;

    // Set max-height value for modal nav multi column view list
    navWrapperRef.current.style.maxHeight = `${navDimensions.menuMaxHeight}px`;
  }, [navDimensions]);

  useEffect(() => {
    if (!isModal || !multiColumnDropdown || !navWrapperRef.current)
      return;

    let touchStartPageY = null;

    const handleTouchStart = ({ touches }) => {
      touchStartPageY = touches[0].pageY;
    };

    const handleTouchMove = e => {
      if (!e.cancelable)
        return;

      const { currentTarget: { offsetHeight, scrollHeight, scrollTop }, touches } = e;
      const isSwipeUp = touches[0].pageY < touchStartPageY;
      const isScrollPresent = scrollHeight > offsetHeight;
      const isScrolledToTopEdge = scrollTop === 0;

      if (!isScrollPresent || (isSwipeUp && !shouldScroll(e)) || (!isSwipeUp && isScrolledToTopEdge))
        e.preventDefault();
    };
    // The above listeners prevent main page content scrolling when scrolled to the bottom point of modal nav multi column view list.
    navWrapperRef.current.addEventListener('touchstart', handleTouchStart);
    navWrapperRef.current.addEventListener('touchmove', handleTouchMove, { passive: false });
    navWrapperRef.current.addEventListener('wheel', handleWheel);

    return () => {
      navWrapperRef.current.removeEventListener('touchstart', handleTouchStart);
      navWrapperRef.current.removeEventListener('touchmove', handleTouchMove);
      navWrapperRef.current.removeEventListener('wheel', handleWheel);
    };
  }, [!!navWrapperRef.current]);

  const shouldWrapSubItems = isModal && multiColumnDropdown;

  let subItemsStyles = `${styles.subItems} ${styles.focusable}`;

  if (isModal && !multiColumnDropdown)
    subItemsStyles += ` ${styles.leftPos}`;

  if (isDesktop && !showImage)
    subItemsStyles += ` ${styles.noImage}`;

  const subItemsListNode = (
    <UseSanaTexts options={textsToLoad} dontWait>
      {([ariaMainNavSubmenuText]) => (
        <ul
          id={id}
          className={subItemsStyles}
          ref={subItemsRef}
          // onMouseLeave is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
          onMouseLeave={multiColumnDropdown || (isTouchScreen && isMouse === false) ? null : removeListHoverState}
          // SubItems should be focusable on desktop resolutions and for simple view for proper event handling in Mobile Safari,
          // Legacy MS Edge and IE11 when using laptop with touch screen.
          tabIndex={isDesktop || !multiColumnDropdown ? '-1' : null}
          aria-label={`${title} ${stripHtmlTagsAndFormatting(ariaMainNavSubmenuText)}`}
          aria-hidden={shouldWrapSubItems ? null : isAccordion ? !expanded : 'true'}
          aria-expanded={shouldWrapSubItems ? null : isAccordion ? expanded : 'false'}
        >
          {subItems.map(subItem => {
            const areSubItemsPresent = shouldRenderNavigation(subItem.children);
            if (multiColumnDropdown)
              return <MultiColumnView key={subItem.id} item={subItem} />;

            if (!isAccordion && areSubItemsPresent && navDimensions)
              return (
                <SublistPositionProvider
                  key={subItem.id}
                  navWidth={navDimensions.navWidth}
                  startOffset={
                    isModal
                      ? navDimensions.listRootWidth + subListWidth
                      : startOffset
                  }
                  startDirection={direction}
                >
                  <SimpleView item={subItem} isAccordion={isAccordion} />
                </SublistPositionProvider>
              );

            return <SimpleView key={subItem.id} item={subItem} isAccordion={isAccordion} />;
          })}
          {showImage &&
            <li className={imgItemStyles}>
              {image.link
                ? (
                  <Link {...image.link} className={styles.imgLink} onClick={setFocus}>
                    <ResponsiveLazyImage src={image.path} alt={title} className={styles.img} />
                  </Link>
                )
                : <ResponsiveLazyImage src={image.path} alt={title} className={styles.img} />
              }
            </li>
          }
        </ul>
      )}
    </UseSanaTexts>
  );

  return shouldWrapSubItems
    ? (
      <section
        // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
        tabIndex="-1"
        onScroll={handleScroll}
        // data-scroll-lock-scrollable - attribute that allows scrolling on and inside of element when body scroll disabled
        data-scroll-lock-scrollable
        className={`${styles.navWrapper} ${styles.focusable}`}
        ref={navWrapperRef}
        aria-hidden="true"
        aria-expanded="false"
      >
        {subItemsListNode}
      </section>
    )
    : subItemsListNode;
};

NavItemSubItems.propTypes = {
  subItems: PropTypes.arrayOf(MainNavItemPropTypes),
  image: MainNavImagePropTypes,
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  isAccordion: PropTypes.bool,
  isModal: PropTypes.bool,
  isDesktop: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default React.memo(NavItemSubItems);

function handleScroll(e) {
  const { currentTarget } = e;
  const { offsetHeight, scrollHeight, scrollTop } = currentTarget;

  if (scrollTop < 0)
    currentTarget.scrollTop = 0;

  if (shouldScroll(e))
    return;

  currentTarget.scrollTop = scrollHeight - offsetHeight;
}

function handleWheel(e) {
  if (!e.cancelable)
    return;

  const scrollIsAtTopPosition = (e.currentTarget.scrollTop === 0);
  const scrollingUp = Math.sign(e.deltaY) === -1;
  const scrollingDown = Math.sign(e.deltaY) === 1;
  const shouldNotScrollUp = scrollIsAtTopPosition && scrollingUp;
  const shouldNotScrollDown = !shouldScroll(e) && scrollingDown;

  if (shouldNotScrollUp || shouldNotScrollDown)
    e.preventDefault();
}

function shouldScroll(e) {
  const { currentTarget: { scrollTop, offsetHeight, scrollHeight } } = e;
  return scrollTop < scrollHeight - offsetHeight;
}
