import { useCallback } from 'react';
import { useGraphApi } from 'utils/hooks';
import { executeCommandMutation } from './queries';

export default function useExtraCheckoutStepCommand() {
  const apiCall = useGraphApi();

  return useCallback(
    args => apiCall(
      executeCommandMutation,
      { input: { arguments: args } },
      ({ checkout }) => checkout?.paymentMethod.extraCheckoutStep?.executeCommand?.result,
    ).toPromise(),
    [],
  );
}