exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Selector_cnt label{cursor:pointer}.Selector_cnt .Selector_chb input:disabled~ins{color:var(--radio_Checked_BackgroundColor,#1f7bc9);background-color:var(--radio_BackgroundColor,#fff)}.Selector_cnt .Selector_chb:hover input:disabled~ins{color:var(--radio_Checked_BackgroundColor,#1f7bc9);background-color:var(--radio_BackgroundColor,#fff);border-color:var(--radio_Hover_BorderColor,#a6a6a6)}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/productComparison/Selector.module.scss"],"names":[],"mappings":"AAII,oBACI,cAHR,CAOQ,+CACI,mDACA,kDALZ,CAQQ,qDACI,mDACA,mDACA,mDANZ,CAAA","file":"Selector.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.cnt {\n    label {\n        cursor: pointer;\n    }\n\n    .chb {\n        input:disabled ~ ins {\n            color: $radio_Checked_BackgroundColor;\n            background-color: $radio_BackgroundColor;\n        }\n\n        &:hover input:disabled ~ ins {\n            color: $radio_Checked_BackgroundColor;\n            background-color: $radio_BackgroundColor;\n            border-color: $radio_Hover_BorderColor;\n        }\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"cnt": "Selector_cnt",
	"chb": "Selector_chb"
};