import styles from '../../Checkout.module.scss';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Radio, HiddenSubmitButton } from 'components/primitives/form';
import { makeRichText } from 'utils/render';
import { useSimpleTexts } from 'components/sanaText';
import { useIsOffline, useCurrencyInfo, useOnChange } from 'utils/hooks';
import { loadPickupLocations } from 'behavior/pages/checkout';
import { useDispatch } from 'react-redux';
import { MethodCost } from '../MethodCost';
import { PickupLocations } from './PickupLocations';
import Spinner from 'components/primitives/spinner/Spinner';

const ShippingMethodsList = ({
  shippingMethods,
  locations,
  selectedMethodId,
  selectedLocationId,
  onSelect,
  onSubmit,
}) => {
  const currency = useCurrencyInfo();

  let [selectedIdState, setSelectedIdState] = useState(selectedMethodId);
  const [selectedLocationIdState, setSelectedLocationIdState] = useState(selectedLocationId);
  const [
    freeCostText,
    costsLbl,
    loadingLocationsText,
  ] = useSimpleTexts([
    'FreeCosts',
    'Costs',
    'ShippingMethods_LoadingPickupLocations',
  ]).texts;
  const isOffline = useIsOffline();

  const dispatch = useDispatch();

  const handleChange = ({ target: { value } }) => {
    setSelectedIdState(value);
    onSelect(value);
  };

  useOnChange(
    () => {
      setSelectedIdState(selectedMethodId);
      selectedIdState = selectedMethodId;
    },
    [shippingMethods, selectedMethodId],
    false,
  );

  useEffect(() => {
    if (locations && selectedIdState in locations) {
      const methodLocations = locations[selectedIdState];
      if (selectedIdState === selectedMethodId && methodLocations.some(l => l.id === selectedLocationId)) {
        onSelect(selectedIdState, selectedLocationId);
        setSelectedLocationIdState(selectedLocationId);
      } else {
        const newLocationId = methodLocations[0]?.id;
        onSelect(selectedIdState, newLocationId);
        setSelectedLocationIdState(newLocationId);
      }

      return;
    }

    const method = shippingMethods.find(m => m.id === selectedIdState);
    if (method && method.hasLocations) {
      dispatch(loadPickupLocations(selectedIdState));
    }
  }, [selectedIdState, locations]);

  const hasIcons = shippingMethods.some(method => method.imageUrl);

  const Tag = onSubmit ? 'form' : 'div';

  return (
    <Tag className={styles.methodsList + (hasIcons ? '' : ` ${styles.noIcons}`)} onSubmit={onSubmit}>
      {shippingMethods.map((method, index) => {
        const costId = method.cost != null ? `shipping-cost-${index}` : '';
        const descriptionId = method.description ? `shipping-description-${index}` : '';

        const describedBy = `${costId} ${descriptionId}`.trim() || null;

        const checked = method.id === selectedIdState;
        const expandLocations = checked && method.hasLocations,
          methodLocations = expandLocations && locations && (locations[method.id] || null);
        const methodLocationsLoading = expandLocations && !methodLocations;

        return (
          <div key={method.id + index}>
            <div className={styles.item}>
              {/* Container below was added for IE11 to properly calculate size of label element with `display: table` rule in case of long method name. */}
              <div>
                <Radio
                  name="shipping"
                  value={method.id}
                  className={styles.radio}
                  checked={checked}
                  onChange={handleChange}
                  aria-describedby={describedBy}
                >
                  {hasIcons &&
                    <i className={styles.icon} aria-hidden>
                      {method.imageUrl && <img src={method.imageUrl} alt={method.name} />}
                    </i>
                  }
                  {method.name}
                </Radio>
              </div>
              {!isOffline &&
                <MethodCost cost={method.cost}
                  id={costId}
                  ariaLabel={costsLbl}
                  freeCostText={freeCostText}
                  currency={currency}
                />
              }
            </div>
            {method.description &&
              <div className={styles.description} id={descriptionId}>
                {makeRichText(method.description)}
              </div>
            }
            {checked && loadingLocationsText && methodLocationsLoading &&
              <div className={styles.locationsLoader}>
                <Spinner className={styles.spinner} />
                <span className={styles.loadingText}>{loadingLocationsText}</span>
              </div>
            }
            {expandLocations && methodLocations &&
              <PickupLocations
                locationId={selectedLocationIdState}
                locations={methodLocations}
                currency={currency}
                onChange={handleLocationChange}
              />
            }
          </div>
        );
      })}
      {onSubmit && <HiddenSubmitButton />}
    </Tag>
  );

  function handleLocationChange(value) {
    setSelectedLocationIdState(value);
    onSelect && onSelect(selectedIdState, value);
  }
};

ShippingMethodsList.propTypes = {
  shippingMethods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    cost: PropTypes.number,
    imageUrl: PropTypes.string,
    hasLocations: PropTypes.bool,
  }).isRequired).isRequired,
  locations: PropTypes.object,
  selectedMethodId: PropTypes.string,
  selectedLocationId: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
};

export default React.memo(ShippingMethodsList);
