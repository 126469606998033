export const FALLBACK_ACTION = 'REDUCER_FALLBACK';

export default function createReducer(initialState, handlers) {
  return function reducer(state = initialState, action) {
    if (handlers.hasOwnProperty(action.type))
      return handlers[action.type](state, action, initialState);

    if (handlers.hasOwnProperty(FALLBACK_ACTION))
      return handlers[FALLBACK_ACTION](state, action, initialState);

    return state;
  };
}