import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { formatAsPrice } from 'utils/format';
import { createPriceModel } from './priceUtils';

const emptyImages = {};
export const useProductDefaultImages = () => useSelector(({ settings: { product } }) => product ? product.noImage : emptyImages);

export const useFormattedPrice = (price, format) => {
  const currency = useSelector(({ settings }) => settings.currency);

  return useMemo(() => {
    if (price == null || !currency)
      return null;

    const formattedPrice = formatAsPrice(price, currency);
    return format ? format(formattedPrice) : formattedPrice;
  }, [price, currency]);
};

const emptyPriceModel = {};
export const usePriceModel = (salesPrice, basePrice) => {
  const actionPricesMode = useSelector(({ settings: { product } }) => product && product.actionPricesPresentationType);

  return useMemo(() => {
    if (salesPrice == null || !actionPricesMode)
      return emptyPriceModel;

    return createPriceModel(salesPrice, basePrice, actionPricesMode);
  }, [salesPrice, basePrice, actionPricesMode]);
};