exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Placeholder_placeholder-item{height:1em;background-color:var(--placeholderColor,#f7f7f7);background-clip:content-box;box-sizing:content-box;display:block}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/placeholders/Placeholder.module.scss"],"names":[],"mappings":"AAGA,8BACI,WACA,iDACA,4BACA,uBACA,aAFJ,CAAA","file":"Placeholder.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.placeholder-item {\n    height: 1em;\n    background-color: $placeholderColor;\n    background-clip: content-box;\n    box-sizing: content-box;\n    display: block;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"placeholder-item": "Placeholder_placeholder-item",
	"placeholderItem": "Placeholder_placeholder-item"
};