exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".OpenerButton_btn-menu-opener{width:64px;height:62px;padding:0;font-size:24px;background-color:transparent;color:#1f7bc9;cursor:pointer;border-color:var(--header_BorderColor,#e6e6e6);border-style:solid;border-width:0 1px}.OpenerButton_btn-menu-opener.active{background-color:var(--header_Menu_Sticky_BackgroundColor,#fff);color:grey}.OpenerButton_icon{max-width:.3em;max-height:.25em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/navigation/mainNav/OpenerButton.module.scss"],"names":[],"mappings":"AAGA,8BACI,WACA,YACA,UACA,eACA,6BACA,cACA,eAGA,+CAAA,mBAAA,kBAFJ,CAII,qCACI,gEACA,UAFR,CAMA,mBACI,eACA,gBAHJ,CAAA","file":"OpenerButton.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n@import \"css/_theme.scss\";\n\n.btn-menu-opener {\n    width: 64px;\n    height: 62px;\n    padding: 0;\n    font-size: 24px;\n    background-color: transparent;\n    color: #1f7bc9;\n    cursor: pointer;\n    border-width: 0 1px;\n    border-style: solid;\n    border-color: $header_BorderColor;\n\n    &:global(.active) {\n        background-color: $header_Menu_Sticky_BackgroundColor;\n        color: #808080;\n    }\n}\n\n.icon {\n    max-width: 0.3em;\n    max-height: 0.25em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"btn-menu-opener": "OpenerButton_btn-menu-opener",
	"btnMenuOpener": "OpenerButton_btn-menu-opener",
	"icon": "OpenerButton_icon"
};