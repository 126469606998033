import styles from './ProductComparisonWidget.module.scss';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useProductRoute } from '../hooks';
import { removeProductFromComparison } from 'behavior/productComparison/actions';
import { LazyImage } from 'components/primitives/responsiveImages';
import { Link } from 'components/primitives/links';
import RemoveButton from '../RemoveButton';
import { ProductTrackingContext, WithProductClickTracking } from 'components/objects/analytics';
import { EVENT_SOURCES } from 'behavior/analytics';

const ComparisonListItem = ({ item, noImage, removeProduct }) => {
  const { id, title, url, image } = item;
  const route = useProductRoute(id);

  return (
    <ProductTrackingContext.Provider value={{ product: item , trackingSource: EVENT_SOURCES.compareProductsWidget }}>
      <li className={styles.listItem}>
        <WithProductClickTracking>
          {handleClick => (
            <Link onClick={handleClick} draggable="false" className={styles.link} url={url} to={route}>
              <LazyImage draggable="false" src={(image && image.small) || noImage} alt="" className={styles.linkImg} aria-hidden />
              <span className={styles.linkTitle}>{title}</span>
            </Link>
          )}
        </WithProductClickTracking>
        <RemoveButton className={styles.btnRemoveItem} onClick={() => removeProduct(id)} />
      </li>
    </ProductTrackingContext.Provider>
  );
};

ComparisonListItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    image: PropTypes.shape({
      small: PropTypes.string,
    }),
    url: PropTypes.string.isRequired,
  }).isRequired,
  noImage: PropTypes.string,
  removeProduct: PropTypes.func.isRequired,
};

const mapStateToProps = ({ settings }) => ({
  noImage: settings && settings.product && settings.product.noImage && settings.product.noImage.small,
});

const mapDispatchToProps = {
  removeProduct: removeProductFromComparison,
};

export default connect(mapStateToProps, mapDispatchToProps)(ComparisonListItem);