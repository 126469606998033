import { switchMap, map, catchError } from 'rxjs/operators';
import { merge, throwError, of } from 'rxjs';
import { ofType } from 'redux-observable';
import { themeLoaded, THEME_REQUESTED } from './actions';
import { loadThemeQuery } from './queries';
import { ErrorVersion } from './constants';

const themeEpic = (action$, _, { api }) => action$.pipe(
  ofType(THEME_REQUESTED),
  switchMap(_ => api.graphApi(loadThemeQuery).pipe(
    map(data => themeLoaded(data.theme)),
  )),
  catchError(e => merge(of(themeLoaded({ version: ErrorVersion })), throwError(e))),
);

export default themeEpic;
