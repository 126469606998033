import {
  getYear, getMonth as getMonthIndex,
  getCenturyStart, getPreviousCenturyStart, getNextCenturyStart, getCenturyEnd, getPreviousCenturyEnd, getCenturyRange,
  getDecadeStart, getPreviousDecadeStart, getNextDecadeStart, getDecadeEnd, getPreviousDecadeEnd, getDecadeRange,
  getYearStart, getPreviousYearStart, getNextYearStart, getYearEnd, getPreviousYearEnd, getYearRange,
  getMonthStart, getPreviousMonthStart, getNextMonthStart, getMonthEnd, getPreviousMonthEnd, getMonthRange,
  getDayStart, getDayEnd, getDayRange,
} from '@wojtekmaj/date-utils';
import type { DateInput } from '@wojtekmaj/date-utils';
import { DaysOfWeek, CalendarTypes, PeriodTypes } from '../enums';

type Formatter = (locale: string, date: Date) => string;

export type { Formatter };

/**
 * Gets day of the week index for provided date and first day of week name or calendar type.
 * @param {Date} date - target date.
 * @param {string} firstDayOfWeek - first day of week name.
 * @param {string} calendarType - type of calendar (ISO 8601, US, Arabic or Hebrew).
 * @returns {number|Error} - day of week index (0 - 6) or error in case of wrong calendar type.
 */
export function getDayOfWeekIndex(date: Date, firstDayOfWeek: DaysOfWeek, calendarType = CalendarTypes.ISO_8601): number {
  const weekDay = date.getDay();
  if (firstDayOfWeek !== null)
    return (weekDay + 7 - firstDayOfWeek) % 7;

  switch (calendarType) {
    case CalendarTypes.ISO_8601:
      return (weekDay + 6) % 7;
    case CalendarTypes.ARABIC:
      return (weekDay + 1) % 7;
    case CalendarTypes.HEBREW:
    case CalendarTypes.US:
      return weekDay;
    default:
      throw new Error('Unsupported calendar type.');
  }
}

/**
 * Gets starting year of century for provided date.
 * @param {DateInput} date - target date.
 * @returns {number} - starting year.
 */
export function getCenturyStartYear(date: DateInput): number {
  const beginOfCentury = getCenturyStart(date);
  return getYear(beginOfCentury);
}

/**
 * Gets starting year of decade for provided date.
 * @param {DateInput} date - target date.
 * @returns {number} - starting year.
 */
export function getDecadeStartYear(date: DateInput): number {
  const beginOfDecade = getDecadeStart(date);
  return getYear(beginOfDecade);
}

/**
 * Gets first day of week for provided date and calendar type.
 * @param {Date} date - target date.
 * @param {string} firstDayOfWeek - first day of week name.
 * @param {string} calendarType - type of calendar (ISO 8601, US, Arabic or Hebrew).
 * @returns {Date} - start day of a week in date form.
 */
export function getWeekStartDate(date: Date, firstDayOfWeek: DaysOfWeek, calendarType = CalendarTypes.ISO_8601): Date {
  const year = getYear(date);
  const monthIndex = getMonthIndex(date);
  const day = date.getDate() - getDayOfWeekIndex(date, firstDayOfWeek, calendarType);
  return new Date(year, monthIndex, day);
}

/**
 * Gets week number for provided date and calendar type.
 * @param {Date} date - target date.
 * @param {string} firstDayOfWeek - first day of week name.
 * @param {string} calendarType - type of calendar (ISO 8601, US, Arabic or Hebrew).
 * @returns {number} - week number.
 */
export function getWeekNumber(date: Date, firstDayOfWeek: DaysOfWeek, calendarType = CalendarTypes.ISO_8601): number {
  const calendarTypeForWeekNumber = calendarType === CalendarTypes.US ? calendarType : CalendarTypes.ISO_8601;
  // In ISO 8601, Arabic and Hebrew week 1 is the one with January 4, in US calendar - with January 1.
  const firstDayOfFirstWeek = CalendarTypes.ISO_8601 ? 4 : 1;
  const dateTimestamp = +date;
  const beginOfWeek = +getWeekStartDate(date, firstDayOfWeek, calendarTypeForWeekNumber);
  let year = getYear(date) + 1;
  let dayInWeekOne: Date;
  let beginOfFirstWeek: number;

  // Look for the first week that does not come after a given date.
  do {
    dayInWeekOne = new Date(year, 0, firstDayOfFirstWeek);
    beginOfFirstWeek = +getWeekStartDate(dayInWeekOne, firstDayOfWeek, calendarTypeForWeekNumber);
    year -= 1;
  } while (dateTimestamp - beginOfFirstWeek < 0);

  const dayInMilliseconds = 8.64e7;
  return Math.round((beginOfWeek - beginOfFirstWeek) / (dayInMilliseconds * 7)) + 1;
}

/**
 * Gets normalized starting date (to its beginning) for provided dates range type.
 * @param {string} rangeType - type of dates range (century, decade, year, month, day).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export function getRangeStart(rangeType: PeriodTypes, date: Date): Date {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getCenturyStart(date);
    case PeriodTypes.DECADE:
      return getDecadeStart(date);
    case PeriodTypes.YEAR:
      return getYearStart(date);
    case PeriodTypes.MONTH:
      return getMonthStart(date);
    case PeriodTypes.DAY:
      return getDayStart(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Gets normalized starting date (to its beginning) for previous date relative to provided in context of dates range type.
 * @param {string} rangeType - type of dates range (century, decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export function getPreviousRangeStart(rangeType: PeriodTypes, date: Date): Date {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getPreviousCenturyStart(date);
    case PeriodTypes.DECADE:
      return getPreviousDecadeStart(date);
    case PeriodTypes.YEAR:
      return getPreviousYearStart(date);
    case PeriodTypes.MONTH:
      return getPreviousMonthStart(date);
    default: throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Gets normalized starting date (to its beginning) for next date relative to provided in context of dates range type.
 * @param {string} rangeType - type of dates range (century, decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export function getNextRangeStart(rangeType: PeriodTypes, date: Date): Date {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getNextCenturyStart(date);
    case PeriodTypes.DECADE:
      return getNextDecadeStart(date);
    case PeriodTypes.YEAR:
      return getNextYearStart(date);
    case PeriodTypes.MONTH:
      return getNextMonthStart(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Gets normalized starting date (to its beginning) for previous date relative to provided in context of dates range type.
 * which is the upper one for current.
 * @param {string} rangeType - type of current dates range (decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export const getPreviousUpperRangeStart = (rangeType: PeriodTypes, date: Date): Date => {
  switch (rangeType) {
    case PeriodTypes.DECADE:
      return getPreviousDecadeStart(date, -100);
    case PeriodTypes.YEAR:
      return getPreviousYearStart(date, -10);
    case PeriodTypes.MONTH:
      return getPreviousMonthStart(date, -12);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Gets normalized starting date (to its beginning) for next date relative to provided in context of
 * dates range type which is the upper one for currently used.
 * @param {string} rangeType - type of current dates range (decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export const getNextUpperRangeStart = (rangeType: PeriodTypes, date: Date): Date => {
  switch (rangeType) {
    case PeriodTypes.DECADE:
      return getNextDecadeStart(date, 100);
    case PeriodTypes.YEAR:
      return getNextYearStart(date, 10);
    case PeriodTypes.MONTH:
      return getNextMonthStart(date, 12);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Gets normalized end date (to its beginning) for provided dates range type.
 * @param {string} rangeType - type of dates range (century, decade, year, month, day).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export function getRangeEnd(rangeType: PeriodTypes, date: Date): Date {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getCenturyEnd(date);
    case PeriodTypes.DECADE:
      return getDecadeEnd(date);
    case PeriodTypes.YEAR:
      return getYearEnd(date);
    case PeriodTypes.MONTH:
      return getMonthEnd(date);
    case PeriodTypes.DAY:
      return getDayEnd(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Gets normalized end date (to its beginning) for previous date relative to provided in context of dates range type.
 * @param {string} rangeType - type of dates range (century, decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export function getPreviousRangeEnd(rangeType: PeriodTypes, date: Date): Date {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getPreviousCenturyEnd(date);
    case PeriodTypes.DECADE:
      return getPreviousDecadeEnd(date);
    case PeriodTypes.YEAR:
      return getPreviousYearEnd(date);
    case PeriodTypes.MONTH:
      return getPreviousMonthEnd(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Gets normalized end date (to its beginning) for previous date relative to provided in context of
 * dates range type which is the upper one for currently used.
 * @param {string} rangeType - type of current dates range (decade, year, month).
 * @param {Date} date - target date.
 * @returns {Date|Error} - normalized date or error in case of wrong range type.
 */
export const getPreviousUpperRangeEnd = (rangeType: PeriodTypes, date: Date): Date => {
  switch (rangeType) {
    case PeriodTypes.DECADE:
      return getPreviousDecadeEnd(date, -100);
    case PeriodTypes.YEAR:
      return getPreviousYearEnd(date, -10);
    case PeriodTypes.MONTH:
      return getPreviousMonthEnd(date, -12);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
};

/**
 * Gets array with the beginning and the end of a given range type for provided date.
 * @param {string} rangeType - type of current dates range (century, decade, year, month, day).
 * @param {Date} date - target date.
 * @returns {Date[]|Error} - array with start and end dates or error in case of wrong range type.
 */
export function getRange(rangeType: PeriodTypes, date: Date): Date[] {
  switch (rangeType) {
    case PeriodTypes.CENTURY:
      return getCenturyRange(date);
    case PeriodTypes.DECADE:
      return getDecadeRange(date);
    case PeriodTypes.YEAR:
      return getYearRange(date);
    case PeriodTypes.MONTH:
      return getMonthRange(date);
    case PeriodTypes.DAY:
      return getDayRange(date);
    default:
      throw new Error(`Invalid rangeType: ${rangeType}`);
  }
}

/**
 * Creates range array out of two dates in context of provided range type.
 * @param {string} rangeType - type of current dates range (decade, year, month, day).
 * @param {Date} date1 - first target date.
 * @param {Date} date2 - second target date.
 * @returns {Date[]} - range array containing its start and end dates.
 */
export function getRangeValue(rangeType: PeriodTypes, date1: Date, date2: Date): Date[] {
  const [rawNextDate1, rawNextDate2] = [date1, date2].sort((a, b) => +a - +b);
  const rangeStart = getRangeStart(rangeType, rawNextDate1);
  const rangeEnd = getRangeEnd(rangeType, rawNextDate2);
  return [rangeStart, rangeEnd];
}

/**
 * Creates label for provided years range.
 * @param {string} locale - currently used locale.
 * @param {function} formatYear - function used to format year value.
 * @param {Date[]} datesRange - years range.
 * @returns {string} - label for years range.
 */
function getYearsRangeLabel(locale: string, formatYear: Formatter, datesRange: Date[]): string {
  return datesRange.map(date => formatYear(locale, date)).join(' – ');
}

/**
 * Creates label for provided date in context of century.
 * @param {string} locale - currently used locale.
 * @param {function} formatYear - function used to format year value.
 * @param {DateInput} date - target date.
 * @returns {string} - label for century.
 */
export function getCenturyLabel(locale: string, formatYear: Formatter, date: DateInput): string {
  return getYearsRangeLabel(locale, formatYear, getCenturyRange(date));
}

/**
 * Creates label for provided date in context of decade.
 * @param {string} locale - currently used locale.
 * @param {function} formatYear - function used to format year value.
 * @param {DateInput} date - target date.
 * @returns {string} - label for decade.
 */
export function getDecadeLabel(locale: string, formatYear: Formatter, date: DateInput): string {
  return getYearsRangeLabel(locale, formatYear, getDecadeRange(date));
}

/**
 * Checks if provided day is weekend.
 * @param {Date} date - target date.
 * @param {string} calendarType - type of calendar (ISO 8601, US, Arabic or Hebrew).
 * @returns {boolean|Error} - result of check or error in case of wrong calendar type.
 */
export function isWeekend(date: Date, calendarType = CalendarTypes.ISO_8601): boolean {
  const weekDay = date.getDay();
  switch (calendarType) {
    case CalendarTypes.ARABIC:
    case CalendarTypes.HEBREW:
      return weekDay === DaysOfWeek.FRIDAY || weekDay === DaysOfWeek.SATURDAY;
    case CalendarTypes.ISO_8601:
    case CalendarTypes.US:
      return weekDay === DaysOfWeek.SATURDAY || weekDay === DaysOfWeek.SUNDAY;
    default:
      throw new Error('Unsupported calendar type.');
  }
}
