import { useRef } from 'react';
import { isEqual } from 'lodash';

export default (computeValue, deps, equalityFn = isEqual) => {
  const memoizedComputedValueRef = useRef();
  const memoizedDepsRef = useRef();

  if (equalityFn(deps, memoizedDepsRef.current))
    return memoizedComputedValueRef.current;
    
  memoizedDepsRef.current = deps;

  return memoizedComputedValueRef.current = computeValue();
};