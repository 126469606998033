import { useCallback } from 'react';
import { useIsIE } from 'utils/detections';

export default () => {
  const isIE = useIsIE();

  return useCallback(element => {
    if (!(element instanceof HTMLElement))
      return;

    if (isIE) {
      // To prevent scroll on focus in IE11 non-standard method setActive should be used.
      element.setActive && element.setActive();
    } else {
      element.focus && element.focus({ preventScroll: true });
    }
  }, []);
};
