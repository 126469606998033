exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ActionButtons_btn-checkout-placeholder,.ActionButtons_btn-quote-placeholder{font-size:var(--buttons_Big_FontSize,22px);height:2em}.ActionButtons_btn-quote-placeholder{min-width:5.88em}.ActionButtons_btn-quote-placeholder.ActionButtons_small{height:1.7em;font-size:var(--buttons_Normal_FontSize,1.4em)}.ActionButtons_btn-checkout-placeholder{min-width:9.54em}.ActionButtons_btn-checkout,.ActionButtons_btn-quote{display:block}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/basket/actionButtons/ActionButtons.module.scss"],"names":[],"mappings":"AAGA,6EAEI,2CACA,UAFJ,CAKA,qCACI,gBAFJ,CAII,yDACI,aACA,8CAFR,CAMA,wCACI,gBAHJ,CAMA,qDAEI,aAHJ,CAAA","file":"ActionButtons.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n@import \"css/_theme.scss\";\n\n.btn-quote-placeholder,\n.btn-checkout-placeholder {\n    font-size: $buttons_Big_FontSize;\n    height: 2em;\n}\n\n.btn-quote-placeholder {\n    min-width: 5.88em;\n\n    &.small {\n        height: 1.7em;\n        font-size: $buttons_Normal_FontSize;\n    }\n}\n\n.btn-checkout-placeholder {\n    min-width: 9.54em;\n}\n\n.btn-checkout,\n.btn-quote {\n    display: block;\n}"],"sourceRoot":""}]);

// exports
exports.locals = {
	"btn-checkout-placeholder": "ActionButtons_btn-checkout-placeholder",
	"btnCheckoutPlaceholder": "ActionButtons_btn-checkout-placeholder",
	"btn-quote-placeholder": "ActionButtons_btn-quote-placeholder",
	"btnQuotePlaceholder": "ActionButtons_btn-quote-placeholder",
	"small": "ActionButtons_small",
	"btn-checkout": "ActionButtons_btn-checkout",
	"btnCheckout": "ActionButtons_btn-checkout",
	"btn-quote": "ActionButtons_btn-quote",
	"btnQuote": "ActionButtons_btn-quote"
};