import React from 'react';
import Column from './ContentBlockColumn';
import { VdColumnContainer } from 'components/visualDesigner';

export default function createColumn(column, index) {
  return (
    <VdColumnContainer key={column.id} index={index} column={column}>
      <Column {...column} />
    </VdColumnContainer>
  );
}