import styles from './PLP.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import ProductContext from './ProductContext';
import { ProductStock } from 'components/primitives/product';

const Stock = ({ stockLevels, inventory }) => {
  if (inventory === null || stockLevels == null)
    return null;

  return (
    <span className={styles.stockIndication}>
      <ProductStock inventory={inventory} stockLevels={stockLevels} />
    </span>
  );
};

Stock.propTypes = {
  inventory: PropTypes.number,
  stockLevels: PropTypes.shape({
    outOfStock: PropTypes.number.isRequired,
    lowStock: PropTypes.number.isRequired,
    maxStockNumber: PropTypes.number,
  }),
};

export default connectToContext(
  [ProductContext],
  ({ stockLevels, inventory }) => ({ stockLevels, inventory }),
)(
  React.memo(Stock),
);