import styles from './ActionButtons.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Placeholder } from 'components/primitives/placeholders';
import { SanaLinkButton } from 'components/primitives/links';
import { routesBuilder } from 'routes';
import { useUpdateContext } from '../updateContext';

const CheckoutButton = ({ disabled, className = '' }) => {
  const { writeOnlySubmit } = useUpdateContext();

  const lines = useSelector(x => x.basket.model?.productLines?.list);

  const handleCheckout = e => {

    const linesMissingSerialNumbers =
      lines.filter(x => {
        const requiresSerialNo = x.product.requiresSerialNo;
        const serialNoMissing = requiresSerialNo && x.serialNo && !x.serialNo.length;
        return serialNoMissing || (requiresSerialNo && !x.serialNo)
      });

    if (linesMissingSerialNumbers.length) {

      for (const e of document.getElementsByClassName('serialNo')) {
        if (e.value === '') {
          e.setAttribute('style', 'background-color: #fcf5f5;border-color: #ce8d9b;color: #cb2245;');
        } else {
          e.setAttribute('style', '');
        }
      }

      return false;
    }
    writeOnlySubmit(lines);
  };

  return (
    <SanaLinkButton
      textKey="ProceedToCheckout"
      to={routesBuilder.forCheckout}
      onClick={handleCheckout}
      disabled={disabled}
      className={`${btnStyles.btnBig} ${btnStyles.btnAction} ${styles.btnCheckout} ${className}`}
      placeholder={<Placeholder className={styles.btnCheckoutPlaceholder} />}
    />
  );
};

CheckoutButton.propTypes = {
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

export default React.memo(CheckoutButton);