exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ReturnOrders_btn-create{margin-top:10px}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/stdin","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/profile/documents/ReturnOrders/ReturnOrders.module.scss"],"names":[],"mappings":"AAIA,yBACI,eCWJ,CAAA","file":"ReturnOrders.module.scss","sourcesContent":["/// <reference path=\"'../../../../../css/_theme.scss\" />\n/// <reference path=\"'../../../../../css/_mixins.scss\" />\n@import 'css/_mixins.scss';\n\n.btn-create {\n    margin-top: 10px;\n}\n","@use \"sass:string\";\n\n$isIE: '(-ms-high-contrast: active), (-ms-high-contrast: none)';\n$isFirefox: '(min--moz-device-pixel-ratio: 0)';\n$isSafari: 'not all and (min-resolution:.001dpcm)';\n$isTouchDevice: '(pointer: coarse)';\n$isTouchScreen: '(pointer: fine) and (any-pointer: coarse) and (any-pointer: fine)';\n$hasMultiplePointers: '(any-pointer: coarse) and (any-pointer: fine)';\n\n:export {\n    isIE: string.unquote($isIE);\n    isFirefox: string.unquote($isFirefox);\n    isSafari: string.unquote($isSafari);\n    isTouchDevice: string.unquote($isTouchDevice);\n    isTouchScreen: string.unquote($isTouchScreen);\n    hasMultiplePointers: string.unquote($hasMultiplePointers);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"isIE": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isIe": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isFirefox": "(min--moz-device-pixel-ratio:0)",
	"isSafari": "not all and (min-resolution:.001dpcm)",
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)",
	"hasMultiplePointers": "(any-pointer:coarse) and (any-pointer:fine)",
	"btn-create": "ReturnOrders_btn-create",
	"btnCreate": "ReturnOrders_btn-create"
};