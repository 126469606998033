import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Search } from '../search';
import { generateKey } from 'utils/helpers';
import { useSelector } from 'react-redux';

const SearchBarBlock = ({ model }) => {
  if (!model)
    return null;

  const isVisualDesigner = useSelector(state => state.visualDesigner.initialized);

  const [searchBarId] = useState(generateKey);
  return <Search initialModel={model} id={searchBarId} restoreQuery={false} designMode={isVisualDesigner} />;
};

SearchBarBlock.propTypes = {
  model: PropTypes.shape({
    placeholder: PropTypes.string,
  }),
};

export default React.memo(SearchBarBlock);