import styles from './MyAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText } from 'components/sanaText';
import { Link, LinkButton } from 'components/primitives/links';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { routesBuilder } from 'routes';

const textsToLoad = ['NewOrder', 'NewOrderAltText'];

const MainBlockItems = ({ canOrderProducts, userName, customerServicePage }) => (

  <div className={`${styles.mainBlock} ${!canOrderProducts ? styles.noOrderProducts : ''} clearfix`}>
    <div className={styles.topActions}>
      {canOrderProducts && (
        <UseSanaTexts options={textsToLoad}>
          {texts => {
            const [newOrderText, newOrderAltText] = texts.map(stripHtmlTagsAndFormatting);
            return (
              <LinkButton
                className={`${btnStyles.btnSmall} ${btnStyles.btnPlus}`}
                to={routesBuilder.forBasket()}
                title={newOrderAltText}
                rel="nofollow"
              >
                {newOrderText}
              </LinkButton>
            );
          }}
        </UseSanaTexts>
      )}
      {!!customerServicePage &&
        <Link to={customerServicePage.to} className={`${linkStyles.arrowed} ${styles.customerServiceLink}`}>
          <SimpleText textKey="CustomerService" />
        </Link>
      }
    </div>
    <div className={styles.mainInfo}>
      <p className={styles.accountName}><SimpleText textKey="Hello" /> {userName}</p>
      <div className={styles.description}><RichText textKey="MyAccount_Description" /></div>
    </div>
  </div>
);

MainBlockItems.propTypes = {
  canOrderProducts: PropTypes.bool.isRequired,
  userName: PropTypes.string,
  customerServicePage: PropTypes.shape({
    to: PropTypes.object,
  }),
};

export default MainBlockItems;