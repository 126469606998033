import React, { useState } from 'react';
import { useLoadEffect } from 'utils/hooks';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { loadTheme } from 'behavior/theme';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { primaryStyleSheets } from './constants';
import { ErrorVersion } from 'behavior/theme';

const StyleProvider = ({ theme, loadTheme, children, insertCss: _insertCss }) => {
  const { loaded, version } = theme;

  function insertCss(styles) {
    if (loaded || version === ErrorVersion) {
      const prepareCss = replaceCss.bind(null, theme.values || {});
      return _insertCss(styles, { prefix: version || 'default', prepareCss, priorities: primaryStyleSheets });
    }
  }

  const [context, updateContext] = useState({
    insertCss,
    version,
  });

  useLoadEffect(() => {
    if (loaded || version === ErrorVersion)
      updateContext({ insertCss, version });
    else
      loadTheme();
  }, [loaded, version]);

  return (
    <StyleContext.Provider value={context}>
      {children}
    </StyleContext.Provider>
  );
};

StyleProvider.propTypes = {
  theme: PropTypes.shape({
    values: PropTypes.object,
    version: PropTypes.string,
    loaded: PropTypes.bool,
  }).isRequired,
  loadTheme: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  insertCss: PropTypes.func.isRequired,
};

export default connect(
  ({ theme }) => ({ theme }),
  { loadTheme },
)(StyleProvider);

const variableRegex = /var\(--(?<name>[^,\s]+),\s*(?<defaultValue>[^)]+)\)/g;

function replaceCss(themeValues, css) {
  return css.replace(variableRegex, (_, name, defaultValue) => themeValues[name] || defaultValue);
}
