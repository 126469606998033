exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "#content.offline{margin:auto 0;padding:30px 0}#content.offline .OfflineMode_logo{display:flex;justify-content:center;padding-bottom:2em}#content.offline .OfflineMode_logo>a{pointer-events:none}#content.offline .OfflineMode_logo img{width:100%}.OfflineMode_content{display:flex;flex-direction:column;align-items:center}.OfflineMode_message{margin:1.92em 0}.OfflineMode_message a{text-transform:lowercase}.OfflineMode_message,.OfflineMode_shop-name{font-size:var(--normalText_BiggerFontSize,14px);line-height:1.9}.OfflineMode_shop-name{color:var(--regularTitle_FontColor,#333);margin:0}.OfflineMode_navs{list-style:none;padding-left:0;margin:0 0 1em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/offlineMode/OfflineMode.module.scss"],"names":[],"mappings":"AAGA,iBACI,cACA,cAFJ,CAII,mCACI,aACA,uBACA,kBAFR,CAIQ,qCACI,mBAFZ,CAKQ,uCACI,UAHZ,CAQA,qBACI,aACA,sBACA,kBALJ,CAQA,qBACI,eALJ,CAOI,uBACI,wBALR,CASA,4CAEI,gDACA,eANJ,CAUA,uBACI,yCACA,QAPJ,CAUA,kBACI,gBACA,eACA,cAPJ,CAAA","file":"OfflineMode.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n:global(#content.offline) {\n    margin: auto 0;\n    padding: 30px 0;\n\n    :local .logo {\n        display: flex;\n        justify-content: center;\n        padding-bottom: 2em;\n\n        > a {\n            pointer-events: none;\n        }\n\n        img {\n            width: 100%;\n        }\n    }\n}\n\n.content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.message {\n    margin: 1.92em 0;\n\n    a {\n        text-transform: lowercase;\n    }\n}\n\n.shop-name,\n.message {\n    font-size: $normalText_BiggerFontSize;\n    line-height: 1.9;\n}\n\n\n.shop-name {\n    color: $regularTitle_FontColor;\n    margin: 0;\n}\n\n.navs {\n    list-style: none;\n    padding-left: 0;\n    margin: 0 0 1em 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"logo": "OfflineMode_logo",
	"content": "OfflineMode_content",
	"message": "OfflineMode_message",
	"shop-name": "OfflineMode_shop-name",
	"shopName": "OfflineMode_shop-name",
	"navs": "OfflineMode_navs"
};