import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';
import { isMaxDate, isMinDate, isValueType } from 'react-date-picker/dist/shared/propTypes';
import { getMinMaxYear } from '../helpers';

const YearInput = ({
  maxDate,
  minDate,
  placeholder = '----',
  valueType,
  ...otherProps
}) => {
  const { maxYear, minYear } = getMinMaxYear(maxDate, minDate);
  const yearStep = valueType === 'century' ? 10 : 1;

  return (
    <Input
      max={maxYear}
      min={minYear}
      name="year"
      placeholder={placeholder}
      step={yearStep}
      {...otherProps}
    />
  );
};

YearInput.propTypes = {
  ariaLabel: PropTypes.string,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  itemRef: PropTypes.func,
  maxDate: isMaxDate,
  minDate: isMinDate,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.number,
  valueType: isValueType,
};

export default YearInput;