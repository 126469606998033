import styles from '../Link.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connectToContext } from 'utils/react';
import { HashRoutingContext } from 'components/hash';
import { useSetFocusWithoutScrolling } from 'utils/hooks';

const SkipLink = ({ hash, title, navigateTo, onClick, omitScroll, ...attributes }) => {
  const setFocusWithoutScrolling = useSetFocusWithoutScrolling();
  const handleClick = e => {
    e.preventDefault();
    onClick && onClick(e);
    !omitScroll && navigateTo(hash);
    e.currentTarget.blur();

    const target = document.getElementById(hash.substring(1));

    if (!target)
      return;
    // Focus should be set on the hash element after small timeout,
    // otherwise it will be set on body element
    setTimeout(setFocusWithoutScrolling.bind(null, target));
  };
  return (
    <a
      href={hash}
      className={`visually-hidden ${styles.skipLink}`}
      onClick={handleClick}
      {...attributes}
    >
      {title}
    </a>
  );
};

SkipLink.propTypes = {
  hash: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  navigateTo: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  omitScroll: PropTypes.bool,
};

export default connectToContext(
  [HashRoutingContext],
  ({ navigateTo }) => ({ navigateTo }),
)(React.memo(SkipLink));