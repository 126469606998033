import styles from 'components/objects/product/Details.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Col, Row } from 'components/primitives/grid';
import { SimpleText, RichText } from 'components/sanaText';
import {
  ProductPrice,
  PricePer,
  ProductStock,
  OfflinePriceWarning,
  useCanViewUom,
} from 'components/primitives/product';
import { useProductContext } from './hooks';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { abilitiesPropType } from 'behavior/user';
import { withAbilities } from 'components/objects/user';
import QuantityBox from './QuantityBox';
import OrderBoxForm from './OrderBoxForm';
import { SanaButton } from 'components/primitives/buttons';
import { VariantDropdowns } from './variantDropdowns';
import { VolumePricesButton } from './volumePrices';
import { showMatrix } from './showMatrix';
import { Placeholder } from 'components/primitives/placeholders';
import { usePrintMode } from 'utils/hooks';
import ConfigureButton from '../productConfigurator/ConfigureButton';
import { BomComponents } from './bomComponents';
import { VariantsMatrixPopup } from './variantsMatrix';
import { AddToWishlist } from './wishlist';
import { productRemovedFromWishList } from 'behavior/wishList/actions';

const OrderBox = ({ matrixPreset, abilities: [orderProductsAbility], wishListEnabled }) => {
  const { product, calculatedInfo, pricesInclTax } = useProductContext();
  const isPrintMode = usePrintMode();
  const canViewUom = useCanViewUom();

  if (!product)
    return null;

  const { price, listPrice, inventory, isOrderable } = calculatedInfo;

  const serialNoRef = useRef();
  const [needSerialNo, setNeedSerialNo] = useState(false);
  const placeholder = <Placeholder className="placeholder" />;

  const {
    stockLevels,
    hasVariants,
    isOrderable: productOrderable,
    productConfiguratorInfo,
    hasVolumePrices,
  } = product;

  const uomId = product.uom && product.uom.id;
  const canOrderProducts = orderProductsAbility === AbilityState.Available;

  const [serialNo, setSerialNo] = useState('');

  const pricePlaceholder = <Placeholder className={styles.pricePlaceholder} />;
  const taxSuffixPlaceholder = <Placeholder className={styles.taxSuffixPlaceholder} />;
  const availabilityTextPlaceholder = <Placeholder className={styles.availabilityTextPlaceholder} />;
  const btnPlaceholder = <Placeholder className={styles.btnPlaceholder} />;
  const priceRowPlaceholder = <Placeholder className={styles.priceRowPlaceholder} />;
  const availabilityPlaceholder = <Placeholder className={styles.availabilityPlaceholder} />;

  if (matrixPreset && product.hasVariants) {
    const matrixSupported = product.variantComponentGroups?.length <= 2;
    if (isPrintMode && matrixSupported)
      return null;

    const calculationInfoIsLoaded = product.variants.some(v => v.isOrderable !== undefined);

    if (matrixSupported && !calculationInfoIsLoaded && productOrderable)
      return btnPlaceholder;

    if (productOrderable && showMatrix(product)) {
      const showBomComponents = !!product.bomComponents?.length;
      return (
        <>
          <VariantsMatrixPopup />
          {showBomComponents && <BomComponents />}
          {hasVolumePrices && <VolumePricesButton showCompleteList canOrderProducts={canOrderProducts} />}
        </>
      );
    }
  }

  const shouldShowOrderBox = price !== null
    || (inventory !== null && stockLevels != null)
    || (productOrderable && hasVariants)
    || (canOrderProducts && isOrderable && productOrderable)
    || (canOrderProducts && isOrderable === false);

  if (!shouldShowOrderBox)
    return null;

  const isProductOrderable = isOrderable && productOrderable;
  const showAddToWishList = wishListEnabled && !(productConfiguratorInfo && productConfiguratorInfo.isProductConfigurable);

  const handleSerialNoChange = e => {
  };

  const handleAddToBasketClicked = e => {
    if (serialNoRef.current) {
      var theSerialNo = serialNoRef.current.value;
      product.serialNo = theSerialNo;

      if (theSerialNo.trim() === '') {
        setNeedSerialNo(true);
        e.preventDefault();
        return;
      }

      setNeedSerialNo(false);
      serialNoRef.current.value = '';
    }
  };

  return (
    <>
      {!isPrintMode && <BomComponents />}
      <div className={styles.orderBox}>
        {price !== null && (
          typeof price !== 'undefined'
            ? (
              <Row className={styles.row} crossAxisAlign="center">
                <Col xs={3} lg={2} className={styles.ellipsis} id="price_Label">
                  <SimpleText textKey="Price" placeholder={pricePlaceholder} />
                </Col>
                <Col xs={9} lg={10} className={styles.field}>
                  <Row className={styles.prices}>
                    <ProductPrice salesPrice={price} basePrice={listPrice} />
                    <Col xs={12} sm="auto" className={styles.afterPrice}>
                      {canViewUom && uomId && <PricePer uomId={uomId} uoms={product.uoms} />}
                      <div>
                        {pricesInclTax != null && (
                          <SimpleText
                            textKey={pricesInclTax ? 'InclTaxSuffix' : 'ExclTaxSuffix'}
                            placeholder={taxSuffixPlaceholder}
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  {!isPrintMode && hasVolumePrices && <VolumePricesButton canOrderProducts={canOrderProducts} />}
                </Col>
                {!isPrintMode && (
                  <Col>
                    <OfflinePriceWarning />
                  </Col>
                )}
              </Row>
            )
            : priceRowPlaceholder
        )}
        {inventory !== null && stockLevels != null && (
          <>
            {
              typeof inventory === 'number'
                ? (
                  <Row className={styles.row} crossAxisAlign="center">
                    <Col xs={3} lg={2} className={styles.ellipsis} id="availability_Label">
                      <SimpleText textKey="Availability" placeholder={availabilityTextPlaceholder} />
                    </Col>
                    <Col xs={9} lg={10} aria-labelledby="availability_Label">
                      <ProductStock inventory={inventory} stockLevels={stockLevels} />
                    </Col>
                  </Row>
                )
                : availabilityPlaceholder
            }
          </>
        )}
        {productOrderable && hasVariants && <VariantDropdowns />}
        {!isPrintMode && canOrderProducts && (
          <OrderBoxForm>
            {product.requiresSerialNo &&
              <div>
                <SimpleText textKey="BasketLine_SerialNo" />
                <input type="text" onChange={handleSerialNoChange} ref={serialNoRef} />
              </div>}
            {needSerialNo &&
              <div className={`${styles.validationError}`}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>
                <SimpleText textKey="SerialNoRequired" placeholder={placeholder} />
              </div>}
            {false && <QuantityBox disabled={!isProductOrderable} />}
            {isProductOrderable &&
              <Row className={styles.row} crossAxisAlign="center">
                <Col xs={12} sm={{ size: 'auto', offset: 3 }} md={{ offset: 0 }} className={styles.limitedWidth}>
                  {productConfiguratorInfo.isProductConfigurable
                    ? <ConfigureButton />
                    : (
                      <SanaButton
                        textKey="AddToBasket"
                        type="submit"
                        className={`${btnStyles.btnAction} ${btnStyles.btnBig} ${styles.addToBasketBtn}`}
                        placeholder={btnPlaceholder}
                        onClick={handleAddToBasketClicked}
                      />
                    )
                  }
                </Col>
                <Col xs={12} className={styles.gutter} />
                <Col xs={12} sm="auto" className={styles.limitedWidth}>
                {showAddToWishList &&
                  <div className={styles.afterBtnLinks}>
                    <AddToWishlist />
                  </div>
                }
                </Col>
              </Row>
            }
          </OrderBoxForm>
        )}
        {canOrderProducts && isOrderable === false && (
          <div className={styles.cannotOrder}>
            <RichText textKey={hasVariants ? 'ProductVariantCannotBeOrderedAtThisTime' : 'ProductCannotBeOrderedAtThisTime'} />
          </div>
        )}
      </div>
    </>
  );
};

OrderBox.propTypes = {
  abilities: abilitiesPropType,
  matrixPreset: PropTypes.bool,
  wishListEnabled: PropTypes.bool,
};

const mapStateToProps = ({
  settings: {
    wishListEnabled,
  },
}) => ({
  wishListEnabled,
});

export default withAbilities(
  connect(mapStateToProps)(OrderBox),
  [AbilityTo.OrderProducts],
);
