import React from 'react';
import DateInput from 'react-date-picker/dist/DateInput';
import DayInput from './inputs/DayInput';
import MonthInput from './inputs/MonthInput';
import YearInput from './inputs/YearInput';
import MonthSelect from 'react-date-picker/dist/DateInput/MonthSelect';
import { isValidDate } from 'utils/helpers';

const allViews = ['century', 'decade', 'year', 'month'];

export default class ExtendedDateInput extends DateInput {
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value && !isValidDate(nextProps.value))
      return { hasInvalidDate: true };

    if (!nextProps.value && prevState.hasInvalidDate)
      return { day: null, month: null, year: null, hasInvalidDate: false };

    let prevStateCore = prevState;
    if (nextProps.value && prevState.value && prevState.hasInvalidDate)
      prevStateCore = { ...prevStateCore, value: null };

    const nextState = DateInput.getDerivedStateFromProps(nextProps, prevStateCore);

    if (prevState.hasInvalidDate)
      nextState.hasInvalidDate = false;

    return nextState;
  }

  get formatDate() {
    const { maxDetail } = this.props;
    const options = { year: 'numeric' };

    const level = allViews.indexOf(maxDetail);
    if (level >= 2)
      options.month = 'numeric';
    if (level >= 3)
      options.day = 'numeric';

    return getFormatter(options);
  }

  get placeholder() {
    const { format, locale } = this.props;

    if (format)
      return format;

    const year = 2017;
    const monthIndex = 11;
    const day = 11;

    const date = new Date(year, monthIndex, day);

    const placeholder = this.formatDate(locale, date)
      .replace(this.formatNumber(locale, year), 'y')
      .replace(this.formatNumber(locale, monthIndex + 1), 'M')
      .replace(this.formatNumber(locale, day), 'd');

    // Fix to support IE which adds unicode symbols
    if (!placeholder.includes('\u200E'))
      return placeholder;

    return placeholder.replace(/\u200E/g, '');
  }

  componentWillUnmount = () => {
    clearTimeout(this.onChangeTimeout);
  };

  onKeyDown = event => {
    switch (event.key) {
      case 'ArrowLeft':
      case 'ArrowRight':
      case 'Left':
      case 'Right':
      case this.divider:
        if (event.key !== this.divider && event.shiftKey)
          return;

        event.preventDefault();

        const { target: input } = event;
        const isLeftArrowKey = event.key === 'ArrowLeft' || event.key === 'Left';
        const property = isLeftArrowKey ? 'previousElementSibling' : 'nextElementSibling';
        const nextInput = findInput(input, property);
        focus(nextInput);
        return;
      case ' ':
      case 'Spacebar':
        event.preventDefault();
        return;
      default:
        return;
    }
  };

  onKeyUp = event => {
    const { key, target: input } = event;

    const isNumberKey = !isNaN(parseInt(key !== 'Unidentified' ? key : input.value.slice(-1), 10));
    if (!isNumberKey)
      return;

    const maxLength = +input.getAttribute('maxLength');
    if (input.value.length === maxLength) {
      const property = 'nextElementSibling';
      const nextInput = findInput(input, property);
      focus(nextInput);
    }
  };

  onChange = (event, name, value) => {
    if (event) {
      name = event.target.name;
      value = event.target.value;
    }

    if (name == null)
      return;

    this.setState({ [name]: value && !isNaN(value) ? parseInt(value, 10) : null });

    clearTimeout(this.onChangeTimeout);
    this.onChangeTimeout = setTimeout(this.onChangeExternal, 50);
  };

  onChangeExternal = () => {
    const { onChange } = this.props;

    if (!onChange)
      return;

    const formElements = [this.dayInput, this.monthInput, this.yearInput].filter(Boolean);
    const values = formElements.reduce((result, formElement) => {
      result[formElement.name] = formElement.value;
      return result;
    }, {});

    if (formElements.every(formElement => !formElement.value)) {
      onChange(null, false);
    } else if (formElements.every(formElement => formElement.value && formElement.checkValidity())) {
      const year = parseInt(values.year, 10);
      const month = parseInt(values.month || 1, 10);
      const day = parseInt(values.day || 1, 10);

      const proposedValue = new Date(year, month - 1, day);
      const processedValue = this.getProcessedValue(proposedValue);

      onChange(processedValue, false);
    } else {
      onChange(new Date(NaN), false);
    }
  };

  // eslint-disable-next-line react/no-multi-comp
  renderDay = (currentMatch, index) => {
    const {
      autoFocus,
      dayAriaLabel,
      dayPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 2)
      throw new Error(`Unsupported token: ${currentMatch}`);

    return (
      <DayInput
        key="day"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={dayAriaLabel}
        autoFocus={index === 0 && autoFocus}
        month={month}
        placeholder={dayPlaceholder}
        value={day}
        year={year}
        allowEmpty={!month && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderMonth = (currentMatch, index) => {
    const {
      autoFocus,
      locale,
      monthAriaLabel,
      monthPlaceholder,
      id,
    } = this.props;
    const { day, month, year } = this.state;

    if (currentMatch && currentMatch.length > 4)
      throw new Error(`Unsupported token: ${currentMatch}`);

    if (currentMatch.length > 2) {
      return (
        <MonthSelect
          key="month"
          id={index === 0 ? id : null}
          {...this.commonInputProps}
          ariaLabel={monthAriaLabel}
          autoFocus={index === 0 && autoFocus}
          locale={locale}
          placeholder={monthPlaceholder}
          short={currentMatch.length === 3}
          value={month}
          year={year}
        />
      );
    }

    return (
      <MonthInput
        key="month"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={monthAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={monthPlaceholder}
        value={month}
        year={year}
        allowEmpty={!day && !year}
      />
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  renderYear = (_currentMatch, index) => {
    const { autoFocus, yearAriaLabel, yearPlaceholder, id } = this.props;
    const { day, month, year } = this.state;

    return (
      <YearInput
        key="year"
        id={index === 0 ? id : null}
        {...this.commonInputProps}
        ariaLabel={yearAriaLabel}
        autoFocus={index === 0 && autoFocus}
        placeholder={yearPlaceholder}
        value={year}
        valueType={this.valueType}
        allowEmpty={!day && !month}
      />
    );
  };
}

function isValidInput(element) {
  return element.tagName === 'INPUT' && element.dataset.type === 'number';
}

function findInput(element, property) {
  let nextElement = element;
  do {
    nextElement = nextElement[property];
  } while (nextElement && !isValidInput(nextElement));
  return nextElement;
}

function focus(element) {
  if (element) {
    element.focus();
  }
}

function getFormatter(options) {
  return (locale, date) => new Intl.DateTimeFormat(locale, options).format(date);
}