import { useSelector, useDispatch } from 'react-redux';
import { showActionForbiddenInPreviewToast } from 'behavior/preview';

export function useIsPreview() {
  return useSelector(({ routing }) => routing.routeData?.params?.previewToken != null);
}

export function useHandlerLockerInPreview(action) {
  const dispatch = useDispatch();
  const isPreview = useIsPreview();

  if (!isPreview)
    return action;

  return () => {
    dispatch(showActionForbiddenInPreviewToast());
    return false;
  };
}