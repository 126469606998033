import styles from './Error.module.scss';
import React from 'react';
import { Container } from 'components/primitives/grid';
import { Link } from 'components/primitives/links';
import { RichText, SimpleText } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { DangerAlert, alertStyles } from 'components/primitives/alerts';

const ErrorBanner = () => {
  const retryLoading = (
    <button className={alertStyles.link} onClick={_ => window.location.reload()}>
      <SimpleText textKey="ErrorPage_PageErrorReload_Reload">Reload</SimpleText>
    </button>
  );

  const homePageLink = (
    <Link to={routesBuilder.forHome}>
      <SimpleText textKey="ErrorPages_HomepageLink">Return</SimpleText>
    </Link>
  );

  return (
    <Container id="errorPage">
      <h1 className={styles.title}>
        <SimpleText textKey="ErrorPage_PageErrorTitle">Requested page cannot be shown</SimpleText>
      </h1>
      <DangerAlert className={styles.block}>
        <div>
          <RichText textKey="ErrorPage_ErrorText">
            Sorry! We are having some trouble with retrieving the information for you. Please try the following:
          </RichText>
        </div>
        <ul className={styles.actionList}>
          <li>
            <SimpleText textKey="ErrorPage_PageErrorReload_Text" formatWith={[retryLoading]} formatAsHtml>
              {'{0} the page'}
            </SimpleText>
          </li>
          <li>
            <SimpleText textKey="ErrorPages_HomepageLinkTemplate" formatWith={[homePageLink]} formatAsHtml>
              {'{0} to homepage.'}
            </SimpleText>
          </li>
        </ul>
      </DangerAlert>
    </Container>
  );
};

export const preload = {
  routes: [routesBuilder.forHome()],
  texts: [
    'ErrorPage_PageErrorTitle',
    'ErrorPage_ErrorText',
    'ErrorPage_PageErrorReload_Reload',
    'ErrorPage_PageErrorReload_Text',
    'ErrorPages_HomepageLink',
    'ErrorPages_HomepageLinkTemplate',
    'ReloadErrorMessageText',
    'ButtonText_Reload',
    'RetryErrorMessageText',
    'ButtonText_Retry',
    'OfflineErrorMessageText',
  ],
};

export default React.memo(ErrorBanner);