import { useMemo } from 'react';
import useComputedValue from './useComputedValue';
import useLoadEffect from './useLoadEffect';
import { loadTexts } from 'behavior/adminTexts';
import { createSelector } from 'reselect';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';

export default function useAdminTexts(keys) {
  const dispatch = useDispatch();

  const selectTexts = useComputedValue(() => makeTextsSelector(keys), keys, shallowEqual);
  const adminTexts = useSelector(selectTexts);

  useLoadEffect(() => {
    const keysToLoad = [];

    for (const text of adminTexts) {
      if (text.value === undefined)
        keysToLoad.push(text.key);
    }

    if (keysToLoad.length)
      dispatch(loadTexts(keysToLoad));
  });

  return useMemo(() => {
    let loaded = true;
    const textValues = [];

    for (const { value } of adminTexts) {
      const isUndefined = value === undefined;
      if (isUndefined)
        loaded = false;

      textValues.push(isUndefined ? null : value);
    }

    return { texts: textValues, loaded };
  }, [adminTexts]);
}

const selectTexts = (adminTexts, keys) => {
  return keys.map(key => {
    if (!key)
      return { key: null, value: null };

    return {
      key,
      value: adminTexts[key],
    };
  });
};

function makeTextsSelector(keys) {
  return createSelector(
    state => state.adminTexts,
    adminTexts => selectTexts(adminTexts, keys),
  );
}
