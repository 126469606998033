import styles from '../MainNav.module.scss';
import React, { useRef, useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'components/primitives/links';
import { VerticalSliding } from 'components/primitives/transitions';
import { ConditionalWrap } from 'components/primitives/conditionalWrap';
import ListItemStateIcon from '../ListItemStateIcon';
import SimpleViewSubItems from './SimpleViewSubItems';
import { shouldRenderNavigation, isRightArrowKeyPressed, isLeftArrowKeyPressed } from '../../helpers';
import { isModifierKeyPressed } from 'utils/helpers';
import { MainNavItemPropTypes } from '../PropTypes';
import { useNavItemEventHandlers } from '../hooks';
import { setFocus } from '../eventHandlers';

const SimpleView = ({
  item: {
    id,
    title,
    cssClass,
    link,
    children: subItems,
  },
  isAccordion,
}) => {
  const linkRef = useRef(null);
  const [isVisible, setVisible] = useState(false);

  let handleClick = null, handleKeyDown = null;

  const shouldRenderSubItems = shouldRenderNavigation(subItems);
  const { navItemEventHandlers, navItemLinkEventHandlers } = useNavItemEventHandlers(shouldRenderSubItems);
  const shouldHandlePointerInteractionOrAria = !isAccordion && shouldRenderSubItems;
  const listItemEventHandlers = useMemo(
    () => isAccordion ? { onClick: navItemEventHandlers.onClick } : navItemEventHandlers,
    [isAccordion, navItemEventHandlers],
  );
  const linkEventHandlers = useMemo(
    () => isAccordion ? { onClick: setFocus, onContextMenu: setFocus } : navItemLinkEventHandlers,
    [isAccordion, navItemLinkEventHandlers],
  );

  const createItem = useCallback(subItem => (
    <SimpleView
      key={subItem.id}
      item={subItem}
      isAccordion={isAccordion}
    />
  ), [isAccordion]);

  if (isAccordion && shouldRenderSubItems) {
    handleKeyDown = e => {
      const { target } = e;
      const isCorrectTarget = target === linkRef.current;
      const isRightArrowKey = isRightArrowKeyPressed(e);

      if (!isCorrectTarget || isModifierKeyPressed(e) || (!isRightArrowKey && !isLeftArrowKeyPressed(e)))
        return;

      setVisible(isRightArrowKey);
    };

    handleClick = ({ target, currentTarget }) => {
      if (target !== currentTarget)
        return;

      setVisible(!isVisible);
      linkRef.current.focus();
    };
  }

  const hypContainerStyles = `${styles.hypContainer} ${shouldRenderSubItems ? styles.hasSubItems : ''}`;

  return (
    <li
      className={`${styles.navItemSub} ${cssClass || ''}`}
      {...listItemEventHandlers}
      data-should-handle-aria={shouldHandlePointerInteractionOrAria ? 'true' : null}
      aria-controls={shouldRenderSubItems ? id : null}
    >
      <div
        // Element should be focusable for proper event handling in Mobile Safari, Legacy MS Edge and IE11 when using laptop with touch screen.
        tabIndex="-1"
        className={hypContainerStyles}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        role="presentation"
      >
        <Link
          {...link}
          className={styles.hypNestedLvl}
          ref={linkRef}
          {...linkEventHandlers}
          aria-haspopup={shouldRenderSubItems ? 'true' : null}
        >
          <span>{title}</span>
        </Link>
        {isAccordion && shouldRenderSubItems && <ListItemStateIcon active={isVisible} className={styles.listItemIcon} />}
      </div>
      {shouldRenderSubItems &&
        <ConditionalWrap
          condition={isAccordion}
          wrapWith={children => (
            <VerticalSliding expanded={isVisible}>
              {children}
            </VerticalSliding>
          )}
        >
          <SimpleViewSubItems
            subItems={subItems}
            id={id}
            createItem={createItem}
            parentItemId={id}
            isAccordion={isAccordion}
            expanded={isAccordion ? isVisible : null}
            title={title}
          />
        </ConditionalWrap>
      }
    </li>
  );
};

SimpleView.propTypes = {
  item: MainNavItemPropTypes,
  isAccordion: PropTypes.bool,
};

export default React.memo(SimpleView);