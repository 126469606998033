exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".NotFound_block ul{padding-left:1.5em}.NotFound_action-list{margin:0}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/notFound/NotFound.module.scss"],"names":[],"mappings":"AAAA,mBACI,kBACJ,CAEA,sBACI,QACJ,CAAA","file":"NotFound.module.scss","sourcesContent":[".block ul {\n    padding-left: 1.5em;\n}\n\n.action-list {\n    margin: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"block": "NotFound_block",
	"action-list": "NotFound_action-list",
	"actionList": "NotFound_action-list"
};