import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ToastProvider as ToastProviderBase } from 'react-toast-notifications';
import Toast from './Toast';
import { onAdd, toasts } from 'behavior/toasts';
import { useIsMobileViewport } from 'utils/hooks';
import { useLayoutShifter } from 'utils/layout';
import { TRANSITION_DURATION } from './constants';
import { useDispatch, useSelector } from 'react-redux';
import { createErrorToast } from 'components/objects/error';
import { closeErrorToast } from 'behavior/errorHandling';

class ExtendedToastProvider extends ToastProviderBase {
  constructor(props) {
    super(props);
    onAdd(this.add);
  }
}

const ToastProvider = ({ children }) => {
  const isMobile = useIsMobileViewport();
  const { bottomFixedElementsHeight } = useLayoutShifter();
  const dispatch = useDispatch();
  const errorToast = useSelector(({ error }) => error.toast);

  useEffect(() => {
    const toastsContainer = document.querySelector('.react-toast-notifications__container');
    if (!toastsContainer)
      return;

    toastsContainer.style.marginBottom = isMobile ? bottomFixedElementsHeight + 'px' : 0;
  }, [isMobile, bottomFixedElementsHeight]);

  useEffect(() => {
    if (!errorToast)
      return;

    const id = `error-${errorToast.type}`;
    toasts.errorDialog(
      createErrorToast(id, errorToast.type, errorToast.data),
      {
        id,
        autoDismiss: false,
        onDismiss: () => dispatch(closeErrorToast(errorToast.type)),
      },
    );
  }, [errorToast]);

  return useMemo(() => (
    <ExtendedToastProvider
      autoDismiss
      autoDismissTimeout={10000}
      transitionDuration={TRANSITION_DURATION}
      components={{ Toast }}
      placement={isMobile ? 'bottom-center' : 'top-right'}
    >
      {children}
    </ExtendedToastProvider>
  ), [isMobile, children]);
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default React.memo(ToastProvider);
