import { EMPTY, merge, of, timer } from 'rxjs';
import {
  mergeMap,
  map,
  takeUntil,
  share,
  switchMapTo,
  first,
  ignoreElements,
  tap,
} from 'rxjs/operators';
import { ofType } from 'redux-observable';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { loadSanaTextsQuery } from './queries';
import { arrayToObject } from 'utils/helpers';
import { APP_INIT, APP_INIT_HYDRATE } from 'behavior/app';
import { SANATEXTS_REQUESTED, sanaTextsLoaded } from './actions';
import { bufferInOneTick } from 'utils/rxjs';

import { VIEWER_CHANGED, LANGUAGE_CHANGED } from 'behavior/events';
import { USER_PROFILE_UPDATED } from 'behavior/user';
import { retryWithToast } from 'behavior/errorHandling';

function textsEpic(action$, state$, { api, logger }) {
  const usedKeys = new Set();

  const getTexts = keys => {
    if (!keys.length)
      return EMPTY;

    const anyTexts = Object.keys(state$.value.sanaTexts).length > 0;

    const fallback = _ => {
      const texts = state$.value.sanaTexts;
      const missingKeys = keys.filter(k => !texts.hasOwnProperty(k));
      if (!missingKeys.length)
        return EMPTY;
      const missingTexts = {};
      missingKeys.forEach(k => missingTexts[k] = null);
      return of(sanaTextsLoaded(missingTexts));
    };

    const errorHandler = anyTexts
      ? retryWithToast(action$, logger, fallback)
      : handleError(ERROR_PRIORITIES.HIGH, 'Sana texts', fallback);

    return api.graphApi(loadSanaTextsQuery, { keys }).pipe(
      map(data => sanaTextsLoaded(arrayToObject(data.sanaTexts, s => s.key, s => s.description))),
      errorHandler,
    );
  };

  const reloadActions$ = action$.pipe(
    ofType(VIEWER_CHANGED, LANGUAGE_CHANGED, USER_PROFILE_UPDATED),
    share(),
  );
  const takeUntilReload = takeUntil(reloadActions$);

  const normal$ = action$.pipe(
    ofType(SANATEXTS_REQUESTED),
    bufferInOneTick(),
    mergeMap(actions => {
      const keys = [];

      for (const action of actions)
        for (const key of action.payload) {
          if (usedKeys.has(key))
            continue;

          keys.push(key);
          usedKeys.add(key);
        }

      return getTexts(keys).pipe(takeUntilReload);
    }),
  );

  const reload$ = reloadActions$.pipe(
    switchMapTo(timer(10).pipe(
      mergeMap(_ => getTexts([...usedKeys.keys()])),
    )),
  );

  const init$ = action$.pipe(
    ofType(APP_INIT, APP_INIT_HYDRATE),
    first(),
    tap(() => Object.keys(state$.value.sanaTexts).forEach(usedKeys.add.bind(usedKeys))),
    ignoreElements(),
  );

  return merge(normal$, reload$, init$);
}

export default textsEpic;
