import { isEqual } from 'lodash';

export function modifyContentElementById(id, content, modifyAction) {
  const { result, processed } = doModifyContentElementById(id, content, modifyAction);
  return processed ? result : content;
}

function doModifyContentElementById(id, content, modifyAction) {
  let processed = false;

  const result = content.map(item => {
    if (processed)
      return item;

    if (item.id === id) {
      processed = true;
      return modifyAction(item);
    }

    if (item.nested) {
      const nestedResult = doModifyContentElementById(id, item.nested, modifyAction);
      if (nestedResult.processed) {
        processed = true;
        return {
          ...item,
          nested: nestedResult.result,
        };
      }
    }
    return item;
  });

  return { result, processed };
}

export function findContentElementById(id, content) {
  function find(array, func) {
    for (let i = 0; i < array.length; i++) {
      const result = func(array[i], i);
      if (result)
        return result;
    }
    return null;
  }

  const result = find(content, (row, rowIndex) => {
    if (row.id === id) {
      return { row, rowIndex };
    }

    return find(row.nested, (column, columnIndex) => {
      if (column.id === id) {
        return { row, rowIndex, column, columnIndex };
      }

      return find(column.nested, (contentBlock, contentBlockIndex) => {
        if (contentBlock.id === id) {
          return { row, rowIndex, column, columnIndex, contentBlock, contentBlockIndex };
        }

        return null;
      });
    });
  });

  return result || {};
}

export function mergePageContentData(oldData, newData) {
  if (oldData) {
    newData = newData.map(rowData => {
      rowData.nested = rowData.nested.map(columnData => {
        columnData.nested = columnData.nested.map(contentBlockData => {
          const oldContentBlockDataModel = findContentElementById(contentBlockData.id, oldData)?.contentBlock?.model;
          if (isEqual(oldContentBlockDataModel, contentBlockData.model))
            contentBlockData.model = oldContentBlockDataModel;

          return contentBlockData;
        });
        return columnData;
      });
      return rowData;
    });
  }
  return newData;
}