import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createUrl } from 'behavior/routing';

export default () => {
  const routing = useSelector(state => state.routing);

  return useMemo(() => {
    const { location, routeData } = routing;

    return { url: createUrl(location), routeData };
  }, [routing]);
};
