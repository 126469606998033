exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, "#content.limited{margin:auto 0}#content.limited .LimitedAccess_container{max-width:682px}#content.limited .LimitedAccess_logo{display:flex;justify-content:center;max-width:none;padding-bottom:1.25em}#content.limited .LimitedAccess_logo>a{pointer-events:none}#content.limited .LimitedAccess_logo img{display:inline-block}.LimitedAccess_title{margin:.25em 0 .13em;line-height:1em}.LimitedAccess_message{margin:1.9em 0;font-size:1.08em;line-height:1.7em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/limitedAccess/LimitedAccess.module.scss"],"names":[],"mappings":"AAGA,iBACI,aAFJ,CAKQ,0CACI,eAHZ,CAMQ,qCACI,aACA,uBACA,eACA,qBAJZ,CAMY,uCACI,mBAJhB,CAOY,yCACI,oBALhB,CAWA,qBACI,qBACA,eARJ,CAWA,uBACI,eACA,iBACA,iBARJ,CAAA","file":"LimitedAccess.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n:global(#content.limited) {\n    margin: auto 0;\n\n    :local {\n        .container {\n            max-width: 682px;\n        }\n\n        .logo {\n            display: flex;\n            justify-content: center;\n            max-width: none;\n            padding-bottom: 1.25em;\n\n            > a {\n                pointer-events: none;\n            }\n\n            img {\n                display: inline-block;\n            }\n        }\n    }\n}\n\n.title {\n    margin: 0.25em 0 0.13em;\n    line-height: 1em;\n}\n\n.message {\n    margin: 1.9em 0;\n    font-size: 1.08em;\n    line-height: 1.7em;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"container": "LimitedAccess_container",
	"logo": "LimitedAccess_logo",
	"title": "LimitedAccess_title",
	"message": "LimitedAccess_message"
};