exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Confirmation_body p{margin:0;padding-top:8px}.Confirmation_confirmation footer{margin-top:20px;text-align:right}.Confirmation_confirmation footer button{margin-top:10px;max-width:100%}.Confirmation_confirmation footer button:last-child{margin-left:16px}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/stdin","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/confirmation/Confirmation.module.scss"],"names":[],"mappings":"AAKI,qBACI,SACA,eCUR,CDNA,kCACI,gBACA,gBCSJ,CDPI,yCACI,gBACA,cCSR,CDPQ,oDACI,gBCSZ,CAAA","file":"Confirmation.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n/// <reference path=\"../../../../css/_mixins.scss\" />\n@import \"css/_mixins.scss\";\n\n.body {\n    p {\n        margin: 0;\n        padding-top: 8px;\n    }\n}\n\n.confirmation footer {\n    margin-top: 20px;\n    text-align: right;\n\n    button {\n        margin-top: 10px;\n        max-width: 100%;\n\n        &:last-child {\n            margin-left: 16px;\n        }\n    }\n}\n","@use \"sass:string\";\n\n$isIE: '(-ms-high-contrast: active), (-ms-high-contrast: none)';\n$isFirefox: '(min--moz-device-pixel-ratio: 0)';\n$isSafari: 'not all and (min-resolution:.001dpcm)';\n$isTouchDevice: '(pointer: coarse)';\n$isTouchScreen: '(pointer: fine) and (any-pointer: coarse) and (any-pointer: fine)';\n$hasMultiplePointers: '(any-pointer: coarse) and (any-pointer: fine)';\n\n:export {\n    isIE: string.unquote($isIE);\n    isFirefox: string.unquote($isFirefox);\n    isSafari: string.unquote($isSafari);\n    isTouchDevice: string.unquote($isTouchDevice);\n    isTouchScreen: string.unquote($isTouchScreen);\n    hasMultiplePointers: string.unquote($hasMultiplePointers);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"isIE": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isIe": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isFirefox": "(min--moz-device-pixel-ratio:0)",
	"isSafari": "not all and (min-resolution:.001dpcm)",
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)",
	"hasMultiplePointers": "(any-pointer:coarse) and (any-pointer:fine)",
	"body": "Confirmation_body",
	"confirmation": "Confirmation_confirmation"
};