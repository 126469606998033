import styles from '../MainNav.module.scss';
import React, { useRef, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { SublistPositionContext } from '../SublistPositionProvider';
import { notificationsSubject } from '../notificationsObservable';
import { shouldRenderNavigation } from '../../helpers';
import { removeListHoverState } from '../eventHandlers';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useIsTouchScreen } from 'utils/detections';
import { useIsMouse } from 'components/detection';

const textsToLoad = ['Aria_MainNavigation_Submenu'];

const SimpleViewSubItems = ({ subItems, id, createItem, parentItemId, title, isAccordion, expanded }) => {
  const subItemsRef = useRef(null);
  const positionContext = useContext(SublistPositionContext);
  const isTouchScreen = useIsTouchScreen();
  const isMouse = useIsMouse();

  useEffect(() => {
    if (isAccordion || !positionContext)
      return;

    const subscription = notificationsSubject.subscribe(({ id, startDirection, startPosition }) => {
      if (id !== parentItemId)
        return;

      const { leftPos, rightPos } = styles;
      const { classList } = subItemsRef.current;
      const { position, direction, posClass } = positionContext.getSublistPositionParams(startDirection, startPosition);

      classList.remove(leftPos, rightPos);
      classList.add(posClass);
      triggerSubItemsPositionCalculation(subItems, direction, position);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [isAccordion, positionContext]);

  return (
    <UseSanaTexts options={textsToLoad} dontWait>
      {([ariaMainNavSubmenuText]) => (
        <ul
          id={id}
          className={styles.subItems}
          ref={subItemsRef}
          // onMouseLeave is not reliable when using touch screen on laptop - it is fired twice for touch position and last known mouse cursor position.
          onMouseLeave={isTouchScreen && isMouse === false ? null : removeListHoverState}
          aria-label={`${title} ${stripHtmlTagsAndFormatting(ariaMainNavSubmenuText)}`}
          aria-hidden={isAccordion ? !expanded : 'true'}
          aria-expanded={isAccordion ? expanded : 'false'}
        >
          {subItems.map(createItem)}
        </ul>
      )}
    </UseSanaTexts>
  );
};

SimpleViewSubItems.propTypes = {
  subItems: PropTypes.array,
  id: PropTypes.string.isRequired,
  createItem: PropTypes.func.isRequired,
  parentItemId: PropTypes.string,
  title: PropTypes.string,
  isAccordion: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default React.memo(SimpleViewSubItems);

export function triggerSubItemsPositionCalculation(subItems, startDirection, startPosition) {
  for (const subItem of subItems) {
    const { id, children } = subItem;
    const areSubItemsPresent = shouldRenderNavigation(children);
    areSubItemsPresent && notificationsSubject.next({ id, startDirection, startPosition });
  }
}