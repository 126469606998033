exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ProductPrice_action-price{font-size:var(--listPrice_FontSize,11px);color:var(--listPrice_FontColor,#b2b2b2);text-decoration:line-through}.ProductPrice_action-price,.ProductPrice_actual-price,.ProductPrice_discount-amount{white-space:nowrap}.ProductPrice_discount-amount,.ProductPrice_discount-percentage{font-weight:700}.ProductPrice_discount-amount{font-size:var(--listPrice_DiscountAmount_FontSize,11px);color:var(--listPrice_DiscountAmount_FontColor,#cb2245)}.ProductPrice_discount-percentage{font-size:var(--listPrice_DiscountPercentage_FontSize,11px);color:var(--listPrice_DiscountPercentage_FontColor,#cb2245)}.ProductPrice_actual-price{font-size:var(--bulkPrice_FontSize,16px);color:var(--bulkPrice_FontColor,#333)}.ProductPrice_actual-price sup{top:-.7em;font-size:50%}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/product/ProductPrice.module.scss"],"names":[],"mappings":"AAGA,2BACI,yCACA,yCACA,4BAFJ,CAKA,oFAGI,kBAFJ,CAKA,gEAEI,eAFJ,CAKA,8BACI,wDACA,uDAFJ,CAKA,kCACI,4DACA,2DAFJ,CAKA,2BACI,yCACA,qCAFJ,CAII,+BACI,UACA,aAFR,CAAA","file":"ProductPrice.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.action-price {\n    font-size: $listPrice_FontSize;\n    color: $listPrice_FontColor;\n    text-decoration: line-through;\n}\n\n.action-price,\n.actual-price,\n.discount-amount {\n    white-space: nowrap;\n}\n\n.discount-amount,\n.discount-percentage {\n    font-weight: bold;\n}\n\n.discount-amount {\n    font-size: $listPrice_DiscountAmount_FontSize;\n    color: $listPrice_DiscountAmount_FontColor;\n}\n\n.discount-percentage {\n    font-size: $listPrice_DiscountPercentage_FontSize;\n    color: $listPrice_DiscountPercentage_FontColor;\n}\n\n.actual-price {\n    font-size: $bulkPrice_FontSize;\n    color: $bulkPrice_FontColor;\n\n    sup {\n        top: -0.7em;\n        font-size: 50%;\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"action-price": "ProductPrice_action-price",
	"actionPrice": "ProductPrice_action-price",
	"actual-price": "ProductPrice_actual-price",
	"actualPrice": "ProductPrice_actual-price",
	"discount-amount": "ProductPrice_discount-amount",
	"discountAmount": "ProductPrice_discount-amount",
	"discount-percentage": "ProductPrice_discount-percentage",
	"discountPercentage": "ProductPrice_discount-percentage"
};