import { useRef } from 'react';
import { shallowEqualArrays } from 'shallow-equal';

const initialCache = [];

export default function (onChange, dependencies, runOnInit = true, comparer = shallowEqualArrays) {
  const cache = useRef(initialCache);

  const initialRun = cache.current === initialCache;
  const shouldCallOnChange = (runOnInit || !initialRun) && !comparer(cache.current, dependencies);

  if (shouldCallOnChange)
    onChange(cache.current);

  if (initialRun || shouldCallOnChange)
    cache.current = dependencies;
}