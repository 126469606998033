import type { DateInput } from '@wojtekmaj/date-utils';
import type { Formatter } from './dates';

/**
 * Gets formatter function which will return language sensitive representation of provided date.
 * @param {object} options - Intl.DateTimeFormat() options.
 * @returns {function} - formatter function.
 */
function getFormatter(options: Intl.DateTimeFormatOptions): Formatter {
  return (locale, date) => date.toLocaleString(locale, options);
}

/**
 * Changes the hour in a Date to ensure right date formatting applied even if DST is messed up.
 * Workaround for bug in WebKit and Firefox with historical dates. For more details, please see:
 * https://bugs.chromium.org/p/chromium/issues/detail?id=750465
 * https://bugzilla.mozilla.org/show_bug.cgi?id=1385643
 * @param {DateInput} date - target date.
 * @returns {Date} - work safe date.
 */
function toSafeHour(date: DateInput): Date {
  const safeDate = new Date(date);
  return new Date(safeDate.setHours(12));
}

/**
 * Gets formatter function which will return language sensitive representation of provided date converted to its safe value.
 * @param {object} options - Intl.DateTimeFormat() options.
 * @returns {function} - formatter function.
 */
function getSafeFormatter(options: Intl.DateTimeFormatOptions): Formatter {
  return (locale, date) => getFormatter(options)(locale, toSafeHour(date));
}

export const formatLongDate = getSafeFormatter({ day: 'numeric', month: 'long', year: 'numeric' });
export const formatMonth = getSafeFormatter({ month: 'long' });
export const formatMonthYear = getSafeFormatter({ month: 'long', year: 'numeric' });
export const formatYear = getSafeFormatter({ year: 'numeric' });
export const formatShortWeekday = getSafeFormatter({ weekday: 'short' });
export const formatWeekday = getSafeFormatter({ weekday: 'long' });
