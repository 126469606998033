import styles from './LayoutShiftProvider.module.scss';
import React, { useRef, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactResizeDetector from 'react-resize-detector';
import { contextInitialValue, LayoutShiftContext, ShiftTypes } from 'utils/layout';

/**
  * @typedef {Object} ComponentProps
  * @property {object} topShiftElRef - ref object with link to element for applying top shift value.
  * @property {object} bottomShiftElRef - ref object with link to element for applying bottom shift value.
  * @property {React.ReactNode} topBlockContent - Content of top shift block element. Anything that can be rendered by React:
  * numbers, strings, another React elements.
  * @property {React.ReactNode} bottomBlockContent - Content of bottom shift block element. Anything that can be rendered by
  * React: numbers, strings, another React elements.
  * @property {React.ReactNode} children - Anything that can be rendered by React: numbers, strings, another React elements.
  */

/**
  * Context provider which provides context with properties and methods for setting application layout
  * shift depending on presence of elements with position fixed (such as cookiebar, sticky header etc.)
  * @param {ComponentProps}
  * @returns {React.ReactNode} - Top shift block, children and bottom shift block wrapped up with the layout shift context provider.
  */

const LayoutShiftProvider = ({
  topShiftElRef,
  bottomShiftElRef,
  topBlockContent,
  bottomBlockContent,
  children,
}) => {
  const topShiftBlockRef = useRef();
  const bottomShiftBlockRef = useRef();
  const prevShiftBlockHeights = useRef();
  const elementsAffectingShiftDataManager = useElementsAffectingShiftDataManager();

  if (!prevShiftBlockHeights.current) {
    prevShiftBlockHeights.current = {
      [ShiftTypes.TOP]: contextInitialValue.topShiftBlockHeight,
      [ShiftTypes.BOTTOM]: contextInitialValue.bottomShiftBlockHeight,
    };
  }

  const updateShiftContext = useCallback((shiftType, height) => {
    const canApplyShift = checkIfShiftCanBeApplied(topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);
    if (!canApplyShift)
      return;

    const shiftData = getShiftData(shiftType, topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);

    setContextValue(prevValues => {
      const shiftValue = height + elementsAffectingShiftDataManager.getShiftHeight(shiftType);
      prevShiftBlockHeights.current[shiftType] = height;
      shiftData.shiftElementStyleObj[shiftData.shiftStyleProp] = `${shiftValue}px`;

      return {
        ...prevValues,
        [shiftData.shiftTypeProp]: shiftValue,
        [shiftData.blockHeightProp]: shiftData.shiftBlockHeight,
        [shiftData.shiftTypePrefix + 'FixedElementsHeight']: shiftData.shiftBlockHeight + elementsAffectingShiftDataManager.getFixedElementsHeight(shiftType),
      };
    });
  }, []);

  const updateElementsAffectingShiftData = useCallback((shiftType, elementName, height, shouldShift = false, isFixed = true) => {
    const canApplyShift = checkIfShiftCanBeApplied(topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);
    if (!canApplyShift)
      return;

    if (height) {
      elementsAffectingShiftDataManager.setData(shiftType, elementName, height, shouldShift, isFixed);
    } else {
      if (elementsAffectingShiftDataManager.hasData(shiftType, elementName)) {
        elementsAffectingShiftDataManager.removeData(shiftType, elementName);
      } else {
        return;
      }
    }

    const shiftData = getShiftData(shiftType, topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef);

    setContextValue(prevValues => {
      const shiftValue = prevValues[shiftData.blockHeightProp] + elementsAffectingShiftDataManager.getShiftHeight(shiftType);
      shiftData.shiftElementStyleObj[shiftData.shiftStyleProp] = `${shiftValue}px`;

      return {
        ...prevValues,
        [shiftData.shiftTypeProp]: shiftValue,
        [shiftData.shiftTypePrefix + 'FixedElementsHeight']: shiftData.shiftBlockHeight + elementsAffectingShiftDataManager.getFixedElementsHeight(shiftType),
      };
    });
  }, []);

  const [contextValue, setContextValue] = useState({ ...contextInitialValue, updateElementsAffectingShiftData });

  const handleTopBlockResize = useCallback((_width, height) => {
    handleResize(ShiftTypes.TOP, Math.ceil(height), prevShiftBlockHeights.current[ShiftTypes.TOP], updateShiftContext);
  }, []);

  const handleBottomBlockResize = useCallback((_width, height) => {
    handleResize(ShiftTypes.BOTTOM, Math.ceil(height), prevShiftBlockHeights.current[ShiftTypes.BOTTOM], updateShiftContext);
  }, []);

  return (
    <LayoutShiftContext.Provider value={contextValue}>
      <div className={styles.topShiftBlock} ref={topShiftBlockRef}>
        <ReactResizeDetector handleHeight onResize={handleTopBlockResize} />
        {topBlockContent}
      </div>
      {children}
      <div className={styles.bottomShiftBlock} ref={bottomShiftBlockRef}>
        <ReactResizeDetector handleHeight onResize={handleBottomBlockResize} />
        {bottomBlockContent}
      </div>
    </LayoutShiftContext.Provider>
  );
};

LayoutShiftProvider.propTypes = {
  topShiftElRef: PropTypes.object,
  bottomShiftElRef: PropTypes.object,
  topBlockContent: PropTypes.node,
  bottomBlockContent: PropTypes.node,
  children: PropTypes.node,
};

export default LayoutShiftProvider;

function useElementsAffectingShiftDataManager() {
  const dataManagerRef = useRef();

  if (dataManagerRef.current)
    return dataManagerRef.current;

  const data = {
    [ShiftTypes.TOP]: {
      elementsData: new Map(),
      shiftHeight: 0,
      fixedElementsHeight: 0,
    },
    [ShiftTypes.BOTTOM]: {
      elementsData: new Map(),
      shiftHeight: 0,
      fixedElementsHeight: 0,
    },
  };
  const calcHeights = shiftType => {
    const shiftTypeData = data[shiftType];
    shiftTypeData.shiftHeight = 0;
    shiftTypeData.fixedElementsHeight = 0;

    for (const [, { height, shouldShift, isFixed }] of shiftTypeData.elementsData) {
      if (shouldShift)
        shiftTypeData.shiftHeight += height;

      if (isFixed)
        shiftTypeData.fixedElementsHeight += height;
    }
  };

  dataManagerRef.current = {
    getShiftHeight(shiftType) {
      return data[shiftType].shiftHeight;
    },
    getFixedElementsHeight(shiftType) {
      return data[shiftType].fixedElementsHeight;
    },
    setData(shiftType, name, height, shouldShift, isFixed) {
      data[shiftType].elementsData.set(name, { height, shouldShift, isFixed });
      calcHeights(shiftType);
    },
    hasData(shiftType, name) {
      return data[shiftType].elementsData.has(name);
    },
    removeData(shiftType, name) {
      data[shiftType].elementsData.delete(name);
      calcHeights(shiftType);
    },
  };

  return dataManagerRef.current;
}

function handleResize(shiftType, value, prevValue, updateShiftContext) {
  if (value === prevValue)
    return;

  updateShiftContext(shiftType, value);
}

function checkIfShiftCanBeApplied(topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef) {
  return !!(topShiftElRef.current && bottomShiftElRef.current && topShiftBlockRef.current && bottomShiftBlockRef.current);
}

function getShiftData(shiftType, topShiftElRef, bottomShiftElRef, topShiftBlockRef, bottomShiftBlockRef) {
  const isTopShift = shiftType === ShiftTypes.TOP;
  const shiftTypePrefix = isTopShift ? 'top' : 'bottom';
  const shiftTypeProp = shiftTypePrefix + 'Shift';
  const shiftElementStyleObj = (isTopShift ? topShiftElRef : bottomShiftElRef).current.style;
  const shiftStyleProp = isTopShift ? 'paddingTop' : 'paddingBottom';
  const blockHeightProp = shiftTypePrefix + 'ShiftBlockHeight';
  const shiftBlockHeight = (isTopShift ? topShiftBlockRef : bottomShiftBlockRef).current.offsetHeight;

  return { shiftTypePrefix, shiftTypeProp, shiftElementStyleObj, shiftStyleProp, blockHeightProp, shiftBlockHeight };
}
