import { of } from 'rxjs';

export default (error, logger) => {
  error && logger.error(error);
  return of({
    statusCode: 500,
    page: {
      index: false,
      component: 'errorBanner',
    },
  });
};