import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

let VdContentBlockWrapper;

const VdContentBlockContainer = ({ children, contentBlock, visualDesignerInitialized, index }) => {
  if (!visualDesignerInitialized)
    return children;

  if (!VdContentBlockWrapper)
    VdContentBlockWrapper = loadable(() => import(/*webpackChunkName:"vd"*/'./contentBlock/VdContentBlockWrapper'));

  return (
    <VdContentBlockWrapper index={index} id={contentBlock.id}>
      {children}
    </VdContentBlockWrapper>
  );
};

VdContentBlockContainer.propTypes = {
  children: PropTypes.node,
  visualDesignerInitialized: PropTypes.bool.isRequired,
  index: PropTypes.number,
  contentBlock: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default connect(({ visualDesigner }) => ({
  visualDesignerInitialized: !!visualDesigner.initialized,
}))(VdContentBlockContainer);