import styles from 'components/objects/product/Details.module.scss';
import React from 'react';
import { SimpleText } from 'components/sanaText';
import { Placeholder } from 'components/primitives/placeholders';
import { useProductContext } from './hooks';
import { useSelector } from 'react-redux';

const ProductTitle = () => {
  const { product } = useProductContext();
  let { id, title } = product || {};
  title = useSelector(state => state.page.header) || title;

  if (!id && !title)
    return null;

  const namePlaceholder = <Placeholder className={styles.namePlaceholder} />;

  return (
    <div className={styles.overview}>
      <h1 className={styles.productTitle}>{title}</h1>
      <div className={styles.productId}>
        <span className={styles.name}>
          <SimpleText textKey="General_Product_Id" placeholder={namePlaceholder} />
        </span>
        <span className={styles.value}>{id}</span>
      </div>
    </div>
  );
};

export default React.memo(ProductTitle);