import { EMPTY } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';
import {
  createQuery,
  cookiebarSection,
  mainSection,
  currencyInfoSection,
  addThisSection,
  navigationSection,
  taxDisplaySection,
  shoppingCartSuggestionsSection,
  searchSection,
  documentsSection,
  productComparisonSpecificationsSection,
  productListPresetSection,
  quickOrderSection,
  checkoutSection,
} from './queries';
import { APP_INIT } from 'behavior/app';
import { VIEWER_CHANGED } from 'behavior/events';
import { settingsLoaded } from './actions';
import { storage as cookiebarStorage } from 'behavior/cookiebar';

class SectionDescriptor {
  /**
   * @param {object} subQuery part or the setting loading query.
   * @param {Function} [shouldReload] checks whether section should be reloaded. By default - no.
   * @param {Function} [shouldLoad] checks whether section should be loaded. By default - yes.
   */
  constructor(subQuery, shouldReload, shouldLoad) {
    this.subQuery = subQuery;
    this.shouldReload = shouldReload;
    this.shouldLoad = shouldLoad;
  }
}

const sections = [
  new SectionDescriptor(mainSection),
  new SectionDescriptor(cookiebarSection,
    ({ cookiebar }) => cookiebarStorage.shouldBeShown() && (!cookiebar || cookiebar.isViewerSpecific),
    () => typeof window === 'undefined' || cookiebarStorage.shouldBeShown(),
  ),
  new SectionDescriptor(currencyInfoSection, _ => true),
  new SectionDescriptor(navigationSection),
  new SectionDescriptor(addThisSection),
  new SectionDescriptor(taxDisplaySection, _ => true),
  new SectionDescriptor(shoppingCartSuggestionsSection),
  new SectionDescriptor(searchSection),
  new SectionDescriptor(documentsSection, _ => true),
  new SectionDescriptor(productComparisonSpecificationsSection),
  new SectionDescriptor(productListPresetSection, _ => true),
  new SectionDescriptor(quickOrderSection, _ => true),
  new SectionDescriptor(checkoutSection, _ => true),
];

const settingsEpic = (action$, state$, { api }) => {
  return action$.ofType(APP_INIT, VIEWER_CHANGED).pipe(
    switchMap(_ => {
      const query = getQuery(state$);
      if (!query)
        return EMPTY;
      return api.graphApi(query).pipe(
        map(({ settings }) => settingsLoaded(settings)),
      );
    }),
  );
};

export default settingsEpic;

function getQuery(state$) {
  const settings = state$.value.settings;
  const filtered = settings.loaded
    ? sections.filter(d => d.shouldReload != null && d.shouldReload(settings))
    : sections.filter(d => d.shouldLoad == null || d.shouldLoad());

  return filtered.length ? createQuery(filtered.map(d => d.subQuery)) : null;
}
