exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Icons_icon{max-width:100%;max-height:100%;font-size:4em;text-indent:100%;white-space:nowrap;overflow:hidden;display:inline-block}.Icons_default-icon{vertical-align:-.031em}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/stdin","/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/primitives/icons/Icons.module.scss"],"names":[],"mappings":"AAIA,YACI,eACA,gBACA,cAEA,iBACA,mBACA,gBACA,oBCWJ,CDRA,oBACI,sBCWJ,CAAA","file":"Icons.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n/// <reference path=\"../../../css/_mixins.scss\" />\n@import '../../../css/_mixins.scss';\n\n.icon {\n    max-width: 100%;\n    max-height: 100%;\n    font-size: 4em;\n    /* Hide alt text for icons with invalid src by kellum method. */\n    text-indent: 100%;\n    white-space: nowrap;\n    overflow: hidden;\n    display: inline-block; // Firefox stretch img size by alt text fix.\n}\n\n.default-icon {\n    vertical-align: -0.031em;\n}\n","@use \"sass:string\";\n\n$isIE: '(-ms-high-contrast: active), (-ms-high-contrast: none)';\n$isFirefox: '(min--moz-device-pixel-ratio: 0)';\n$isSafari: 'not all and (min-resolution:.001dpcm)';\n$isTouchDevice: '(pointer: coarse)';\n$isTouchScreen: '(pointer: fine) and (any-pointer: coarse) and (any-pointer: fine)';\n$hasMultiplePointers: '(any-pointer: coarse) and (any-pointer: fine)';\n\n:export {\n    isIE: string.unquote($isIE);\n    isFirefox: string.unquote($isFirefox);\n    isSafari: string.unquote($isSafari);\n    isTouchDevice: string.unquote($isTouchDevice);\n    isTouchScreen: string.unquote($isTouchScreen);\n    hasMultiplePointers: string.unquote($hasMultiplePointers);\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"isIE": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isIe": "(-ms-high-contrast:active),(-ms-high-contrast:none)",
	"isFirefox": "(min--moz-device-pixel-ratio:0)",
	"isSafari": "not all and (min-resolution:.001dpcm)",
	"isTouchDevice": "(pointer:coarse)",
	"isTouchScreen": "(pointer:fine) and (any-pointer:coarse) and (any-pointer:fine)",
	"hasMultiplePointers": "(any-pointer:coarse) and (any-pointer:fine)",
	"icon": "Icons_icon",
	"default-icon": "Icons_default-icon",
	"defaultIcon": "Icons_default-icon"
};