import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useRoutes } from 'components/primitives/route';
import { UseSanaTexts } from 'components/sanaText';
import { preload as banner } from './ErrorBanner';
import { preload as retryToast } from './ErrorToastWithRetry';
import { preload as reloadToast } from './ErrorToastWithReload';

const None = () => null;

const Preload = ({ shouldPreload }) => {
  if (!shouldPreload)
    return null;

  useRoutes([...banner.routes, ...retryToast.routes, ...reloadToast.routes]);

  return (
    <UseSanaTexts options={banner.texts.concat(retryToast.texts, reloadToast.texts)}>{None}</UseSanaTexts>
  );
};

Preload.propTypes = {
  shouldPreload: PropTypes.bool,
};

export default connect(({ localization: { currentLanguage: { id } } }) => ({ shouldPreload: !!id }))(Preload);