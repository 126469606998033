import { routeNames } from 'routes';
import {
  closedStoreMiddleware,
  authorizeMiddleware,
  createAbilityMiddleware,
  applyMiddleware,
} from './middlewares';
import { AbilityTo } from 'behavior/user/constants';

import { createSystemPageHandler, homeHandler, createFallbackHandler } from './system';
import { contentHandler } from './contentPage';
import { representHandler } from './representation';
import { newsHandler } from './news';
import { subAccountHandler, subAccountsHandler } from './subAccounts';
import { newsDetailHandler } from './news/details';
import { visualDesignerHandler } from './visualDesigner';
import { faqHandler } from './faq';
import { productDetailsHandler } from './product';
import { productListHandler } from './productList';
import { basketHandler } from './basket';
import { websiteRedirectHandler } from './websiteRedirect';
import { previewHandler } from './preview';
import { lastViewedPageHandler } from './productList/lastViewed';
import { orderTemplatesHandler } from './orderTemplates';
import { searchHandler } from './productList/search';
import { productsWithCategoryHandler } from './productList/withCategory';
import {
  ordersHandler,
  quotesHandler,
  invoicesHandler,
  returnOrdersHandler,
  creditNotesHandler,
  returnReceiptsHandler,
  shipmentsHandler,
} from './documents';
import { resetPasswordHandler } from './resetPassword';
import { handler as registrationHandler } from './registration';
import { handler as createProspectHandler } from './createProspect';
import { handler as loginPageHandler } from './login';
import { handler as forgotPasswordPageHandler } from './forgotPassword';
import { documentHandler } from './document';
import { handler as productComparisonPageHandler } from './productComparison';
import { salesAgreementHandler, salesAgreementsHandler } from './salesAgreements';
import {
  checkoutHandler,
  orderSubmitHandler,
  orderFailedHandler,
  orderCancelledHandler,
  paymentErrorHandler,
} from './checkout';
import { handler as editProfileHandler } from './editProfile';
import { handler as orderAuthorizationsHandler } from './orderAuthorizations';
import { handler as invoicePaymentHandler } from './invoicePayment';
import { createDocFreeReturnOrderHandler } from './createDocFreeReturnOrder';
import { createDocBasedReturnOrderHandler } from './createDocBasedReturnOrder';
import {
  orderPaymentHandler,
  paymentSubmitHandler,
  paymentCancelledHandler,
  paymentFailedHandler,
} from './orderPayment';
import { handler as wishListHandler } from './wishList';

export { default as errorHandler } from './errorHandler';
export const fallbackHandler = createFallbackHandler(createSystemPageHandler(), routeNames.NotFound);

const createSubAccountMiddleware = createAbilityMiddleware(AbilityTo.CreateSubAccount);
const useSalesAgreementsMiddleware = createAbilityMiddleware(AbilityTo.UseSalesAgreements);
const viewCatalogMiddleware = createAbilityMiddleware(AbilityTo.ViewCatalog);
const orderProductsMiddleware = createAbilityMiddleware(AbilityTo.OrderProducts);

export const handlers = {
  [routeNames.NotFound]: applyMiddleware(fallbackHandler, closedStoreMiddleware),
  [routeNames.Login]: loginPageHandler,
  [routeNames.Registration]: applyMiddleware(registrationHandler, closedStoreMiddleware),
  [routeNames.MyAccount]: applyMiddleware(createSystemPageHandler(), authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewMyAccountPage)),
  [routeNames.ChangePassword]: applyMiddleware(createSystemPageHandler(), authorizeMiddleware),
  [routeNames.EditProfile]: applyMiddleware(editProfileHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.EditProfile)),
  [routeNames.ForgotPassword]: forgotPasswordPageHandler,
  [routeNames.ResetPassword]: resetPasswordHandler,
  [routeNames.SubAccounts]: applyMiddleware(subAccountsHandler, authorizeMiddleware, createSubAccountMiddleware),
  [routeNames.NewSubAccount]: applyMiddleware(subAccountHandler, authorizeMiddleware, createSubAccountMiddleware),
  [routeNames.EditSubAccount]: applyMiddleware(subAccountHandler, authorizeMiddleware, createSubAccountMiddleware),
  [routeNames.CreateProspect]: applyMiddleware(createProspectHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.CreateProspect)),
  [routeNames.Represent]: applyMiddleware(representHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.Impersonate)),
  [routeNames.Orders]: applyMiddleware(ordersHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewOrders, false)),
  [routeNames.Quotes]: applyMiddleware(quotesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewQuotes, false)),
  [routeNames.Invoices]: applyMiddleware(invoicesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewInvoices, false)),
  [routeNames.ReturnOrders]: applyMiddleware(returnOrdersHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewReturnOrders, false)),
  [routeNames.CreateDocFreeReturnOrder]: applyMiddleware(createDocFreeReturnOrderHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.CreateDocFreeReturnOrder)),
  [routeNames.CreateDocBasedReturnOrder]: applyMiddleware(createDocBasedReturnOrderHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.CreateDocBasedReturnOrder)),
  [routeNames.CreditNotes]: applyMiddleware(creditNotesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewCreditNotes, false)),
  [routeNames.ReturnReceipts]: applyMiddleware(returnReceiptsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewReturnReceipts, false)),
  [routeNames.Shipments]: applyMiddleware(shipmentsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.ViewShipments, false)),
  [routeNames.OrderAuthorizations]: applyMiddleware(orderAuthorizationsHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.AuthorizeOrders)),
  [routeNames.DocumentDetails]: applyMiddleware(documentHandler, authorizeMiddleware),
  [routeNames.OrderTemplates]: applyMiddleware(orderTemplatesHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.UseOrderTemplates)),
  [routeNames.SalesAgreements]: applyMiddleware(salesAgreementsHandler, authorizeMiddleware, useSalesAgreementsMiddleware),
  [routeNames.SalesAgreement]: applyMiddleware(salesAgreementHandler, authorizeMiddleware, useSalesAgreementsMiddleware),
  [routeNames.ContactUs]: applyMiddleware(createSystemPageHandler(), closedStoreMiddleware),
  [routeNames.Home]: applyMiddleware(homeHandler, closedStoreMiddleware),
  [routeNames.ContentPage]: applyMiddleware(contentHandler, closedStoreMiddleware),
  [routeNames.NewsOverview]: applyMiddleware(newsHandler, closedStoreMiddleware),
  [routeNames.NewsDetail]: applyMiddleware(newsDetailHandler, closedStoreMiddleware),
  [routeNames.NewsletterSubscribe]: applyMiddleware(createSystemPageHandler(), createAbilityMiddleware(AbilityTo.SubscribeToNewsletter), closedStoreMiddleware),
  [routeNames.NewsletterUnSubscribe]: applyMiddleware(createSystemPageHandler(), createAbilityMiddleware(AbilityTo.SubscribeToNewsletter), closedStoreMiddleware),
  [routeNames.Search]: applyMiddleware(searchHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [routeNames.ProductsWithCategory]: applyMiddleware(productsWithCategoryHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [routeNames.ProductDetails]: applyMiddleware(productDetailsHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [routeNames.ProductList]: applyMiddleware(productListHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [routeNames.VisualDesigner]: visualDesignerHandler,
  [routeNames.FaqPage]: applyMiddleware(faqHandler, closedStoreMiddleware),
  [routeNames.BasketPage]: applyMiddleware(basketHandler, closedStoreMiddleware, orderProductsMiddleware),
  [routeNames.WebsiteRedirect]: applyMiddleware(websiteRedirectHandler, closedStoreMiddleware),
  [routeNames.PagePreview]: previewHandler,
  [routeNames.LastViewedProducts]: applyMiddleware(lastViewedPageHandler, closedStoreMiddleware, viewCatalogMiddleware),
  [routeNames.ProductComparison]: applyMiddleware(productComparisonPageHandler, closedStoreMiddleware, createAbilityMiddleware(AbilityTo.CompareProducts)),
  [routeNames.Checkout]: applyMiddleware(checkoutHandler, closedStoreMiddleware, orderProductsMiddleware),
  [routeNames.QuotePromotion]: applyMiddleware(checkoutHandler, authorizeMiddleware, closedStoreMiddleware, orderProductsMiddleware),
  [routeNames.OrderSubmit]: orderSubmitHandler,
  [routeNames.OrderFailed]: orderFailedHandler,
  [routeNames.OrderCancelled]: orderCancelledHandler,
  [routeNames.QuotePromotionCancelled]: orderCancelledHandler,
  [routeNames.PaymentError]: paymentErrorHandler,
  [routeNames.InvoicePayment]: applyMiddleware(invoicePaymentHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.PayInvoice)),
  [routeNames.OrderPayment]: orderPaymentHandler,
  [routeNames.PaymentSubmit]: paymentSubmitHandler,
  [routeNames.PaymentCancelled]: paymentCancelledHandler,
  [routeNames.PaymentFailed]: paymentFailedHandler,
  [routeNames.WishList]: applyMiddleware(wishListHandler, authorizeMiddleware, createAbilityMiddleware(AbilityTo.UseWishlist)),
};
