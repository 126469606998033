import React from 'react';
import { PropTypes } from 'prop-types';
import { useLoadEffect } from '../hooks';
import { connect } from 'react-redux';
import { requestAddonsMetadata } from './metadata/actions';
import AddonContext from './AddonContext';

const AddonProvider = ({ registry, metadataLoaded, dispatch, children }) => {
  useLoadEffect(() => {
    if (!metadataLoaded)
      dispatch(requestAddonsMetadata());
  });

  return (
    <AddonContext.Provider value={{ registry }}>
      {children}
    </AddonContext.Provider>
  );
};

AddonProvider.propTypes = {
  registry: PropTypes.object.isRequired,
  metadataLoaded: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default connect(
  ({ addons: { metadata } }) => ({ metadataLoaded: metadata != null }),
)(AddonProvider);
