import styles from '../MainNav.module.scss';
import headerStyles from 'components/sections/header/Header.module.scss';
import React, { useState, useEffect, useLayoutEffect, useCallback, useRef } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { Container } from 'components/primitives/grid';
import OpenerButton from '../OpenerButton';
import MainNav from '../MainNav';
import NavItem from './NavItem';
import NavDimensionsProvider from '../NavDimensionsProvider';
import { fillGapSelectors } from 'components/objects/modals';
import { useIsMobileSafari } from 'utils/detections';
import { useMultiColumnDropdownSelector } from '../hooks';
import { hoveredListClass } from '../eventHandlers';
import scrollIntoView from 'scroll-into-view';
import {
  enablePageScroll, disablePageScroll,
  addFillGapSelector, removeFillGapSelector,
} from 'scroll-lock';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';
import { useLayoutShifter } from 'utils/layout';

const { modalMode } = headerStyles;
const openerComponentStyle = { display: 'none' };
// data-scroll-lock-scrollable - attribute that allows scrolling on and inside of element when body scroll disabled
const scrollableAttr = 'data-scroll-lock-scrollable';
const textsToLoad = ['Aria_MainNavigation_ModalToggleButton'];
const iOSBottomIndent = +styles.iOSBottomIndent;

const ModalNav = ({
  isVisible,
  isDesktop,
  noGutters,
  visibilityChangeHandler,
  openerContainer,
  OpenerComponent = OpenerButton,
}) => {
  const multiColumnDropdown = useMultiColumnDropdownSelector();
  const { bottomFixedElementsHeight } = useLayoutShifter();
  const [shouldRender, setRenderState] = useState(!!openerContainer.current);
  const openerComponentRef = useRef(null);
  const navRef = useRef(null);
  const containerRef = useRef(null);
  const isMobileSafari = useIsMobileSafari();

  const onItemsLoaded = useCallback(() => {
    openerComponentRef.current.style.display = '';
  }, []);

  const handleOpenerClick = useCallback(() => visibilityChangeHandler(), [isVisible]);
  const handleSkipLinkClick = useCallback(() => visibilityChangeHandler(true), []);

  useEffect(() => {
    setRenderState(!!openerContainer.current);
  }, [!!openerContainer.current]);

  useEffect(() => {
    if (!containerRef.current || !isMobileSafari)
      return;

    // Additional bottom indent for iOS Safari to ensure menu content is not hidden below actual device screen.
    containerRef.current.parentElement.style.paddingBottom = `${Math.abs(bottomFixedElementsHeight - iOSBottomIndent)}px`;
  }, [shouldRender, isMobileSafari, bottomFixedElementsHeight]);

  useLayoutEffect(
    handleNavVisibilityChange.bind(
      null,
      isVisible,
      containerRef.current,
      navRef.current,
      multiColumnDropdown,
    ),
    [isVisible, multiColumnDropdown],
  );

  if (!shouldRender)
    return null;

  const modalNavOpener = (
    <UseSanaTexts options={textsToLoad} dontWait>
      {([ariaModalNavToggleButtonText]) => (
        <OpenerComponent
          onClick={handleOpenerClick}
          isActive={isVisible}
          customClass={styles.btnModalNavOpener}
          ref={openerComponentRef}
          style={openerComponentStyle}
          aria-label={stripHtmlTagsAndFormatting(ariaModalNavToggleButtonText)}
          aria-pressed={isVisible}
          aria-controls="mainNavigation"
        />
      )}
    </UseSanaTexts>
  );

  const wrapperClassNames = `${styles.modalNavWrapper} ${isVisible ? styles.visible : ''} ${multiColumnDropdown ? styles.limitedHeight : styles.scrollable}`;

  return (
    <>
      {ReactDOM.createPortal(modalNavOpener, openerContainer.current)}
      <section
        className={wrapperClassNames}
        data-scroll-lock-fill-gap={(multiColumnDropdown && isDesktop) || null}
        aria-hidden={!isVisible}
        aria-expanded={isVisible}
      >
        <Container
          fluid={!isDesktop}
          className={`${styles.navContainer}${noGutters ? ` ${styles.noGutters}` : ''}`}
          ref={containerRef}
        >
          <NavDimensionsProvider containerRef={containerRef} navRef={navRef}>
            <MainNav
              ref={navRef}
              NavItemComponent={NavItem}
              navClass={`${styles.modal} ${multiColumnDropdown ? styles.multicolumn : styles.simple}`}
              onItemsLoaded={onItemsLoaded}
              handleSkipLinkClick={handleSkipLinkClick}
              omitSkipLinkScroll
            />
          </NavDimensionsProvider>
        </Container>
      </section>
    </>
  );
};

ModalNav.propTypes = {
  isVisible: PropTypes.bool,
  isDesktop: PropTypes.bool,
  noGutters: PropTypes.bool,
  visibilityChangeHandler: PropTypes.func.isRequired,
  openerContainer: PropTypes.object.isRequired,
  OpenerComponent: PropTypes.object,
};

export default React.memo(ModalNav);

function handleNavVisibilityChange(isVisible, navContainerElement, navRefElement, multiColumnDropdown) {
  if (!navContainerElement || !navRefElement)
    return;

  const [navListRootElement] = navRefElement.children;
  const { parentElement: scrollableContainer } = navContainerElement;
  const { parentElement: stickyHeaderWrapper } = scrollableContainer;
  let navListRootObserver;

  if (isVisible) {
    stickyHeaderWrapper.classList.add(modalMode); // Set modal mode styles for sticky header wrapper element
    if (multiColumnDropdown) {
      navListRootElement.setAttribute(scrollableAttr, true);
      navListRootObserver && navListRootObserver.disconnect();
    } else {
      const mutationOptions = { attributes: true, attributeFilter: ['class'] };
      scrollableContainer.setAttribute(scrollableAttr, true);
      scrollableContainer.scrollTop = 0;
      navListRootObserver = createNavListRootMutationObserver(scrollableContainer);
      navListRootObserver.observe(navListRootElement, mutationOptions);
    }
    disableBodyScroll();
  }

  return () => {
    navListRootObserver && navListRootObserver.disconnect();
    stickyHeaderWrapper.classList.remove(modalMode);
    scrollableContainer.removeAttribute(scrollableAttr);
    navListRootElement.removeAttribute(scrollableAttr);
    if (document.body.hasAttribute('data-scroll-lock-locked')) {
      enableBodyScroll();
    }
  };
}

function disableBodyScroll() {
  disablePageScroll();
  addFillGapSelector(fillGapSelectors);
}

function enableBodyScroll() {
  enablePageScroll();
  removeFillGapSelector(fillGapSelectors);
}

function createNavListRootMutationObserver(scrollableContainer) {
  // If simple view, all sublists closed and navigation list root element
  // is not in the viewport scroll list root into view
  const validTarget = target => target === scrollableContainer;
  return new MutationObserver(mutations => {
    const [classAttrMutation] = mutations;
    const { target } = classAttrMutation;
    if (target.classList.contains(hoveredListClass))
      return;

    const topOffset = parseInt(scrollableContainer.parentElement.style.paddingTop, 10) || 0;
    const { bottom } = target.getBoundingClientRect();

    if (bottom > topOffset)
      return;

    scrollIntoView(target, {
      time: 200, validTarget, align: { top: 0, topOffset },
    });
  });
}