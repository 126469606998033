import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup } from 'components/objects/forms';
import { TextBoxField, EmailAddressField, CheckboxField } from 'components/objects/forms/fields';
import { useSimpleTexts } from 'components/sanaText';

const SubAccountFields = ({ orderProducts, showPrices, showStock }) => {
  const [
    nameTitle,
    emailTitle,
    activeTitle,
    canAuthorizeOrdersTitle,
    canSeePricesTitle,
    canSeeStockTitle,
    canOrderTitle,
  ] = useSimpleTexts([
    'Name',
    'EmailAddress',
    'Active',
    'MayAuthorizeOrders',
    showPrices ? 'MaySeePrices' : '',
    showStock ? 'MaySeeStock' : '',
    orderProducts ? 'MayOrder' : '',
  ]).texts;

  return (
    <>
      <FormGroup
        fieldName="name"
        fieldTitle={nameTitle}
        fieldComponent={TextBoxField}
        maxLength={50}
        required
        validation={{
          required: true,
        }}
      />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
          uniqueEmail: true,
        }}
      />
      <FormGroup
        fieldName="active"
        fieldTitle={activeTitle}
        fieldComponent={CheckboxField}
      />
      {showPrices && (
        <FormGroup
          fieldName="canSeePrices"
          fieldTitle={canSeePricesTitle}
          fieldComponent={CheckboxField}
        />
      )}
      {showStock && (
        <FormGroup
          fieldName="canSeeStock"
          fieldTitle={canSeeStockTitle}
          fieldComponent={CheckboxField}
        />
      )}
      {orderProducts && (
        <FormGroup
          fieldName="canOrder"
          fieldTitle={canOrderTitle}
          fieldComponent={CheckboxField}
        />
      )}
      <FormGroup
        fieldName="canAuthorizeOrders"
        fieldTitle={canAuthorizeOrdersTitle}
        fieldComponent={CheckboxField}
      />
    </>
  );
};

SubAccountFields.propTypes = {
  orderProducts: PropTypes.bool,
  showPrices: PropTypes.bool,
  showStock: PropTypes.bool,
};

export default React.memo(SubAccountFields);