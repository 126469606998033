import styles from './NotFound.module.scss';
import React from 'react';
import { Link, BackLink } from 'components/primitives/links';
import { RichText, SimpleText, useSanaTexts } from 'components/sanaText';
import { routesBuilder } from 'routes';
import { Helmet } from 'react-helmet';
import { InfoAlert } from 'components/primitives/alerts';
import { usePageTitle } from '../hooks';
import { makeSimpleText } from 'utils/render';

const NotFound = () => {
  const [title] = useSanaTexts(['NotFound'], makeSimpleText).texts;
  const pageTitle = usePageTitle(title);

  const backLink = (
    <BackLink>
      <SimpleText textKey="PageNotFound_BackLink" />
    </BackLink>
  );

  const homePageLink = (
    <Link to={routesBuilder.forHome}>
      <SimpleText textKey="ErrorPages_HomepageLink" />
    </Link>
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <InfoAlert className={styles.block}>
        <div>
          <RichText textKey="PageNotFound_IntroText" />
        </div>
        <ul className={styles.actionList}>
          <li>
            <SimpleText textKey="PageNotFound_CheckAddress" />
          </li>
          <li>
            <SimpleText textKey="PageNotFound_BackLinkTemplate" formatWith={[backLink]} formatAsHtml />
          </li>
          <li>
            <SimpleText textKey="ErrorPages_HomepageLinkTemplate" formatWith={[homePageLink]} formatAsHtml />
          </li>
        </ul>
      </InfoAlert>
    </>
  );
};

export default React.memo(NotFound);