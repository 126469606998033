import styles from './WishList.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { Link } from 'components/primitives/links';
import { WithProductClickTracking } from 'components/objects/analytics';

const ProductInfo = ({
  productUrl,
  route,
  productTitle,
  productId,
  description,
  variantTitle,
}) => (
    <>
      {productUrl
        ? (
          <WithProductClickTracking>
            {handleClick => (
              <Link
                onClick={handleClick}
                url={productUrl}
                to={route}
                className={styles.largeTitle}
              >
                {productTitle}
              </Link>
            )}
          </WithProductClickTracking>
        )
        : <span className={styles.largeTitle}>{productTitle}</span>
      }
      <div className={variantTitle ? styles.variantIdWrapper : null}>
        <div className={styles.productId}>
          <SimpleText textKey="General_Product_Id_Label" /> {productId}
        </div>
      </div>
      {description && (
        <div className={styles.productDescription}>{description}</div>
      )}
    </>
  );

ProductInfo.propTypes = {
  productUrl: PropTypes.string,
  route: PropTypes.any.isRequired,
  productTitle: PropTypes.string,
  productId: PropTypes.string,
  description: PropTypes.string,
  variantTitle: PropTypes.string,
};

export default React.memo(ProductInfo);