import styles from '../OrderBox.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withAbilities } from 'components/objects/user';
import { AbilityTo, AbilityState } from 'behavior/user/constants';
import { SimpleText } from 'components/sanaText';
import ViewProductButton from './ViewProductButton';
import { SanaButton } from 'components/primitives/buttons';
import { generateKey } from 'utils/helpers';
import { addProducts } from 'behavior/basket';
import { connect } from 'react-redux';
import { abilitiesPropType } from 'behavior/user';
import { createQuantityModel } from 'components/primitives/product';
import { Placeholder } from 'components/primitives/placeholders';

const OrderBox = ({
  product,
  abilities: [orderProductAbility],
  basketModifiedDate,
  basketUpdatedById,
  addProducts,
  className = '',
  withoutBackTo,
}) => {
  const {
    id,
    isOrderable,
    hasVariants,
    productConfiguratorInfo,
  } = product;

  const uomId = product.uom && product.uom.id;
  const uom = product.uoms
    ? product.uoms.find(u => u.id === uomId)
    : product.uom;
  const quantity = createQuantityModel(uom).initial;
  const [componentId] = useState(generateKey);
  const [disabled, setDisabled] = useState(false);
  const [serialNo, setSerialNo] = useState('');
  const [needSerialNo, setNeedSerialNo] = useState(false);

  const serialNoInput = useRef();

  const handleSerialNoChange = e => {
    setSerialNo(e.target.value);
  };

  const addToBasket = useCallback(() => {
    if (disabled)
      return;

    var sn = serialNoInput?.current?.value;

    if (sn!==null && sn!==undefined && sn.trim()==='') {
      setNeedSerialNo(true);
      return;
    }

    setNeedSerialNo(false);

    setDisabled(true);
    addProducts([{
      productId: id,
      uomId,
      quantity,
      serialNo: sn,
    }], componentId);

    setSerialNo('');
  }, []);

  useEffect(() => {
    if (basketUpdatedById === componentId)
      setDisabled(false);
  }, [basketModifiedDate, basketUpdatedById]);

  if (orderProductAbility !== AbilityState.Available)
    return null;

  let content = null;
  const placeholder = <Placeholder className="placeholder" />;


  if (isOrderable == null) {
    content = placeholder;
  } else if (isOrderable === false) {
    content = (
      <span className="msg-not-available">
        <SimpleText textKey="Product_NotAvailable" placeholder={placeholder} />
      </span>
    );
  } else if (productConfiguratorInfo.isProductConfigurable) {
    content = (
      <ViewProductButton
        textKey="ConfigureProduct"
        className={`${btnStyles.btnSmall} btn-configure`}
        titleTextKey="ConfigureProduct_ListPage"
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
      />
    );
  } else if (hasVariants) {
    content = (
      <ViewProductButton
        textKey="SelectVariants"
        className={`${btnStyles.btnSmall} btn-action`}
        product={product}
        placeholder={placeholder}
        withoutBackTo={withoutBackTo}
      />
    );
  } else {
    content = (
      <>
        <SanaButton className={`${btnStyles.btnAction} ${btnStyles.btnSmall} btn-action`}
          textKey="AddToBasket"
          onClick={addToBasket}
          placeholder={placeholder}
        />
        {product.requiresSerialNo &&
          <div>
            <SimpleText textKey="BasketLine_SerialNo" />
            <input type="text" required={true} onChange={handleSerialNoChange} ref={serialNoInput} value={serialNo} />
            {needSerialNo &&
              <div className={`${styles.validationError}`}>
                <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times-circle" className="svg-inline--fa fa-times-circle fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z"></path></svg>
                <SimpleText textKey="SerialNoRequired" placeholder={placeholder} />
              </div>}
          </div>}
      </>
    );
  }

  return (
    <div className={`${className} ${styles.orderBox}`} role="article">
      {content}
    </div>
  );
};

OrderBox.propTypes = {
  product: PropTypes.shape({
    id: PropTypes.string.isRequired,
    isOrderable: PropTypes.bool,
    hasVariants: PropTypes.bool,
    uom: PropTypes.shape({
      id: PropTypes.string,
    }),
    uoms: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
    })),
    productConfiguratorInfo: PropTypes.object,
  }),
  abilities: abilitiesPropType,
  basketModifiedDate: PropTypes.number,
  basketUpdatedById: PropTypes.string,
  addProducts: PropTypes.func.isRequired,
  className: PropTypes.string,
  withoutBackTo: PropTypes.bool,
};

export default connect(({ basket }) => ({
  basketModifiedDate: basket.modifiedDate,
  basketUpdatedById: basket.updated.updaterId,
}),
  { addProducts },
)(withAbilities(OrderBox, [AbilityTo.OrderProducts]));
