import { useMemo, useEffect } from 'react';
import { generateKey } from '../helpers';

export default () => {
  const [downloadingIframes, download] = useMemo(() => {

    const downloadingIframes = new Map();

    return [downloadingIframes, download];

    function download(src, onDownloaded, onFailed) {
      let iframeData = downloadingIframes.get(src);
      if (iframeData) {
        clearInterval(iframeData.intervalId);

        const oldIframe = document.getElementById(iframeData.id);
        oldIframe && document.body.removeChild(oldIframe);
      } else {
        iframeData = {};
        downloadingIframes.set(src, iframeData);
      }

      iframeData.id = generateKey();

      const iframe = document.createElement('iframe');
      iframe.id = iframeData.id;
      iframe.style.visibility = 'hidden';
      iframe.src = src + (src.indexOf('?') === -1 ? '?' : '&') + 'download=' + iframeData.id;
      document.body.appendChild(iframe);

      const cookieName = 'download' + iframeData.id;

      iframeData.intervalId = setInterval(() => {
        if (document.cookie.toLowerCase().indexOf(cookieName) > -1) {
          clearInterval(iframeData.intervalId);

          const isFailed = withHtmlOrFailed(iframe);

          if (isFailed)
            onFailed();
          else
            onDownloaded();
        }

        if (withHtmlOrFailed(iframe)) {
          clearInterval(iframeData.intervalId);
          onFailed();
        }
      }, 150);
    }
  }, []);

  useEffect(() => () => {
    for (const iframeData of downloadingIframes.values()) {
      clearInterval(iframeData.intervalId);

      const oldIframe = document.getElementById(iframeData.id);
      oldIframe && document.body.removeChild(oldIframe);
    }
  }, []);

  return download;
};

function withHtmlOrFailed(iframe) {
  try {
    let iframeDoc = iframe.contentWindow || iframe.contentDocument;
    if (iframeDoc.document)
      iframeDoc = iframeDoc.document;
    return !!(iframeDoc.body && iframeDoc.body.innerHTML);
  }
  catch (err) {
    // IE & Chromium throws a permission denied error in case of 404/503 statuses.
    if (err && (err.number === -2146828218 || err.code === 18)) {
      return true;
    }
    else {
      throw err;
    }
  }
}
