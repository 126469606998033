exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".ShoppingCartSuggestions_suggestions-btn{display:flex;justify-content:center;align-items:center;margin:0 var(--gutterWidth,16px) 15px;padding:7px 10px;min-width:auto;min-height:auto}.ShoppingCartSuggestions_suggestions-btn .ShoppingCartSuggestions_icon{max-width:.28em;max-height:.28em}.ShoppingCartSuggestions_suggestions-btn.ShoppingCartSuggestions_hide-label{padding:0;margin:0;width:44px;height:44px}.ShoppingCartSuggestions_suggestions-btn.ShoppingCartSuggestions_hide-label .ShoppingCartSuggestions_icon{max-width:20px;max-height:20px}.ShoppingCartSuggestions_suggestions-btn .btn-cnt{margin-left:.6em;padding-top:0}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/sections/header/ShoppingCartSuggestions.module.scss"],"names":[],"mappings":"AAGA,yCACI,aACA,uBACA,mBACA,sCACA,iBACA,eACA,eAFJ,CAII,uEACI,gBACA,gBAFR,CAKI,4EACI,UACA,SACA,WACA,WAHR,CAKQ,0GACI,eACA,eAHZ,CAOI,kDACI,iBACA,aALR,CAAA","file":"ShoppingCartSuggestions.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n@import \"css/_theme.scss\";\n\n.suggestions-btn {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin: 0 $gutterWidth 15px;\n    padding: 7px 10px;\n    min-width: auto;\n    min-height: auto;\n\n    .icon {\n        max-width: 0.28em;\n        max-height: 0.28em;\n    }\n\n    &.hide-label {\n        padding: 0;\n        margin: 0;\n        width: 44px;\n        height: 44px;\n\n        .icon {\n            max-width: 20px;\n            max-height: 20px;\n        }\n    }\n\n    :global(.btn-cnt) {\n        margin-left: 0.6em;\n        padding-top: 0;\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"suggestions-btn": "ShoppingCartSuggestions_suggestions-btn",
	"suggestionsBtn": "ShoppingCartSuggestions_suggestions-btn",
	"icon": "ShoppingCartSuggestions_icon",
	"hide-label": "ShoppingCartSuggestions_hide-label",
	"hideLabel": "ShoppingCartSuggestions_hide-label"
};