import { useLayoutEffect, useEffect } from 'react';

export default () => {
  useLayoutEffect(() => {
    // overflowY should be set to hidden firstly to avoid issues in Firefox with
    // incorrect document size calculations which results in scrollbar present
    // even if the content is not overflowed
    document.body.style.overflowY = 'hidden';
  }, []);

  useEffect(() => {
    document.body.style.overflowY = 'auto';
    return () => document.body.style.overflowY = '';
  }, []);
};
