import { useEffect } from 'react';

let pendingServerEffects = null;

export function runPendingServerEffects() {
  if (pendingServerEffects == null)
    return;

  const capturedList = pendingServerEffects;
  pendingServerEffects = null;
  const errors = capturedList.map(effect => {
    try {
      effect();
      return null;
    }
    catch (e) {
      return e;
    }
  }).filter(e => e);
  if (errors.length)
    throw errors;
}

function useServerEffect(effect) {
  if (!pendingServerEffects) {
    pendingServerEffects = [];
    setTimeout(runPendingServerEffects, 0);
  }
  pendingServerEffects.unshift(effect);
}

export default typeof window === 'undefined' ? useServerEffect : useEffect;
