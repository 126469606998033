import { routeNames } from 'routes/routeNames';

const allowedRoutes = new Set([
  routeNames.ProductDetails,
  routeNames.LastViewedProducts,
  routeNames.ProductList,
  routeNames.Search,
  routeNames.ProductsWithCategory,
]);

/**
 * Checks whether widget can be shown on the page with specified route name.
 * @param {string} routeName - route name.
 * @returns {boolean} value indicating whether widget is allowed for the route.
 */
export const isWidgetAllowed = routeName => allowedRoutes.has(routeName);
