import React from 'react';
import { ContentBlock } from 'components/contentBlocks';
import { VdContentBlockContainer } from 'components/visualDesigner';

export default function createContentBlock(contentBlock, index, isPrintMode) {
  const { name, ...props } = contentBlock;

  return (
    <VdContentBlockContainer key={contentBlock.id} index={index} contentBlock={contentBlock}>
      <ContentBlock componentName={name} isPrintMode={isPrintMode} {...props} />
    </VdContentBlockContainer>
  );
}