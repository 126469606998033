import styles from './Orders.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { RichText, SimpleText } from 'components/sanaText';
import Filter from './Filter';
import { LoadMoreButton } from 'components/primitives/buttons';
import { useLoadMore } from './hooks';
import { useOnChange } from 'utils/hooks';
import { documentTypePropType } from './propTypes';
import { useIsPreview, useHandlerLockerInPreview } from 'components/objects/preview';

const Documents = ({
  documents,
  totalCount,
  size,
  filter,
  loadMoreRoute,
  textKeys,
  documentType,
  actions,
  footer,
  children,
  showAuthorizationStatusFilter,
}) => {
  const loadMore = useLoadMore(loadMoreRoute);
  const filterRef = useRef();
  const isPreview = useIsPreview();

  const applyFilter = useHandlerLockerInPreview(filter => {
    filterRef.current = filter;
    loadMore(0, filter);
  });

  useOnChange(() => {
    filterRef.current = filter;
  }, [filter]);

  return (
    <div className={styles.order}>
      {textKeys.introduction && <div className={styles.introduction}><RichText textKey={textKeys.introduction} /></div>}
      <Filter
        documentType={documentType}
        filter={filterRef.current}
        applyFilter={applyFilter}
        showAuthorizationStatusFilter={showAuthorizationStatusFilter}
      />
      <div className={styles.recentOrders}>
        <h2 className="h3"><SimpleText textKey={textKeys.listHeader} /></h2>
        {!isPreview ? (
          <>
            {!!totalCount && (
              <>
                {children}
                <div className={styles.actionsBlock}>
                  <LoadMoreButton
                    className={btnStyles.btnBig}
                    textKey={textKeys.loadMore}
                    totalCount={totalCount}
                    loadedCount={documents.length}
                    size={size}
                    loadNext={index => loadMore(index, filterRef.current)}
                  />
                  {actions}
                </div>
              </>
              )}
              {!totalCount && (
                <div className="msg-block">
                  <SimpleText textKey={textKeys.noItems} />
                </div>
              )}
          </>
        ) : children}
        {footer}
      </div>
    </div>
  );
};

Documents.propTypes = {
  documents: PropTypes.array,
  totalCount: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  filter: PropTypes.shape({
    orderDate: PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }).isRequired,
  }).isRequired,
  loadMoreRoute: PropTypes.object.isRequired,
  textKeys: PropTypes.shape({
    introduction: PropTypes.string,
    listHeader: PropTypes.string.isRequired,
    loadMore: PropTypes.string.isRequired,
    noItems: PropTypes.string.isRequired,
  }).isRequired,
  documentType: documentTypePropType.isRequired,
  actions: PropTypes.node,
  footer: PropTypes.node,
  children: PropTypes.node.isRequired,
  showAuthorizationStatusFilter: PropTypes.bool,
};

export default Documents;

export const selectPropsFromPage = ({
  docs: {
    items,
    totalCount,
  },
  size,
  filter,
}) => ({
  documents: items,
  totalCount,
  size,
  filter,
});
