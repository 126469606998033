exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".NonOrderable_closable{overflow:hidden;margin:20px 0}.NonOrderable_closable .NonOrderable_block{margin:0}.NonOrderable_closable.NonOrderable_closed{max-height:0;transition:max-height .25s}.NonOrderable_closable:not(:last-child)>*{margin-bottom:0}.NonOrderable_lines{padding:7px 1.2em;margin:0}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/basket/nonOrderable/NonOrderable.module.scss"],"names":[],"mappings":"AAUA,uBACI,gBACA,aALJ,CAOI,2CACI,QALR,CAQI,2CACI,aACA,0BANR,CAUQ,0CACI,eARZ,CAaA,oBACI,kBACA,QAVJ,CAAA","file":"NonOrderable.module.scss","sourcesContent":["/// <reference path=\"../../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n$closeTransition: 250;\n$iconTransition: 100;\n\n:export {\n    closeTransition: $closeTransition;\n}\n\n.closable {\n    overflow: hidden;\n    margin: 20px 0;\n\n    .block {\n        margin: 0;\n    }\n\n    &.closed {\n        max-height: 0;\n        transition: max-height #{$closeTransition}ms;\n    }\n\n    &:not(:last-child) {\n        > * {\n            margin-bottom: 0;\n        }\n    }\n}\n\n.lines {\n    padding: 7px 1.2em;\n    margin: 0;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"closeTransition": "250",
	"closable": "NonOrderable_closable",
	"block": "NonOrderable_block",
	"closed": "NonOrderable_closed",
	"lines": "NonOrderable_lines"
};