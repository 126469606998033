exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".NotValidCustomer_no-gutters{padding-left:0;padding-right:0}.NotValidCustomer_info{background-color:var(--alert_Info_BackgroundColor,#f3f6f9)}.NotValidCustomer_info .NotValidCustomer_no-margin,.NotValidCustomer_info p{margin:0}.NotValidCustomer_info p{line-height:normal}.NotValidCustomer_content-wrapper{display:inline-block;vertical-align:middle}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/notValidCustomer/NotValidCustomer.module.scss"],"names":[],"mappings":"AAGA,6BACI,eACA,eAFJ,CAKA,uBACI,0DAFJ,CAQI,4EAHI,QAER,CACI,yBAEI,kBAHR,CAOA,kCACI,qBACA,qBAJJ,CAAA","file":"NotValidCustomer.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.no-gutters {\n    padding-left: 0;\n    padding-right: 0;\n}\n\n.info {\n    background-color: $alert_Info_BackgroundColor;\n\n    .no-margin {\n        margin: 0;\n    }\n\n    p {\n        margin: 0;\n        line-height: normal;\n    }\n}\n\n.content-wrapper {\n    display: inline-block;\n    vertical-align: middle;\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"no-gutters": "NotValidCustomer_no-gutters",
	"noGutters": "NotValidCustomer_no-gutters",
	"info": "NotValidCustomer_info",
	"no-margin": "NotValidCustomer_no-margin",
	"noMargin": "NotValidCustomer_no-margin",
	"content-wrapper": "NotValidCustomer_content-wrapper",
	"contentWrapper": "NotValidCustomer_content-wrapper"
};