import React, { useContext } from 'react';
import hoistStatics from 'hoist-non-react-statics';

export default function connectToContext(contexts, mapContextToProps) {
  return function (ComposedComponent) {
    const Connected = props => {
      const resolvedContexts = [];

      for (const context of contexts)
        resolvedContexts.push(useContext(context));

      const contextProps = mapContextToProps(...resolvedContexts, props);

      return <ComposedComponent {...contextProps} {...props} />;
    };

    const displayName = ComposedComponent.displayName
      || ComposedComponent.name || 'Component';

    Connected.displayName = `ConnectToContext(${displayName})`;
    Connected.ComposedComponent = ComposedComponent;

    return hoistStatics(Connected, ComposedComponent);
  };
}
