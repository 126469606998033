import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { AbilityState, AbilityTo, CustomerTypes } from 'behavior/user/constants';
import { useAbilities } from 'components/objects/user';
import MainBlockItems from './MainBlockItems';

const MainBlock = () => {
  const [orderProductsAbility] = useSidebarAbilities();
  const showOrderProducts = orderProductsAbility === AbilityState.Available;
  const { customerType, name } = useSelector(({ user: { customerType, name } }) => ({ customerType, name }), shallowEqual);
  const customerServicePage = useSelector(({ settings: { customerServicePage } }) => customerServicePage);
  const isB2cCustomer = customerType === CustomerTypes.B2C;
  const canOrderProducts = !isB2cCustomer && showOrderProducts;

  return (
    <MainBlockItems
      canOrderProducts={canOrderProducts}
      userName={name}
      customerServicePage={customerServicePage}
    />
  );
};

export default React.memo(MainBlock);

const abilityKeys = [AbilityTo.OrderProducts];

function useSidebarAbilities() {
  return useAbilities(abilityKeys).abilities;
}