import { PageComponentNames } from '../componentNames';
import {
  UPDATE_VIEW_PAGE,
  UPDATE_TITLE,
  VISUAL_DESIGNER_PREVIEW_ADD_ROW,
  VISUAL_DESIGNER_PREVIEW_MOVE_ROW,
  VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW,
  VISUAL_DESIGNER_PREVIEW_DELETE_ROW,
  VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT,
} from './actions';
import { arrayMove, generateKey } from 'utils/helpers';
import { createReducer } from 'utils/redux';
import { mergePageContentData, modifyContentElementById, findContentElementById } from './helpers';

export const emptyRowModel = {
  rowAnimation: 'NONE',
  verticalAlignment: 'TOP',
  nested: [],
  name: 'Row',
  background: {},
  border: { style: 'NONE' },
  spacing: { margin: '0px 0px 30px 0px' },
  attributes: {},
  displayConfiguration: {},
  minHeight: {},
};

export default createReducer(null, {
  [UPDATE_VIEW_PAGE]: onUpdateViewPage,
  [UPDATE_TITLE]: onUpdateTitle,
  [VISUAL_DESIGNER_PREVIEW_ADD_ROW]: onVisualDesignerPreviewAddRow,
  [VISUAL_DESIGNER_PREVIEW_MOVE_ROW]: onVisualDesignerPreviewMoveRow,
  [VISUAL_DESIGNER_PREVIEW_DUPLICATE_ROW]: onVisualDesignerPreviewDuplicateRow,
  [VISUAL_DESIGNER_PREVIEW_DELETE_ROW]: onVisualDesignerPreviewDeleteRow,
  [VISUAL_DESIGNER_PREVIEW_MOVE_CONTENT_ELEMENT]: onVisualDesignerPreviewMoveContentElement,
});

function onUpdateViewPage(state, action) {
  const { page } = action.payload;
  page.content = mergePageContentData(state.content, page.content);

  return {
    ...state,
    ...page,
    revision: !state.revision ? 1 : state.revision + 1,
    component: PageComponentNames.Content,
  };
}

function onUpdateTitle(state, action) {
  const { defaultTitle, translationTitle } = action.payload;

  return {
    ...state,
    defaultTitle,
    translationTitle,
  };
}

function onVisualDesignerPreviewAddRow(state, action) {
  const { index, id } = action.payload;
  const newRow = { ...emptyRowModel, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index),
    newRow,
    ...state.content.slice(index),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewMoveRow(state, action) {
  const {
    indexBefore,
    indexAfter,
  } = action.payload;

  const content = arrayMove(state.content, indexBefore, indexAfter);
  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDuplicateRow(state, action) {
  const { index, id } = action.payload;
  const newRow = { ...emptyRowModel, id: id || generateKey() };
  const content = [
    ...state.content.slice(0, index + 1),
    newRow,
    ...state.content.slice(index + 1),
  ];

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewDeleteRow(state, action) {
  const content = state.content.filter((_, i) => i !== action.payload.index);

  return {
    ...state,
    content,
  };
}

function onVisualDesignerPreviewMoveContentElement(state, action) {
  const {
    indexBefore,
    indexAfter,
    sourceElementId,
    targetElementId,
  } = action.payload;

  let newContent;

  if (!targetElementId || targetElementId === sourceElementId) {
    newContent = modifyContentElementById(sourceElementId, state.content, sourceElement => ({
      ...sourceElement,
      nested: arrayMove(sourceElement.nested, indexBefore, indexAfter),
    }));
  } else {
    const {
      row: sourceRow,
      column: sourceColumn,
    } = findContentElementById(sourceElementId, state.content);
    const sourceElementParent = sourceColumn || sourceRow;

    newContent = modifyContentElementById(targetElementId, state.content, targetElement => {
      const newTargetColumnNested = [...targetElement.nested];
      newTargetColumnNested.splice(indexAfter, 0, sourceElementParent.nested[indexBefore]);
      return {
        ...targetElement,
        nested: newTargetColumnNested,
      };
    });

    newContent = modifyContentElementById(sourceElementId, newContent, sourceElement => {
      const newSourceColumnNested = [...sourceElement.nested];
      newSourceColumnNested.splice(indexBefore, 1);
      return {
        ...sourceElement,
        nested: newSourceColumnNested,
      };
    });
  }

  return {
    ...state,
    content: newContent,
  };
}
