import React from 'react';

/**
 * Maps valid react children nodes within provided function selector
 * @param {Array | Node} children array or single item of react children
 * @param {Function} func map function
 * @returns {Array} mapped array of react children
 */
function map(children, func) {
  let index = 0;
  return React.Children.map(children, child =>
    React.isValidElement(child) ? func(child, index++) : child,
  );
}

/**
 * Iterates through valid react children nodes executing provided function for each node
 * @param {Array | Node} children array or single item of react children
 * @param {Function} func function to be executed for child node
 */
function forEach(children, func) {
  let index = 0;
  React.Children.forEach(children, child =>
    React.isValidElement(child) && func(child, index++));
}

/**
 * Calculates length of valid react children nodes.
 * @param {Array | Node} children array or single item of react children
 * @returns {PositiveInteger} length of react children
 */
function length(children) {
  let index = 0;
  React.Children.forEach(children, child =>
    React.isValidElement(child) && index++);

  return index;
}

export { map, forEach, length };