import { of } from 'rxjs';
import { map, pluck } from 'rxjs/operators';
import { setPreviewToken } from './helpers';
import { viewerChanged } from 'behavior/events';
import { reloadLocation } from 'behavior/routing';
import { createViewerQuery } from 'behavior/user/queries';
import { setPreviewUser } from 'behavior/user/actions';
import { createUserData } from 'behavior/user/helpers';
import { routeNames } from 'routes';

const ignoredForPreviewUserInitializationRoutes = new Set([
  routeNames.ContentPage,
  routeNames.NewsDetail,
  routeNames.FaqPage,
  routeNames.ProductDetails,
  routeNames.ProductList,
]);

export default ({ params: { token, routeName, routeData } }, state$, { api }) => {
  if (!token)
    return of(null);

  const forwardTo = { routeName, params: { ...routeData, previewToken: token } };

  if (state$.value.user.previewData)
    return of({ forwardTo });

  setPreviewToken(api, token);

  if (ignoredForPreviewUserInitializationRoutes.has(routeName))
    return of({ forwardTo });

  return api.graphApi(createViewerQuery()).pipe(
    pluck('viewer'),
    map(viewer => createUserData(viewer, true)),
    map(previewUser => ({ page: {}, action$: of(setPreviewUser(previewUser), viewerChanged(), reloadLocation()) })),
  );
};