import { bufferTime, map, exhaustMap, first, share } from 'rxjs/operators';

export default () => source$ => {
  const sharedSource$ = source$.pipe(
    share(),
  );

  return sharedSource$.pipe(
    exhaustMap(firstAction => sharedSource$.pipe(
      bufferTime(10),
      map(buffered => [firstAction, ...buffered]),
      first(),
    )),
  );
};
