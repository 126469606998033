import { map } from 'rxjs/operators';

export default (handler, routeName) => (routeData, ...rest) => (
  handler({ routeName }, ...rest).pipe(
    map(result => {
      if (result.page) {
        result.page.index = false;
      }
      return { ...result, statusCode: 404, routeData };
    }),
  )
);
