import styles from './MainNav.module.scss';
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'components/primitives/links';
import { SimpleText } from 'components/sanaText';
import { shouldRenderNavigation } from '../helpers';
import { MainNavItemPropTypes } from './PropTypes';
import { setFocus } from './eventHandlers';
import { UseSanaTexts } from 'components/sanaText';
import { stripHtmlTagsAndFormatting } from 'utils/helpers';

const textsToLoad = ['Aria_MainNavigation_Submenu'];

const MultiColumnView = ({
  item: {
    title,
    cssClass,
    link,
    children: subItems,
  },
  viewMoreVisible,
}) => (
    <li className={`${styles.navItemBlock} ${cssClass || ''}`}>
      <Link {...link} className={styles.hypSecondLvl} onClick={setFocus} onContextMenu={setFocus}>
        {title}
      </Link>
      {shouldRenderNavigation(subItems) && (
        <UseSanaTexts options={textsToLoad} dontWait>
          {([ariaMainNavSubmenuText]) => (
            <ul className={styles.navList} aria-label={`${title} ${stripHtmlTagsAndFormatting(ariaMainNavSubmenuText)}`}>
              {subItems.map(({ id, title, cssClass, link }) => {
                return (
                  <li key={id} className={cssClass || null}>
                    <Link {...link} className={styles.hypDefault} onClick={setFocus} onContextMenu={setFocus}>
                      {title}
                    </Link>
                  </li>
                );
              })}
              {viewMoreVisible && link != null && !!link.url && link.url !== '#' && (
                <li>
                  <Link {...link} className={styles.hyp} onClick={setFocus}>
                    <SimpleText textKey="ViewMore" /> {title}
                  </Link>
                </li>
              )}
            </ul>
          )}
        </UseSanaTexts>
      )}
    </li>
  );

MultiColumnView.propTypes = {
  item: MainNavItemPropTypes,
  viewMoreVisible: PropTypes.bool,
};

export default connect(mapStateToProps)(MultiColumnView);

function mapStateToProps({ settings }) {
  return {
    viewMoreVisible: settings.loaded ? settings.navigation.viewMoreLinkVisible : false,
  };
}