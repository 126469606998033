exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".FullScreenPopup_popup{bottom:0;padding:0;background:var(--popup_BackgroundColor,#fff);border:1px solid var(--popup_BorderColor,#e6e6e6);outline:0;height:auto}.FullScreenPopup_close{position:absolute;top:0;right:0;border:0;background:none;cursor:pointer;padding:3px 4px}.FullScreenPopup_close img{max-width:30px;max-height:30px;vertical-align:middle}.FullScreenPopup_close svg{width:30px;height:30px;fill:var(--lightestText_FontColor,#b2b2b2);vertical-align:middle}.FullScreenPopup_close svg:hover{fill:var(--normalText_FontColor,grey)}", "", {"version":3,"sources":["/src/SDK/Sana.Commerce.WebApp/ClientApp/src/webstore/components/objects/fullScreenPopup/FullScreenPopup.module.scss"],"names":[],"mappings":"AAGA,uBACI,SACA,UACA,6CACA,kDACA,UACA,WAFJ,CAKA,uBACI,kBACA,MACA,QACA,SACA,gBACA,eACA,eAFJ,CAII,2BACI,eACA,gBACA,qBAFR,CAKI,2BACI,WACA,YACA,2CACA,qBAHR,CAKQ,iCACI,qCAHZ,CAAA","file":"FullScreenPopup.module.scss","sourcesContent":["/// <reference path=\"../../../css/_theme.scss\" />\n@import 'css/_theme.scss';\n\n.popup {\n    bottom: 0;\n    padding: 0;\n    background: $popup_BackgroundColor;\n    border: 1px solid $popup_BorderColor;\n    outline: 0;\n    height: auto;\n}\n\n.close {\n    position: absolute;\n    top: 0;\n    right: 0;\n    border: 0;\n    background: none;\n    cursor: pointer;\n    padding: 3px 4px;\n\n    img {\n        max-width: 30px;\n        max-height: 30px;\n        vertical-align: middle;\n    }\n\n    svg {\n        width: 30px;\n        height: 30px;\n        fill: $lightestText_FontColor;\n        vertical-align: middle;\n\n        &:hover {\n            fill: $normalText_FontColor;\n        }\n    }\n}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"popup": "FullScreenPopup_popup",
	"close": "FullScreenPopup_close"
};